import { AntDesign } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FlatList,
  Modal,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Avatar from '../Avatar';
import TeamSelectorListItem from './elements/TeamSelectorListItem';
import Alert from '/Alert';
import { ALERT_YELLOW, KEY_GRAY, KEY_GREEN, TITLE_FONT_SIZE } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import { Team, TeamMemberRole } from '/generated/graphql';
import { DeepPartial } from '/types';
import { isOrganizationProfileComplete, isUnderPrivileged } from '/util';

export interface TeamSelectorSelectionEvent {
  /** Prevent the team change */
  preventDefault: () => void;
  teamId: string;
}

interface Props {
  /** If set, TeamSelector will automatically open the selector overlay and not allow the user to
   *  interact with content behind it until they have selected a team. Default is `false` */
  requireTeamSelection?: boolean;
  /** Require selecting a team with this role, or higher. Only allow selecting teams on which our role
   * matches or exceeds this role. */
  requireRole?: TeamMemberRole;
  /** Require selecting a team whose owner's profile is complete */
  requireOrganizationProfileComplete?: boolean;
  /** Default is `fade` */
  overlayAnimationType?: 'none' | 'slide' | 'fade' | undefined;
  /** Shows number of unread notifications next to teams */
  showUnreadNotificationBadge?: boolean;
  containerStyle?: ViewStyle;
  buttonTextColor?: string;
  buttonTextStyle?: StyleProp<TextStyle>;
  buttonAvatarSize?: number;
  style?: StyleProp<ViewStyle>;
  onSelectTeam?: (e: TeamSelectorSelectionEvent) => void;
}

export default function TeamSelector(props: Props) {
  const { activeTeam, loading, setActiveTeam, teams, refresh } =
    useTeamContext();

  const { userData } = useAuthContext();

  const safeAreaInsets = useSafeAreaInsets();

  const [showTeamSelectorOverlay, setShowTeamSelectorOverlay] = useState(false);

  useFocusEffect(
    useCallback(() => {
      return () => {
        // When we go out of focus, make sure selector closes if was left open
        setShowTeamSelectorOverlay(false);
      };
    }, []),
  );

  useEffect(
    function refreshOnOpen() {
      if (showTeamSelectorOverlay) {
        /** Whenever we open the selector, refresh teams in case things have changed */
        refresh();
      }
    },
    [showTeamSelectorOverlay, refresh],
  );

  useEffect(() => {
    const underPrivileged =
      props.requireRole &&
      isUnderPrivileged(props.requireRole!, activeTeam?.membership?.team_role);
    const incompleteProfile =
      props.requireOrganizationProfileComplete &&
      activeTeam &&
      !isOrganizationProfileComplete(activeTeam.user);

    const getTeamWithSufficientPrivileges = function () {
      return teams.find(
        (team) =>
          !isUnderPrivileged(props.requireRole!, team.membership?.team_role) &&
          (props.requireOrganizationProfileComplete
            ? isOrganizationProfileComplete(team.user)
            : true),
      );
    };

    if (
      !loading &&
      (underPrivileged || incompleteProfile) &&
      activeTeam?.user.id !== userData?.id
    ) {
      const newTeam = getTeamWithSufficientPrivileges();

      if (newTeam && newTeam.id !== activeTeam?.id) {
        setActiveTeam(newTeam.id);
        Alert.notify({
          message: `Switched to ${newTeam.user.name}'s team`,
          color: ALERT_YELLOW,
        });
      }
    }
  }, [
    activeTeam,
    props.requireTeamSelection,
    props.requireOrganizationProfileComplete,
    props.requireRole,
    loading,
    teams,
    userData?.id,
    setActiveTeam,
  ]);

  function onCloseSelector() {
    // If we require a team to be selected and try to close the selector without selecting a team,
    // don't procceed
    if (props.requireTeamSelection && !activeTeam) return;

    setShowTeamSelectorOverlay(false);
  }

  function onSelectTeam(teamId: string) {
    let preventDefault = false;

    if (props.onSelectTeam) {
      const e: TeamSelectorSelectionEvent = {
        preventDefault: () => (preventDefault = true),
        teamId,
      };

      props.onSelectTeam(e);
    }

    if (preventDefault) return;

    setActiveTeam(teamId);
    setShowTeamSelectorOverlay(false);
  }

  return (
    <>
      {/* Button */}
      <SelectorButton
        textColor={props.buttonTextColor}
        onPress={() => setShowTeamSelectorOverlay(true)}
        activeTeam={activeTeam}
        containerStyle={StyleSheet.flatten([props.containerStyle, props.style])}
        textStyle={props.buttonTextStyle}
        avatarSize={props.buttonAvatarSize}
      />

      {/* Full-screen overlay listing teams */}
      <Modal
        visible={showTeamSelectorOverlay}
        animationType={props.overlayAnimationType ?? 'fade'}
        style={styles.modalContainer}
      >
        <View
          style={[
            styles.modalContentContainer,
            {
              marginTop: safeAreaInsets.top,
            },
          ]}
        >
          <View
            style={{
              width: '100%',
            }}
          >
            <SelectorButton
              onPress={() => setShowTeamSelectorOverlay(true)}
              activeTeam={activeTeam}
              asHeader
            />
            <Pressable
              onPress={onCloseSelector}
              style={styles.modalCloseButton}
            >
              <AntDesign name="close" size={28} color={KEY_GRAY} />
            </Pressable>
          </View>
          <Text style={styles.headerText}>
            SELECT TEAM
            {props.requireRole
              ? ` (${props.requireRole.toUpperCase()} REQUIRED)`
              : ''}
          </Text>
          <FlatList
            style={styles.listContainer}
            data={teams}
            renderItem={({ item: team, index }) => {
              return (
                <TeamSelectorListItem
                  team={team}
                  showUnreadNotificationBadge={
                    !!props.showUnreadNotificationBadge
                  }
                  onPress={() => {
                    onSelectTeam(team.id);
                  }}
                  requireOrganizationProfileComplete={
                    !!props.requireOrganizationProfileComplete
                  }
                  requireRole={props.requireRole}
                  activeTeam={activeTeam}
                  style={{
                    borderTopColor: '#ddd',
                    borderTopWidth: index === 0 ? 0 : 1,
                  }}
                  key={team.id}
                />
              );
            }}
          />
        </View>
      </Modal>
    </>
  );
}

interface ISelectorButtonProps {
  onPress: () => void;
  activeTeam: DeepPartial<Team> | undefined;
  asHeader?: boolean;
  textColor?: string;
  containerStyle?: ViewStyle;
  avatarSize?: number;
  textStyle?: StyleProp<TextStyle>;
}

const SelectorButton = (props: ISelectorButtonProps) => {
  return (
    <Pressable
      disabled={props.asHeader}
      onPress={props.onPress}
      style={({ pressed }) => [
        styles.selectorButtonContainer,
        props.containerStyle,
        {
          opacity: pressed ? 0.5 : 1,
        },
      ]}
    >
      <Avatar
        rounded
        size={props.avatarSize ?? 28}
        containerStyle={styles.avatarContainer}
        source={{ uri: props.activeTeam?.user?.profile_image ?? '' }}
      />
      <Text
        style={[
          styles.activeTeamName,
          props.textColor ? { color: props.textColor } : null,
          props.textStyle,
        ]}
      >
        {props.activeTeam ? props.activeTeam.user?.name : 'No team selected'}
        {` `}
        {props.asHeader ? null : (
          <AntDesign
            name="caretdown"
            size={16}
            color={props.textColor ?? KEY_GRAY}
          />
        )}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  selectorButtonContainer: {
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarContainer: {
    borderColor: KEY_GREEN,
    borderWidth: 2,
    padding: 1,
  },
  activeTeamName: {
    color: KEY_GRAY,
    textTransform: 'uppercase',
    fontFamily: 'LeagueSpartan-Bold',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: 16,
    margin: 12,
    marginHorizontal: 8,
    alignItems: 'center',
  },
  modalContainer: {},
  modalContentContainer: {
    flex: 1,
    paddingVertical: 10,
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#ddd',
  },
  modalCloseButton: {
    width: 40,
    top: 0,
    bottom: 0,
    padding: 8,
    position: 'absolute',
    right: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    marginTop: 12,
    paddingHorizontal: 12,
    fontSize: TITLE_FONT_SIZE,
  },
  listContainer: {
    flex: 1,
    marginTop: 12,
  },
});
