import { Ionicons } from '@expo/vector-icons';
import React, { useState } from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import Alert from '/Alert';
import Button from '/components/Button';
import MultipleChoice from '/components/MultipleChoice';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import ScrollView from '/components/common/ScrollView/ScrollView';
import { KEY_DARK_GREEN, KEY_GRAY, KEY_GREEN } from '/constants';
import { useAuthContext } from '/context';
import {
  TesterStatus,
  UserRole,
  useSignupAsTesterMutation,
} from '/generated/graphql';
import { shortenEmail } from '/util';
import { useLinkTo } from '@react-navigation/native';
import withAuthRequired from '/components/withAuthRequired';

function TesterSignupScreen() {
  const {
    userData,
    refresh,
    fetching: fetchingUser,
    logOut,
    userAttributes,
  } = useAuthContext();
  const windowDimensions = useWindowDimensions();
  const [{ fetching: signingUp }, signupAsTester] = useSignupAsTesterMutation();

  const linkTo = useLinkTo();

  const [roleSelect, setRoleSelect] = useState<UserRole | undefined>(undefined);

  const initiateLogout = () => {
    Alert.alert('Log out', 'Are you sure you want to log out?', [
      {
        onPress: () => logOut('/tester-signup'),
        style: 'default',
        text: 'Log Out',
      },
      { style: 'cancel', text: 'Cancel' },
    ]);
  };

  /** If we have not yet created User entity for this user, they need to
   * specify the role before we create it */
  const roleSelectRequired =
    [TesterStatus.None, undefined].includes(
      userData?.tester_status || undefined,
    ) && !userData?.onboarded;

  return (
    <>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          minHeight: windowDimensions.height,
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: windowDimensions.height,
          }}
        >
          <View style={styles.containerMaxWidth}>
            <HorizontalContainer
              style={{
                paddingBottom: 8,
              }}
            >
              <Ionicons
                name="arrow-back"
                size={24}
                color={KEY_GRAY}
                style={{
                  marginRight: 10,
                  alignSelf: 'flex-start',
                }}
                onPress={() => {
                  linkTo('/welcome');
                }}
              />
              <Text style={styles.instructionText}>Become a tester</Text>
            </HorizontalContainer>
            <View style={styles.container}>
              <Text style={styles.text}>
                {userData?.tester_status === TesterStatus.Interested
                  ? 'Thank you for your interest in becoming a tester. If you are given access, you will receive an email notification.'
                  : `Key Conservation is currently only available to testers. If you'd like to be a tester, click "I'm interested" below.`}
              </Text>
              {roleSelectRequired ? (
                <MultipleChoice
                  containerStyle={{
                    paddingBottom: 0,
                  }}
                  choiceContainerStyle={{
                    marginBottom: 8,
                  }}
                  options={[UserRole.Supporter, UserRole.Conservationist]}
                  value={roleSelect}
                  onChange={setRoleSelect}
                  renderTitle={(role) =>
                    role === UserRole.Supporter
                      ? 'Sign up as an individual'
                      : 'Sign up as a conservation organization'
                  }
                />
              ) : null}
              {userData?.tester_status === TesterStatus.Interested ? (
                <HorizontalContainer
                  style={{
                    alignSelf: 'flex-end',
                    marginTop: 8,
                  }}
                >
                  <Ionicons
                    name="checkmark-circle"
                    size={18}
                    color={KEY_DARK_GREEN}
                  />
                  <Text
                    style={{
                      color: KEY_DARK_GREEN,
                      marginLeft: 6,
                      fontFamily: 'Lato-Bold',
                      fontSize: 15,
                    }}
                  >
                    Signed up
                  </Text>
                </HorizontalContainer>
              ) : (
                <Button
                  containerStyle={{
                    alignSelf: 'flex-end',
                    marginTop: 8,
                  }}
                  style={{
                    backgroundColor: KEY_GREEN,
                  }}
                  disabled={
                    (roleSelectRequired && !roleSelect) ||
                    userData?.tester_status === TesterStatus.Revoked
                  }
                  loading={signingUp || fetchingUser}
                  label="I'm interested"
                  onPress={() => {
                    signupAsTester({
                      role: roleSelect,
                    }).then(({ error }) => {
                      if (!error) refresh();
                    });
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.identityContainer}>
        <Text style={styles.signedInAs}>
          Signed in as {`${shortenEmail(userAttributes?.email ?? '', 36)}`}
        </Text>
        <Pressable
          style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
          })}
          onPress={initiateLogout}
        >
          <Text style={styles.notYouText}>Sign out</Text>
        </Pressable>
      </View>
    </>
  );
}

export default withAuthRequired(TesterSignupScreen);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 6,
  },
  containerMaxWidth: {
    maxWidth: 480,
  },
  text: {
    fontSize: 16,
    fontFamily: 'Lato-Bold',
  },
  instructionText: {
    flex: 1,
    color: KEY_GRAY,
    fontSize: 19,
    fontFamily: 'Lato-Bold',
  },
  identityContainer: {
    position: 'absolute',
    right: 24,
    bottom: 48,
    marginLeft: 48,
    alignItems: 'flex-end',
  },
  signedInAs: {
    fontFamily: 'Lato-Bold',
    color: 'black',
    textAlign: 'right',
    fontSize: 16,
    paddingBottom: 4,
  },
  notYouText: {
    fontFamily: 'Lato-Bold',
    color: KEY_DARK_GREEN,
    fontSize: 17,
  },
});
