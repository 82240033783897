import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Pressable, Text, View } from 'react-native';

import { useIsFocused, useNavigation } from '@react-navigation/native';

import styles from './CampaignPost.style';

import { ViewportAwareView } from '@skele/components';

import LoadingOverlay from '../LoadingOverlay';
import MediaViewer from '../MediaViewer/MediaViewer';
import CampaignDescriptionAndTimestamp from './elements/CampaignDescriptionAndTimestamp';
import CampaignPostControls, {
  ICampaignPostInteractions,
} from './elements/CampaignPostControls';
import CampaignPostFooter from './elements/CampaignPostFooter';
import CampaignPostHeader from './elements/CampaignPostHeader';
import CampaignSpecies, { ICampaignSpecies } from './elements/CampaignSpecies';
import CampaignTimeline from './elements/CampaignTimeline';

import { FontAwesome5 } from '@expo/vector-icons';
import {
  IDonationRequest,
  ISkilledImpactRequest,
  IVolunteerRequest,
} from '../TakeAction/TakeActionCallToAction';
import Alert from '/Alert';
import { useAuthContext } from '/context/AuthProvider';
import {
  Campaign,
  CampaignImpactReferralInput,
  CampaignPost as CampaignPostType,
  ClientEventAction,
  ClientEventElement,
  TranslatableText,
  User,
  useGetCampaignPostQuery,
} from '/generated/graphql';
import { getUrgencyLabelAndColors } from '/util';
import useClientEvents from '/hooks/useClientEvents';

interface ICampaignPostProps {
  /** Required for reporting: Name of page that component is being rendered in */
  page: string | null;
  data: ICampaignPostComponentCampaignPost | null;
  referral?: CampaignImpactReferralInput;
  loading?: boolean;
  campaign?: ICampaignPostComponentCampaign | null;
  disableGoToCampaign?: boolean;
  /** Hides the footer */
  hideFooter?: boolean;
  /** Hides the due date */
  hideDueDate?: boolean;
  /** Hides the species */
  hideSpecies?: boolean;
  recyclingKey?: string;
  hideTimestamp?: boolean;
  /** Hide control buttons under post - emoji, comment & bookmark buttons */
  disableControls?: boolean;
  /** Disable header pressability */
  disableHeader?: boolean;
  hideCommentsButton?: boolean;
  /** Hides "Take Action" section of campaign - Used in campaign builder preview step */
  preview?: boolean;
  /** Hides horizontal FlatList of related CampaignPosts */
  hideRelated?: boolean;
  /** Prevents entry animation from happening */
  disableAnimateIn?: boolean;
  useOriginalMediaSource?: boolean;
  /** Called when campaign is pressed and navigates to ViewCampaignScreen */
  onGoToCampaign?: () => void;
}

export interface ICampaignPostComponentCampaign
  extends Pick<
    Campaign,
    | 'id'
    | 'name'
    | 'urgency'
    | 'closed'
    | 'closed_for_testing'
    | 'is_bookmarked'
    | 'support_needed_by_date'
  > {
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
  volunteer_request?: IVolunteerRequest | undefined | null;
  donation_request?: IDonationRequest | undefined | null;
  skilled_impact_requests: ISkilledImpactRequest[] | undefined | null;
  species?: ICampaignSpecies[] | undefined | null;
}

export interface ICampaignPostComponentCampaignPost
  extends Pick<
    CampaignPostType,
    'id' | 'is_update' | 'created_at' | 'media' | 'thumbnail'
  > {
  description: Pick<TranslatableText, 'id' | 'text' | 'language'>;
  campaign?: ICampaignPostComponentCampaign | null;
  user?: Pick<User, 'id' | 'name' | 'profile_image'> | null;
  authors?: Pick<User, 'id' | 'name' | 'profile_image'>[] | undefined | null;
  interactions?: ICampaignPostInteractions | null | undefined;
}

const STATUS_BAR_COLOR = 'rgba(0,255,157,0.6)';

const CampaignPost = (props: ICampaignPostProps) => {
  const { navigate, push } = useNavigation<any>();

  const isFocused = useIsFocused();

  const campaign = props.campaign || props.data?.campaign;
  const user = props.campaign?.user || props.data?.user;

  /** If this is undefined, that means we are in the default state and just rendering the post from
   * props.data. When user selects a post in CampaignTimeline, we set it here to start fetching and
   * rendering that post instead */
  const [selectedPostId, setSelectedPostId] = useState<string | undefined>();

  /** Users can select and view different posts in the same campaign, this currentPost
   * is where we store the post we currently want to render. Initially, we use
   * the post passed in from props */
  const [selectedPost, setSelectedPost] = useState<
    Omit<ICampaignPostComponentCampaignPost, 'campaign'> | undefined
  >(props.data || undefined);

  const [
    {
      data: selectedPostData,
      fetching: fetchingSelectedPost,
      error: fetchSelectedPostError,
    },
  ] = useGetCampaignPostQuery({
    variables: {
      getCampaignPostId: selectedPostId!,
      relatedPostsLimit: 0,
    },
    /** Only start this query if user has explicity selected a different post */
    pause: !selectedPostId,
  });

  const { userData } = useAuthContext();

  const animation = useRef(new Animated.Value(0));

  const animateIn = Animated.timing(animation.current, {
    useNativeDriver: true,
    toValue: 1,
    duration: 250,
  });

  /** IDs of posts that this component has already recorded impressions for */
  const hasRecordedImpressionIds = useRef<string[]>([]);
  const hasRecordedProfileClick = useRef(false);
  const hasRecordedCampaignClick = useRef(false);

  const clientEvents = useClientEvents();

  useEffect(() => {
    setSelectedPostId(undefined);
  }, [props.data?.id]);

  useEffect(() => {
    if (!props.data) {
      console.warn(
        'Trying to render CampaignPost without a valid `data` prop!',
      );
      return;
    }

    /** If there is no data in selectedPostData, then we should update selectedPost to make
     * sure the post stays up to date with the cache */
    if (!selectedPostId && props.data) {
      setSelectedPost(props.data);
    }
  }, [props.data, selectedPostId, selectedPost?.id]);

  useEffect(() => {
    if (props.disableAnimateIn) {
      animation.current.setValue(1);
    } else {
      animateIn.start();
    }
  }, [animateIn, props.disableAnimateIn]);

  useEffect(() => {
    if (fetchSelectedPostError && selectedPostId !== selectedPost?.id) {
      setSelectedPostId(selectedPost?.id);
      Alert.alert('Oh no', 'There was a problem loading this post');
    }
  }, [fetchSelectedPostError, selectedPostId, selectedPost]);

  useEffect(() => {
    /** If we fetched a post, set it in state to render it */
    if (selectedPostData?.getCampaignPost) {
      setSelectedPost(selectedPostData.getCampaignPost);
    }
  }, [selectedPostData]);

  const goToProfile = () => {
    recordProfileClick();

    if (user?.id === userData?.id) {
      navigate('ProfileStack');
    } else {
      push('Profile', { id: user?.id });
    }
  };

  const goToCampaign = async (params?: any) => {
    if (!selectedPost?.id) {
      console.warn(
        'CampaignPost.goToCampaign called, but a post `id` could not be found on the `data` prop.',
      );
      return;
    }

    // If goToCampaign is disabled, don't navigate
    if (props.disableGoToCampaign) return;

    recordCampaignClick();

    push('Campaign', {
      postId: selectedPost?.id,
      ...params,
    });

    props.onGoToCampaign?.();
  };

  function onSelectPost(postId: string) {
    /** do nothing if target post is already selected */
    if (selectedPostId === postId) return;

    setSelectedPostId(postId);
  }

  const onViewportEnter = () => {
    if (!selectedPost?.id) return;

    recordImpression();

    hasRecordedImpressionIds.current.push(selectedPost.id);
  };

  const canRecordEvent =
    !!props.page &&
    !props.preview &&
    isFocused &&
    !!selectedPost?.id &&
    user?.id !== userData?.id;

  const recordImpression = useCallback(() => {
    if (!canRecordEvent) return;
    if (hasRecordedImpressionIds.current.includes(selectedPost!.id as string))
      return;

    clientEvents([
      {
        action: ClientEventAction.Impression,
        element: ClientEventElement.CampaignPost,
        page: props.page || 'unknown',
        campaignPostId: selectedPost!.id,
      },
    ]);
  }, [canRecordEvent, selectedPost, clientEvents, props.page]);

  const recordProfileClick = () => {
    if (!canRecordEvent) return;
    if (hasRecordedProfileClick.current) return;

    hasRecordedProfileClick.current = true;

    clientEvents([
      {
        action: ClientEventAction.ProfileClick,
        element: ClientEventElement.CampaignPost,
        page: props.page || 'unknown',
        campaignPostId: selectedPost?.id,
        profileId: user?.id,
      },
    ]);
  };

  const recordCampaignClick = () => {
    if (!canRecordEvent) return;
    if (hasRecordedCampaignClick.current) return;

    hasRecordedCampaignClick.current = true;

    clientEvents([
      {
        action: ClientEventAction.Click,
        element: ClientEventElement.CampaignPost,
        page: props.page || 'unknown',
        campaignPostId: selectedPost?.id,
        profileId: user?.id,
      },
    ]);
  };

  const translateY = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [-650, 0],
  });

  const opacity = animation.current.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: [0, 0.25, 1],
  });

  // const isLargeDevice = cardWidth > CAMPAIGN_POST_LARGE_DEVICE_BREAKPOINT;

  const urgency = getUrgencyLabelAndColors(campaign?.urgency);
  const closedSuffix = campaign?.closed ? ' - CLOSED' : '';

  return (
    <Pressable
      style={styles.container}
      onPress={() => {
        goToCampaign();
      }}
    >
      {/* CONTENT */}
      <ViewportAwareView
        style={{
          flex: 1,
        }}
        onViewportEnter={onViewportEnter}
      >
        <Animated.View
          // onLayout={(event) => {
          //   setCardWidth(event.nativeEvent.layout.width);
          // }}
          style={[
            styles.mainContainer,
            { transform: [{ translateY: translateY }], opacity: opacity },
          ]}
        >
          {selectedPost?.is_update ? (
            <View
              style={[
                styles.urgencyBar,
                {
                  backgroundColor: STATUS_BAR_COLOR,
                },
              ]}
            >
              <Text style={styles.urgencyBarText}>UPDATE{closedSuffix}</Text>
            </View>
          ) : urgency.label ? (
            <View
              style={[
                styles.urgencyBar,
                {
                  backgroundColor: urgency.color,
                },
              ]}
            >
              <Text
                style={[
                  styles.urgencyBarText,
                  urgency.labelColor ? { color: urgency.labelColor } : null,
                ]}
              >
                {urgency.label}
                {closedSuffix}
              </Text>
            </View>
          ) : null}

          <CampaignPostHeader
            data={{
              ...selectedPost,
              id: selectedPost?.id || '',
              is_update: selectedPost?.is_update || false,
              created_at: selectedPost?.created_at || '',
              description: selectedPost?.description,
            }}
            campaign={campaign}
            user={user}
            disableActionSheetButton={props.preview}
            disableHeader={props.disableHeader ?? false}
            onPress={goToProfile}
          />

          <CampaignDescriptionAndTimestamp
            hideTimestamp={props.hideTimestamp}
            data={selectedPost}
          />

          <View
            style={{
              zIndex: -1,
              marginHorizontal: 8,
            }}
          >
            <MediaViewer
              // style={{ flex: 1 }}
              useOriginalSource={props.useOriginalMediaSource}
              thumbnailUri={selectedPost?.thumbnail ?? ''}
              sourceUris={selectedPost?.media ?? []}
            />

            {/* LOADING OVERLAY */}
            <LoadingOverlay
              loading={fetchingSelectedPost || props.loading || false}
            />
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
          >
            {/* Campaign species and due date */}
            <CampaignSpecies campaign={campaign} hide={props.hideSpecies} />

            {props.disableControls || !selectedPost?.id ? null : (
              <CampaignPostControls
                data={selectedPost}
                campaign={campaign}
                hideCommentsButton={props.hideCommentsButton ?? false}
              />
            )}
          </View>

          {/* Timeline */}
          <View
            style={{
              display: props.hideRelated ? 'none' : 'flex',
            }}
          >
            {props.hideRelated ? null : (
              <>
                <CampaignTimeline
                  campaignId={campaign?.id || props.data?.campaign?.id}
                  selectedPostId={selectedPostId || selectedPost?.id}
                  onSelectPost={onSelectPost}
                />
              </>
            )}
          </View>

          <CampaignPostFooter
            hidden={!!props.hideFooter}
            referral={props.referral}
            user={user}
            campaign={campaign}
            preview={props.preview || false}
          />

          {!props.disableGoToCampaign ? (
            <Pressable
              onPress={() => goToCampaign()}
              style={styles.detailExpandContainer}
            >
              <FontAwesome5 name="expand-alt" size={24} color="black" />
              <Text style={styles.detailExpandText}>
                Click to expand details
              </Text>
            </Pressable>
          ) : null}
        </Animated.View>
      </ViewportAwareView>
    </Pressable>
  );
};

export default CampaignPost;
