import { MaterialIcons } from '@expo/vector-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform, Text, View } from 'react-native';
import { ICampaignPickerCampaign } from '../CampaignPicker';
import styles from './CampaignPickerModal.style';
import Button from '/components/Button';
import CampaignFlatList, {
  CampaignFlatListFilter,
  CampaignFlatListLayoutMode,
} from '/components/CampaignFlatList/CampaignFlatList';
import CampaignPost from '/components/CampaignPost/CampaignPost';
import UserFilterButton from '../../FilterButtons/UserFilterButton/UserFilterButton';
import ResearchTopicFilterButton from '/components/FilterButtons/ResearchTopicFilterButton/ResearchTopicFilterButton';
import SpeciesFilterButton from '/components/FilterButtons/SpeciesFilterButton/SpeciesFilterButton';
import SearchBar from '/components/SearchBar/SearchBar';
import { ISpeciesPickerSpecies } from '/components/SpeciesPicker/SpeciesPickerModal';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import ScrollView from '/components/common/ScrollView/ScrollView';
import { KEY_GRAY, KEY_GREEN, TEXT_INPUT } from '/constants';
import { useAuthContext, useModalContext, useTeamContext } from '/context';
import { GetCampaignsQuery, User, UserRole } from '/generated/graphql';
import useDebouncedState from '/hooks/useDebouncedState';

export type ShowFilters =
  | boolean
  | {
      createdBy?: boolean;
      species?: boolean;
      topics?: boolean;
    };

interface Props {
  filter: Omit<CampaignFlatListFilter, 'speciesTaxonIds' | 'topicNames'>;
  /** Campaign IDs */
  selection: ICampaignPickerCampaign[];
  resultsLayoutMode?: CampaignFlatListLayoutMode;
  showFilters?: ShowFilters;
  maxSelectionSize?: number;
  emptyPlaceholderText?: string;
  onClose: (selection: ICampaignPickerCampaign[]) => void;
}

export default function CampaignPickerModal(props: Props) {
  const { spawnModal } = useModalContext();
  const { userData } = useAuthContext();
  const { teams } = useTeamContext();

  const [selection, setSelection] = useState<ICampaignPickerCampaign[]>([]);

  const [loading, setLoading] = useState(false);

  const [query, queryDebounced, setQuery] = useDebouncedState('');
  const [speciesFilter, setSpeciesFilter] = useState<ISpeciesPickerSpecies[]>(
    [],
  );
  const [topicsFilter, setTopicsFilter] = useState<string[]>([]);
  const [createdByFilter, setCreatedByFilter] = useState<
    Pick<User, 'id' | 'name' | 'profile_image'> | undefined
  >(
    [userData, ...teams.map((t) => t.user)].find(
      (u) => u?.id === props.filter.userId,
    ) ||
      (props.filter.userId
        ? {
            id: props.filter.userId,
            name: '...',
          }
        : undefined),
  );

  useEffect(() => {
    setSelection(props.selection);
  }, [props.selection]);

  function onRequestClose() {
    props.onClose(selection);
  }

  const onSelectCampaign = useCallback(
    (campaignId: string, campaign: ICampaignPickerCampaign) => {
      const isSelected = selection?.some((c) => c.id === campaignId);

      if (isSelected) {
        setSelection(selection.filter((c) => c.id !== campaignId));
      } else {
        if (
          !props.maxSelectionSize ||
          !selection.length ||
          selection.length < props.maxSelectionSize
        ) {
          setSelection([...selection, campaign]);
        }
      }
    },
    [props.maxSelectionSize, selection],
  );

  const renderWidget = useCallback(
    (item: GetCampaignsQuery['getCampaigns']['items'][0]) => {
      const isSelected = selection?.some((c) => c.id === item.id);

      return (
        <HorizontalContainer
          style={{
            marginTop: 4,
          }}
        >
          <Button
            containerStyle={{
              width: 50,
              marginRight: 4,
            }}
            label={<MaterialIcons name="preview" size={20.5} color="black" />}
            onPress={() => {
              spawnModal({
                title: 'Preview Campaign',
                style: {
                  maxWidth: 640,
                },
                component: (
                  <ScrollView
                    contentContainerStyle={{
                      pointerEvents: 'none',
                    }}
                  >
                    <CampaignPost
                      preview
                      page={null}
                      disableAnimateIn
                      disableHeader
                      disableGoToCampaign
                      campaign={item}
                      data={item.original_post}
                    />
                  </ScrollView>
                ),
              });
            }}
          />
          <Button
            onPress={() => onSelectCampaign(item.id, item)}
            containerStyle={{
              flex: 1,
            }}
            style={{
              backgroundColor: isSelected ? KEY_GREEN : KEY_GRAY,
            }}
            labelStyle={{
              color: isSelected ? 'black' : 'white',
            }}
            disabled={
              !isSelected &&
              !!props.maxSelectionSize &&
              !!selection.length &&
              selection.length >= props.maxSelectionSize
            }
            label={isSelected ? 'SELECTED' : 'SELECT'}
          />
        </HorizontalContainer>
      );
    },
    [onSelectCampaign, props.maxSelectionSize, selection, spawnModal],
  );

  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={styles.headerTitle}>SELECT CAMPAIGNS</Text>

        <Button
          label="Done"
          onPress={onRequestClose}
          style={{
            backgroundColor: KEY_GREEN,
          }}
        />
      </View>

      {props.showFilters ? (
        <>
          {/* Search bar & filters */}
          <View
            style={{
              marginBottom: 10,
            }}
          >
            <SearchBar
              value={query}
              loading={loading}
              onChangeText={setQuery}
              style={TEXT_INPUT}
              containerStyle={{
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
                paddingVertical: 0,
                paddingRight: 0,
              }}
            />

            <ScrollView
              horizontal
              contentContainerStyle={{
                paddingBottom: 4,
              }}
              showsHorizontalScrollIndicator={Platform.OS === 'web'}
              style={{
                overflow: 'visible',
                flexGrow: 0,
                flexShrink: 0,
                backgroundColor: 'white',
              }}
            >
              {typeof props.showFilters === 'boolean' ||
              props.showFilters?.createdBy ? (
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>CREATED BY</Text>
                  <UserFilterButton
                    value={createdByFilter}
                    onChange={setCreatedByFilter}
                    users={
                      userData?.role === UserRole.Conservationist
                        ? [userData]
                        : teams.map((t) => t.user)
                    }
                  />
                </View>
              ) : null}

              {typeof props.showFilters === 'boolean' ||
              props.showFilters?.species ? (
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>SPECIES</Text>
                  <SpeciesFilterButton
                    species={speciesFilter}
                    onChange={setSpeciesFilter}
                  />
                </View>
              ) : null}

              {typeof props.showFilters === 'boolean' ||
              props.showFilters?.topics ? (
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>TOPICS</Text>
                  <ResearchTopicFilterButton
                    topics={topicsFilter}
                    onChange={setTopicsFilter}
                  />
                </View>
              ) : null}
            </ScrollView>
          </View>
        </>
      ) : null}

      {typeof props.maxSelectionSize === 'number' && (
        <Text style={styles.headerSubtitle}>
          {selection.length} / {props.maxSelectionSize} SELECTED
        </Text>
      )}

      <CampaignFlatList
        emptyPlaceholderText={props.emptyPlaceholderText}
        key={JSON.stringify({
          speciesTaxonIds: speciesFilter.map((s) => s.acceptedNameUsageID),
          topicNames: topicsFilter,
          query: queryDebounced,
        })}
        onLoadingChange={setLoading}
        filter={{
          ...props.filter,
          userId: createdByFilter?.id,
          speciesTaxonIds: speciesFilter.map((s) => s.acceptedNameUsageID),
          topicNames: topicsFilter,
          queryString: queryDebounced,
        }}
        page={null}
        layoutMode={props.resultsLayoutMode ?? 'compact'}
        compactOnPress={onSelectCampaign}
        gridOnPress={onSelectCampaign}
        hideControls
        compactWidget={renderWidget}
        gridWidget={renderWidget}
      />
    </>
  );
}
