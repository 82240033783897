import debounce from 'lodash/debounce';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Image,
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform,
  ReturnKeyType,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  TextInputSubmitEditingEventData,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { TouchableHighlight } from 'react-native-gesture-handler';
import { ACTIVITY_INDICATOR_DEFAULT, KEY_GRAY } from '/constants';
import { geocode } from '/util';
import useIsMouseDown from '../../hooks/useIsMouseDown';
import env from '/env';
import { v4 } from 'uuid';
import { get } from 'aws-amplify/api';

interface LocationInputProps extends TextInputProps {
  suggestionListOrientation?: 'top' | 'bottom';
  style?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  suggestionListStyle?: StyleProp<ViewStyle>;
  suggestionTypes?: PlaceType[];
  /** If set, suggestions will bias to locations that are closer in proximity to this origin */
  originLocation?: { lat: number; lon: number };
  value?: string;
  placeholder?: string;
  returnKeyType?: ReturnKeyType;
  hidePoweredByGoogle?: boolean;
  onLayout?: (event: LayoutChangeEvent) => void;
  onSubmitEditing?: (
    e: NativeSyntheticEvent<TextInputSubmitEditingEventData>,
  ) => void;
  onChangeText?: (
    text: string,
    isValid?: boolean,
    prediction?: PlacePrediction,
  ) => void;
}

type PlaceType =
  | 'geocode'
  | 'address'
  | 'establishment'
  | 'locality'
  | 'sublocality'
  | 'postal_code'
  | 'country'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | '(regions)'
  | '(cities)';

type GooglePlacesResponse = {
  predictions: PlacePrediction[];
  status: string;
};

type PlacePrediction = {
  description: string;
  id?: string;
  matched_substrings: MatchedSubstring[];
  place_id: string;
  reference: string;
  structured_formatting: StructuredFormat;
  terms: Term[];
  types: string[];
  longitude: number;
  latitude: number;
};

type Term = {
  offset: number;
  value: string;
};

type StructuredFormat = {
  main_text: string;
  main_text_matched_substrings: MatchedSubstring[];
  secondary_text: string;
};

type MatchedSubstring = {
  length: number;
  offset: number;
};

export default forwardRef<TextInput, LocationInputProps>(
  (
    {
      style = {},
      containerStyle = {},
      suggestionListStyle = {},
      suggestionListOrientation = 'bottom',
      value,
      defaultValue,
      suggestionTypes,
      originLocation,
      placeholder,
      returnKeyType,
      hidePoweredByGoogle = false,
      onSubmitEditing,
      onChangeText,
      onLayout,
      onBlur,
      ...rest
    }: LocationInputProps,
    ref,
  ) => {
    const inputRef = useRef<TextInput>(null);

    const [inputText, setInputText] = useState<string>(
      value || defaultValue || '',
    );

    const [suggestions, setSuggestions] = useState<PlacePrediction[]>([]);

    const abortController = useRef<AbortController | undefined>();

    const [suggestionListOffsetY, setSuggestionListOffsetY] =
      useState<number>();

    const [loading, setLoading] = useState<boolean>(true);
    const [busy, setBusy] = useState<boolean>(false);

    const isMouseDown = useIsMouseDown();

    const [isFocused, _setFocused] = useState<boolean>(false);
    const setFocused = (focused: boolean) => {
      let timeout = focused ? 0 : 200;
      setTimeout(() => _setFocused(focused), timeout);
    };

    const [isSuggestionListVisible, setSuggestionListVisible] = useState(false);

    // Session should be refreshed after every selection has been made
    // i.e. a user makes a selection from the autocomplete list
    const [sessionToken, setSessionToken] = useState<string>(v4());

    useEffect(() => {
      if (!isFocused && isMouseDown) return;

      setSuggestionListVisible(isFocused);
    }, [isFocused, isMouseDown]);

    useImperativeHandle(ref, () => inputRef.current as TextInput);

    const _handleChangeText = useCallback(
      async (text: string) => {
        const apiKey = Platform.select({
          ios: env.IOS_GOOGLE_MAPS_KEY,
          android: env.ANDROID_GOOGLE_MAPS_KEY,
          web: env.WEB_GOOGLE_MAPS_KEY,
        }) as string;

        // If there is no input text, don't bother making a request
        if (!text?.trim()) {
          setSuggestions([]);
          return;
        }

        setLoading(true);

        try {
          const response = await get({
            apiName: 'googlemapsapi',
            path: '/autocomplete',
            options: {
              queryParams: {
                input: text,
                key: apiKey,
                sessiontoken: sessionToken,
                locationbias: originLocation
                  ? `circle:5000@${originLocation.lon},${originLocation.lat}`
                  : 'ipbias',
                types: suggestionTypes ? suggestionTypes.join('|') : 'geocode',
              },
            },
          }).response;

          const result = (await response.body.json()) as GooglePlacesResponse;

          setSuggestions(result.predictions);
        } catch (err: any) {
          console.log('error', JSON.stringify(err));
          // Only treat this as an error if it is not an AbortError
          if (err?.name !== 'AbortError') {
            // console.log('[LocationInput]', err);
          }
        } finally {
          setLoading(false);
        }
      },
      [originLocation, sessionToken, suggestionTypes],
    );
    let _onChangeText = useMemo(
      () => debounce(_handleChangeText, 500),
      [_handleChangeText],
    );

    const abortCurrentRequest = () => {
      if (abortController.current?.signal.aborted === false)
        abortController.current.abort();
    };

    useEffect(() => {
      // Cancel previous request immediately and potentially
      // save some bandwidth/improve behavior
      abortCurrentRequest();

      // If text is empty, do not make a request
      // and remove all suggestions
      if (!inputText?.length) {
        setLoading(false);
        // setPredictions([]);
        setSuggestions([]);
        return;
      }
      _onChangeText(inputText);
    }, [_onChangeText, inputText]);

    useEffect(() => {
      setInputText(value || '');
    }, [value]);

    // Clean-up function, abort current request
    // if we unmount to prevent memory leak
    useEffect(() => abortCurrentRequest, []);

    const refreshSession = () => {
      setSessionToken(v4());
    };

    const onPlaceSelected = (prediction: PlacePrediction) => {
      refreshSession();

      setInputText(prediction.description);

      setBusy(true);

      inputRef.current?.blur();

      geocode(prediction.description)
        .then((results) => {
          const location = results?.[0]?.location;

          let coords: {
            latitude?: number;
            longitude?: number;
          } = {};

          if (
            typeof location?.lat === 'number' &&
            typeof location?.lng === 'number'
          ) {
            coords = {
              latitude: location.lat,
              longitude: location.lng,
            };
          }

          onChangeText?.(prediction.description, true, {
            ...prediction,
            ...coords,
          });
        })
        .catch((err) => {
          const tryStringify = (_err: any) => {
            try {
              return JSON.stringify(_err);
            } catch {
              return _err;
            }
          };

          console.log('[LocationInput]', tryStringify(err));
          onChangeText?.(prediction.description, false);
        })
        .finally(() => {
          setBusy(false);
        });
    };

    return (
      <View style={[{ flex: 1 }, containerStyle]}>
        <TextInput
          {...rest}
          clearButtonMode={'always'}
          autoComplete={'off'}
          dataDetectorTypes={'address'}
          onFocus={(event) => {
            setFocused(true);
            rest.onFocus?.(event);
          }}
          onLayout={(event: LayoutChangeEvent) => {
            setSuggestionListOffsetY(event.nativeEvent.layout.height);
            onLayout?.(event);
          }}
          ref={inputRef}
          style={style}
          enterKeyHint={returnKeyType || 'search'}
          placeholder={placeholder || 'Location'}
          placeholderTextColor={rest.placeholderTextColor || 'gray'}
          onChangeText={(text) => {
            // This only executes when typing,
            // not when updated programmatically
            setInputText(text);
            onChangeText?.(text, false);
          }}
          onBlur={(e: NativeSyntheticEvent<TextInputFocusEventData>) => {
            setFocused(false);
            onBlur?.(e);
          }}
          onSubmitEditing={(e) => {
            onSubmitEditing?.(e);
          }}
          value={value ?? inputText}
        />
        {/* Loading Overlay */}
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              display: busy ? 'flex' : 'none',
              backgroundColor: 'rgba(100, 100, 100, 0.6)',
              justifyContent: 'center',
            },
          ]}
        >
          <ActivityIndicator
            size="small"
            color={KEY_GRAY}
            style={{
              alignSelf: 'center',
            }}
          />
        </View>
        {/* Suggestion List */}
        {typeof suggestionListOffsetY === 'number' &&
        inputText?.length &&
        isSuggestionListVisible ? (
          <View
            style={[
              styles.suggestionList,
              suggestionListStyle,
              suggestionListOrientation === 'top'
                ? {
                    bottom: suggestionListOffsetY,
                    flexDirection: 'column-reverse',
                  }
                : {
                    top: suggestionListOffsetY,
                  },
            ]}
          >
            {suggestions?.length ? (
              suggestions.map((prediction, index) => (
                <LocationSuggestion
                  hideBorder={index === suggestions.length - 1}
                  borderOrientation={suggestionListOrientation}
                  onPress={() => onPlaceSelected(prediction)}
                  text={prediction.description}
                  key={index}
                />
              ))
            ) : (
              <View style={styles.emptyList}>
                {loading ? (
                  <ActivityIndicator color={ACTIVITY_INDICATOR_DEFAULT} />
                ) : (
                  <Text style={styles.emptyListText}>No matches found</Text>
                )}
              </View>
            )}
            {hidePoweredByGoogle ? null : (
              <View style={styles.attributionContainer}>
                <Image
                  style={styles.attributionLogo}
                  source={require('../../assets/attribution/powered_by_google_on_white.png')}
                  resizeMode={'contain'}
                />
              </View>
            )}
          </View>
        ) : null}
      </View>
    );
  },
);

interface LocationSuggestionProps {
  text: string;
  onPress: () => void;
  hideBorder: boolean;
  borderOrientation: 'top' | 'bottom';
}

const LocationSuggestion = ({
  text,
  onPress,
  hideBorder,
  borderOrientation,
}: LocationSuggestionProps) => {
  return (
    <TouchableHighlight
      // @ts-ignore
      style={Platform.select({
        web: {
          cursor: 'pointer',
        },
      })}
      underlayColor="#aaa"
      onPressIn={onPress}
    >
      <View
        pointerEvents="none"
        style={[
          styles.suggestion,
          hideBorder
            ? null
            : {
                [borderOrientation === 'top'
                  ? 'borderTopWidth'
                  : 'borderBottomWidth']: 1,
              },
        ]}
      >
        <Text style={styles.suggestionText}>{text}</Text>
      </View>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  suggestionList: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 99,
    marginVertical: 4,
    borderRadius: 8,
    backgroundColor: 'white',
    paddingVertical: 4,
    borderWidth: 1,
    borderColor: '#fbfbfb',
    shadowRadius: 5,
    shadowColor: '#dbdbdb',
    shadowOpacity: 0.5,
  },
  emptyList: {
    padding: 16,
  },
  emptyListText: {
    fontFamily: 'Lato',
    color: 'gray',
  },
  suggestionText: {
    fontFamily: 'Lato-Bold',
  },
  suggestion: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    borderColor: '#eee',
  },
  attributionContainer: {
    padding: 12,
  },
  attributionLogo: {
    height: 18,
    opacity: 0.8,
  },
});
