import { Ionicons } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import { useScrollToTop } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  RefreshControl,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import MyDonations from '../../components/ImpactTab/MyDonations/MyDonations';
import TeamSelector from '../../components/TeamSelector/TeamSelector';
import withAuthRequired from '../../components/withAuthRequired';
import OrgImpactFunding from './sections/OrgImpactFunding';
import OrgImpactJobPosts from './sections/OrgImpactJobPosts';
import Lightening from '/assets/jsicons/bottomnavigation/Lightening';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import Accordion from '/components/Accordion/Accordion';
import Button from '/components/Button';
import CampaignPreview from '/components/CampaignPreview/CampaignPreview';
import SearchableSection from '/components/ImpactTab/SearchableSection';
import TruncatedList from '/components/TruncatedList/TruncatedList';
import UpdateAvailableWidget from '/components/UpdateAvailableWidget';
import { KEY_GRAY, SECTION_CONTAINER } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import {
  ListManageOrganizationCampaignsQuery,
  TeamMemberRole,
  UserRole,
  useGetOrganizationStatsPreviewQuery,
  useListManageOrganizationCampaignsQuery,
} from '/generated/graphql';
import { ImpactStackParamList } from '/navigation/navigators/nested/ImpactStack';
import { CommonClusterParamList } from '/navigation/navigators/nested/common';
import MyCreativeConnect from '/components/ImpactTab/MyCreativeConnect/MyCreativeConnect';
import MyNewsPosts from '/components/MyNewsPosts/MyNewsPosts';
import MyMomentsInNature from '/components/MyMomentsInNature/MyMomentsInNature';
import MyEvents from '/components/ImpactTab/MyEvents/MyEvents';

type Campaign =
  ListManageOrganizationCampaignsQuery['listManageOrganizationCampaigns']['open']['items'][number];

interface IOrgSkilledImpactScreenProps {
  navigation: StackNavigationProp<
    CommonClusterParamList & ImpactStackParamList
  >;
}

function OrgOurImpact({ navigation }: IOrgSkilledImpactScreenProps) {
  const scrollViewRef = useRef<any>();

  useScrollToTop(scrollViewRef);

  const { userData } = useAuthContext();
  const {
    activeTeam,
    teams,
    loading: fetchingTeams,
    error: fetchTeamsError,
  } = useTeamContext();

  const userId =
    userData?.role === UserRole.Supporter ? activeTeam?.user.id : userData?.id;

  const [ourCampaignsQuery, setOurCampaignsQuery] = useState('');

  const [{ data: campaigns, fetching: loading, error }, refetch] =
    useListManageOrganizationCampaignsQuery({
      variables: {
        organizationId: userId as any,
        queryString: ourCampaignsQuery,
      },
      pause: !userId,
      requestPolicy: 'cache-and-network',
    });

  const [
    { data: stats, fetching: fetchingStats, error: fetchStatsError },
    refetchStats,
  ] = useGetOrganizationStatsPreviewQuery({
    variables: {
      id: userId as any,
    },
  });

  const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    if (!fetchingStats && !loading) {
      setIsRefreshing(false);
    }
  }, [fetchingStats, loading]);

  const refetchAll = useCallback(
    function () {
      refetch({ requestPolicy: 'network-only' });
      refetchStats({ requestPolicy: 'network-only' });
    },
    [refetch, refetchStats],
  );

  const onRefresh = useCallback(
    function () {
      setIsRefreshing(true);
      refetchAll();
    },
    [refetchAll],
  );

  useEffect(() => {
    /** Make sure the correct impact tab screen is being rendered at index 0 */
    if (!userData || userData?.role === UserRole.Conservationist) return;

    const state = navigation.getState();

    /** Supporters can only view this screen if they are on a team and
     * this screen is not the first screen in the stack */
    if (state.index === 0) {
      navigation.replace('SupSkilledImpact', {});
    } else if (
      state.index !== 0 &&
      !fetchingTeams &&
      !fetchTeamsError &&
      !teams?.length
    ) {
      navigation.goBack();
    }
  }, [userData, navigation, teams, fetchingTeams, fetchTeamsError]);

  function onManageCampaign(campaign: Campaign) {
    if (!campaign.id) return;

    navigation.navigate('ManageCampaign', { campaignId: campaign.id });
  }

  function shouldExpandOurCampaigns(list: any[]) {
    return ourCampaignsQuery?.length > 0 && list?.length > 0;
  }

  function editCampaignDraft(id: string) {
    navigation.navigate('CreateCampaign', {
      draftId: id,
    });
  }

  function onViewStats() {
    if (!userId) return;

    navigation.navigate('OrganizationStats', {
      userId,
    });
  }

  // TODO: Conditions for drafts, closed and archived campaigns.
  const drafts = campaigns?.listManageOrganizationCampaigns.drafts.items ?? [];
  const draftsTotal =
    campaigns?.listManageOrganizationCampaigns.drafts.total ?? 0;
  // const awaitingApproval = campaigns?.listManageOrganizationCampaigns.awaiting_approval ?? []; // TODO: Implement
  const active = campaigns?.listManageOrganizationCampaigns.open.items ?? [];
  const activeTotal =
    campaigns?.listManageOrganizationCampaigns.open.total ?? 0;
  const closed = campaigns?.listManageOrganizationCampaigns.closed.items ?? [];
  const closedTotal =
    campaigns?.listManageOrganizationCampaigns.closed.total ?? 0;
  const archived =
    campaigns?.listManageOrganizationCampaigns.archived.items ?? [];
  const archivedTotal =
    campaigns?.listManageOrganizationCampaigns.archived.total ?? 0;

  const lastTotalCampaigns = useRef(0);
  const totalCampaigns = useMemo(() => {
    if (ourCampaignsQuery.length) return lastTotalCampaigns.current;

    const total = activeTotal + closedTotal + archivedTotal;
    lastTotalCampaigns.current = total;

    return total;
  }, [activeTotal, archivedTotal, closedTotal, ourCampaignsQuery.length]);

  return (
    <View style={{ flex: 1 }}>
      <UpdateAvailableWidget />
      <KeyboardAwareScrollView
        innerRef={(r) => {
          if (r) scrollViewRef.current = r;
        }}
        scrollEventThrottle={100}
        refreshControl={
          <RefreshControl
            tintColor={KEY_GRAY}
            refreshing={isRefreshing}
            onRefresh={onRefresh}
          />
        }
        contentContainerStyle={{
          paddingBottom: 42,
        }}
      >
        {userData?.role === UserRole.Supporter ? (
          <TeamSelector
            requireTeamSelection
            style={{
              paddingBottom: 0,
            }}
            requireRole={TeamMemberRole.Creator}
          />
        ) : null}

        <View style={[SECTION_CONTAINER, { marginTop: 8, paddingBottom: 8 }]}>
          <Text style={styles.statsSectionLabel}>LIFETIME STATS</Text>

          {fetchingStats && !stats?.getUser ? (
            <View style={styles.statsLoadingContainer}>
              <ActivityIndicator size={22} color={KEY_GRAY} />
              <Text style={styles.statsLoadingText}>Fetching stats...</Text>
            </View>
          ) : fetchStatsError ? (
            <View style={styles.statsLoadingContainer}>
              <Text style={styles.statsErrorText}>
                There was an error fetching your stats.
              </Text>
              <Button
                label="Retry"
                onPress={refetchStats}
                containerStyle={{
                  marginTop: 8,
                }}
              />
            </View>
          ) : (
            <View style={styles.statsPreviewContainer}>
              <View style={styles.statContainer}>
                <Text style={styles.statNumber}>
                  {loading && !ourCampaignsQuery.length ? (
                    <ActivityIndicator size={22} color={KEY_GRAY} />
                  ) : (
                    totalCampaigns
                  )}
                </Text>
                <Text style={styles.statLabel}>Campaigns</Text>
              </View>

              <View style={styles.statsSeparator} />

              <View style={styles.statContainer}>
                <Text style={styles.statNumber}>
                  {fetchingStats ? (
                    <ActivityIndicator size={22} color={KEY_GRAY} />
                  ) : (
                    stats?.getUser?.total_volunteers
                  )}
                </Text>
                <Text style={styles.statLabel}>Volunteers</Text>
              </View>

              <View style={styles.statsSeparator} />

              <View style={styles.statContainer}>
                <Text style={styles.statNumber}>
                  {fetchingStats ? (
                    <ActivityIndicator size={22} color={KEY_GRAY} />
                  ) : (
                    stats?.getUser?.total_skill_applicants
                  )}
                </Text>
                <Text style={styles.statLabel}>Skilled Applicants</Text>
              </View>
            </View>
          )}

          <TouchableOpacity
            onPress={onViewStats}
            style={styles.viewStatsButton}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Ionicons
                name="stats-chart-sharp"
                size={20}
                color="gray"
                style={{
                  marginRight: 6,
                }}
              />
              <Text style={styles.viewStatsButtonText}>View All Stats</Text>
            </View>

            <ChevronRight />
          </TouchableOpacity>
        </View>

        <SearchableSection
          onQueryChange={setOurCampaignsQuery}
          title="Our Campaigns"
          renderIcon={() => <Lightening width={25} height={25} />}
          contentContainerStyle={{
            backgroundColor: 'transparent',
          }}
        >
          {loading && !campaigns?.listManageOrganizationCampaigns ? (
            <View style={styles.loadStatusContainer}>
              <ActivityIndicator color={KEY_GRAY} />
              <Text style={styles.loadingText}>Fetching...</Text>
            </View>
          ) : error ? (
            <View style={styles.loadStatusContainer}>
              <Text style={styles.errorText}>
                There was an error fetching campaigns
              </Text>
              <Button
                label="Retry"
                onPress={refetchAll}
                containerStyle={{
                  marginTop: 8,
                }}
              />
            </View>
          ) : (
            <>
              <Accordion
                loading={loading}
                expanded={shouldExpandOurCampaigns(drafts)}
                title="Drafts"
                icon={
                  <Ionicons name="checkmark-circle" color="#323338" size={30} />
                }
                badgeValue={draftsTotal}
                contentContainerStyle={styles.accordionContentContainerStyle}
                headerStyle={styles.accordionHeaderStyle}
              >
                {drafts.length === 0 ? (
                  <View style={styles.emptyContainer}>
                    <Text style={styles.emptyText}>Nothing to see here</Text>
                  </View>
                ) : (
                  <TruncatedList
                    style={{ width: '100%' }}
                    onViewMore={() => {
                      navigation.navigate('ListCampaigns', {
                        page: null,
                        layoutMode: 'compact',
                        filter: {
                          draft: true,
                          userId: userId,
                        },
                      });
                    }}
                    total={draftsTotal}
                    items={drafts}
                    renderItem={(camp, index) => (
                      <CampaignPreviewContainer key={camp.id ?? index}>
                        <CampaignPreview
                          onPress={() => {
                            if (camp.id) editCampaignDraft(camp.id);
                          }}
                          campaignPost={camp.posts?.items?.[0] || undefined}
                          campaign={camp}
                          showChevronArrow
                          // subtitle={<>
                          // TODO: Add ButtonToggles here to show support requests on drafts
                          // </>}
                        />
                      </CampaignPreviewContainer>
                    )}
                  />
                )}
              </Accordion>
              {/* <Accordion
                      expanded={shouldExpandOurCampaigns(awaitingApproval)}
                      title="Awaiting Approval"
                      subtitle={
                        pendingActionAlert && ACTION_ALERT[pendingActionAlert]?.text
                      }
                      subtitleStyle={{
                        color:
                          pendingActionAlert && ACTION_ALERT[pendingActionAlert]?.color,
                      }}
                      icon={<Ionicons name="checkmark-circle" color="#AAAAAA" size={30} />}
                      badgeValue={awaitingApproval.length}
                      contentContainerStyle={styles.accordionContentContainerStyle}
                      headerStyle={styles.accordionHeaderStyle}
                    >
                      {awaitingApproval.length === 0 ? (
                        <View style={styles.emptyContainer}>
                          <Text style={styles.emptyText}>Nothing to see here</Text>
                        </View>
                      ) : (
                        <TruncatedList
                          style={{ width: '100%' }}
                          onViewMore={() => {
                            navigate('ListCampaigns', {
                              page: null,
                              compact: true,
                              filter: {
                                userId: userId,
                                // TODO: Implement
                              },
                              title: 'Awaiting Approval',
                            });
                          }}
                          items={awaitingApproval}
                          renderItem={(camp, index) => (
                            <CampaignPreviewContainer key={camp.id ?? index}>
                              <CampaignPreview
                                onPress={() => onManageCampaign(camp)}
                                campaignPost={camp.posts?.items?.[0] || undefined}
                                campaign={camp}
                                showChevronArrow
                              />
                            </CampaignPreviewContainer>
                          )}
                        />
                      )}
                    </Accordion> */}
              <Accordion
                loading={loading}
                expanded={shouldExpandOurCampaigns(active)}
                title="Open Campaigns"
                icon={
                  <View
                    style={{
                      width: 28,
                      height: 28,
                    }}
                  >
                    {/* Add black background to make check mark black... */}
                    <View
                      style={{
                        position: 'absolute',
                        top: 5,
                        bottom: 5,
                        right: 5,
                        left: 5,
                        borderRadius: 50,
                        overflow: 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: -1,
                        elevation: -1,
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: 'black',
                          width: 24,
                          height: 24,
                          borderRadius: 24,
                          zIndex: -99,
                        }}
                      />
                    </View>
                    <Ionicons
                      name="checkmark-circle"
                      color="#3EFFB5"
                      size={30}
                    />
                  </View>
                }
                badgeValue={activeTotal}
                contentContainerStyle={styles.accordionContentContainerStyle}
                headerStyle={styles.accordionHeaderStyle}
              >
                {active.length === 0 ? (
                  <View style={styles.emptyContainer}>
                    <Text style={styles.emptyText}>Nothing to see here</Text>
                  </View>
                ) : (
                  <TruncatedList
                    style={{ width: '100%' }}
                    total={activeTotal}
                    items={active}
                    onViewMore={() => {
                      navigation.navigate('ListCampaigns', {
                        page: null,
                        layoutMode: 'compact',
                        filter: {
                          closed: false,
                          draft: false,
                          userId: userId,
                        },
                        title: 'Open Campaigns',
                      });
                    }}
                    renderItem={(camp, index) => (
                      <CampaignPreviewContainer key={camp.id ?? index}>
                        <CampaignPreview
                          onPress={() => onManageCampaign(camp)}
                          campaignPost={camp.posts?.items?.[0] || undefined}
                          campaign={camp}
                          showSocialControls
                          showChevronArrow
                        />
                      </CampaignPreviewContainer>
                    )}
                  />
                )}
              </Accordion>
              <Accordion
                loading={loading}
                expanded={shouldExpandOurCampaigns(closed)}
                title="Closed Campaigns"
                icon={
                  <Ionicons name="checkmark-circle" color="#FF0202" size={30} />
                }
                badgeValue={closedTotal}
                contentContainerStyle={styles.accordionContentContainerStyle}
                headerStyle={styles.accordionHeaderStyle}
              >
                {closed.length === 0 ? (
                  <View style={styles.emptyContainer}>
                    <Text style={styles.emptyText}>Nothing to see here</Text>
                  </View>
                ) : (
                  <TruncatedList
                    style={{ width: '100%' }}
                    total={closedTotal}
                    items={closed}
                    onViewMore={() => {
                      navigation.navigate('ListCampaigns', {
                        page: null,
                        layoutMode: 'compact',
                        filter: {
                          userId: userId,
                          closed: true,
                        },
                        title: 'Closed Campaigns',
                      });
                    }}
                    renderItem={(camp, index) => (
                      <CampaignPreviewContainer key={camp.id ?? index}>
                        <CampaignPreview
                          onPress={() => onManageCampaign(camp)}
                          campaignPost={camp.posts?.items?.[0] || undefined}
                          campaign={camp}
                          showSocialControls
                          showChevronArrow
                        />
                      </CampaignPreviewContainer>
                    )}
                  />
                )}
              </Accordion>
              <Accordion
                loading={loading}
                expanded={shouldExpandOurCampaigns(archived)}
                title="Archived Campaigns"
                icon={
                  <Ionicons name="checkmark-circle" color="#FFC502" size={30} />
                }
                badgeValue={archivedTotal}
                contentContainerStyle={styles.accordionContentContainerStyle}
                headerStyle={styles.accordionHeaderStyle}
              >
                {archived.length === 0 ? (
                  <View style={styles.emptyContainer}>
                    <Text style={styles.emptyText}>Nothing to see here</Text>
                  </View>
                ) : (
                  <TruncatedList
                    style={{ width: '100%' }}
                    items={archived}
                    total={archivedTotal}
                    onViewMore={() => {
                      navigation.navigate('ListCampaigns', {
                        page: null,
                        layoutMode: 'compact',
                        filter: {
                          userId: userId,
                          archived: true,
                        },
                        title: 'Archived Campaigns',
                      });
                    }}
                    renderItem={(camp, index) => (
                      <CampaignPreviewContainer key={camp.id ?? index}>
                        <CampaignPreview
                          onPress={() => onManageCampaign(camp)}
                          campaignPost={camp.posts?.items?.[0] || undefined}
                          campaign={camp}
                          showChevronArrow
                        />
                      </CampaignPreviewContainer>
                    )}
                  />
                )}
              </Accordion>
            </>
          )}
        </SearchableSection>

        {userData?.role === UserRole.Conservationist && <OrgImpactFunding />}

        <OrgImpactJobPosts />

        {userData?.role === UserRole.Conservationist && <MyDonations />}

        {!userId ? null : <MyEvents userId={userId} />}

        {!userId ? null : <MyNewsPosts userId={userId} />}

        {!userId ? null : <MyMomentsInNature userId={userId} />}

        {!userId ? null : <MyCreativeConnect userId={userId} />}
      </KeyboardAwareScrollView>
    </View>
  );
}

export default withAuthRequired(OrgOurImpact, {
  requireUserRole: UserRole.Conservationist,
});

const CampaignPreviewContainer = (props: React.PropsWithChildren<{}>) => (
  <View
    style={{
      width: '100%',
      borderRadius: 6,
      overflow: 'hidden',
      backgroundColor: 'white',
      marginVertical: 4,
      paddingRight: 6,
    }}
  >
    {props.children}
  </View>
);

const styles = StyleSheet.create({
  emptyContainer: {
    padding: 12,
    marginVertical: 6,
    width: '100%',
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
  },
  accordionContentContainerStyle: {
    backgroundColor: 'transparent',
    paddingVertical: 4,
  },
  accordionHeaderStyle: {
    backgroundColor: 'white',
    paddingHorizontal: 16,
  },
  statsPreviewContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  statContainer: {
    flex: 1,
    alignItems: 'center',
    padding: 8,
  },
  statNumber: {
    fontFamily: 'Lato-Bold',
    fontSize: 22,
    padding: 6,
  },
  statLabel: {
    fontSize: 17,
    fontFamily: 'Lato-Bold',
    textAlign: 'center',
  },
  statsSeparator: {
    height: '100%',
    width: 1,
    marginHorizontal: 12,
    backgroundColor: 'gray',
  },
  viewStatsButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
    padding: 8,
    borderTopWidth: 1,
    borderTopColor: '#ddd',
  },
  viewStatsButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
  statsSectionLabel: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 15,
    color: 'gray',
    marginBottom: 8,
    letterSpacing: 0.5,
  },
  statsLoadingContainer: {
    padding: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statsLoadingText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    marginTop: 4,
  },
  statsErrorText: {
    fontFamily: 'Lato-Bold',
    color: 'crimson',
  },
  loadStatusContainer: {
    padding: 16,
    paddingVertical: 24,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  loadingText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    marginTop: 4,
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    color: 'crimson',
  },
});
