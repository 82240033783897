import { Image, StyleSheet, Text, View } from 'react-native';
import TakeActionCallToAction, {
  ITakeActionCallToActionCampaign,
} from '/components/TakeAction/TakeActionCallToAction';
import { KEY_GRAY } from '/constants';
import {
  Campaign,
  CampaignImpactReferralInput,
  User,
} from '/generated/graphql';
import env from '/env';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';

interface ICampaign
  extends ITakeActionCallToActionCampaign,
    Pick<Campaign, 'closed' | 'closed_for_testing'> {}

interface ICampaignPostFooterComponentProps {
  preview: boolean;
  hidden: boolean;
  referral: CampaignImpactReferralInput | undefined;
  campaign: ICampaign | undefined | null;
  user: Pick<User, 'id'> | undefined | null;
}

export default function CampaignPostFooter(
  props: ICampaignPostFooterComponentProps,
) {
  return props.hidden ? null : (
    <>
      {props.campaign?.closed !== true ? (
        <View
          pointerEvents={props.preview ? 'none' : 'auto'}
          style={{
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <TakeActionCallToAction
            referral={props.referral}
            data={props.campaign}
          />
        </View>
      ) : props.campaign?.closed === true ? (
        <View style={styles.closedContainer}>
          <Text style={styles.closedText}>This is a closed campaign.</Text>
          {env.TESTER_ONLY_MODE && props.campaign.closed_for_testing ? (
            <>
              <HorizontalContainer>
                <Image
                  source={require('/assets/images/keyFullBlack.png')}
                  style={{
                    width: 24,
                    height: 24,
                    marginRight: 6,
                  }}
                />
                <Text style={styles.closedSubtext}>
                  This campaign was closed early while we make exciting upgrades
                  to the platform. Thanks for joining us as we take Key to the
                  next level!
                </Text>
              </HorizontalContainer>
            </>
          ) : (
            <Text style={styles.closedSubtext}>
              No support is currently needed.
            </Text>
          )}
        </View>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  closedContainer: {
    borderRadius: 6,
    marginHorizontal: 8,
    marginTop: 12,
    backgroundColor: '#f5f5f5',
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closedText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    textAlign: 'center',
  },
  closedSubtext: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: KEY_GRAY,
    textAlign: 'center',
  },
  bigIssuesLinkContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  bigIssuesLinkText: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
  },
});
