import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Avatar } from '@rneui/base';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import {
  FlatList,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import styles from '../CampaignPost.style';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import TranslateButton from '/components/common/TranslatableText/TranslateButton';
import { useModalContext } from '/context';
import {
  CampaignPost,
  Maybe,
  TranslatableText,
  User,
  UserMention,
} from '/generated/graphql';
import useTranslatableText from '/hooks/useTranslatableText';
import { DeepPartial } from '/types';
import { shorten } from '/util';
import UserMentionText from '/components/UserMentionText';

interface ICampaignPostAuthor
  extends Pick<User, 'id' | 'name' | 'profile_image'> {}

interface ICampaignPost extends Pick<CampaignPost, 'id' | 'created_at'> {
  authors?: ICampaignPostAuthor[] | undefined | null;
  description: Pick<TranslatableText, 'id' | 'text' | 'language'>;
  mentions?: Pick<UserMention, 'start' | 'end' | 'userId'>[] | null;
}

interface ICampaignDescriptionAndTimestampProps {
  data: ICampaignPost | undefined | null;
  hideTimestamp: boolean | undefined;
}

const Wrapper = Platform.OS === 'android' ? React.Fragment : Text;

export default function CampaignDescriptionAndTimestamp(
  props: ICampaignDescriptionAndTimestampProps,
) {
  const [translateText, setTranslateText] = useState(false);
  const {
    text: descriptionText,
    isTranslating,
    isTranslated,
  } = useTranslatableText({
    data: props.data?.description,
    showTranslation: translateText,
    onTranslateError() {
      setTranslateText(false);
    },
  });

  const { push } = useNavigation<StackNavigationProp<any>>();

  const [readMore, setReadMore] = useState(false);

  const authorsModalId = useRef<string | null>(null);

  const { spawnModal, closeModal } = useModalContext();

  function goToProfile(id: string) {
    push('Profile', { id });

    if (authorsModalId.current) {
      closeModal(authorsModalId.current);
      authorsModalId.current = null;
    }
  }

  function onSeeAuthors() {
    authorsModalId.current = spawnModal({
      title: 'Authors',
      style: {
        maxWidth: 400,
      },
      component: (
        <FlatList
          data={props.data?.authors ?? []}
          style={{ flexGrow: 0 }}
          renderItem={({ item: author }) => (
            <Pressable
              style={styles.authorCard}
              onPress={() => author?.id && goToProfile(author.id)}
            >
              <Avatar
                source={{ uri: author?.profile_image ?? '' }}
                rounded
                size={56}
              />
              <Text numberOfLines={2} style={styles.authorName}>
                {author?.name}
              </Text>
            </Pressable>
          )}
        />
      ),
    });
  }

  const wrapperProps =
    Platform.OS === 'android' ? {} : { style: styles.campaignDescriptionText };

  return (
    <View style={styles.campaignDescriptionAndTimestampContainer}>
      {/* Does not look work properly inline on Android and iOS */}
      {props.data?.authors?.length && Platform.OS !== 'web' ? (
        <Authors onSeeAuthors={onSeeAuthors} authors={props.data.authors} />
      ) : null}

      <Wrapper {...wrapperProps}>
        {props.data?.authors?.length && Platform.OS === 'web' ? (
          <Authors onSeeAuthors={onSeeAuthors} authors={props.data.authors} />
        ) : null}

        <UserMentionText
          key={props.data?.id}
          value={
            readMore ? descriptionText : shorten(descriptionText ?? '', 360)
          }
          withHyperlink
          mentions={props.data?.mentions}
          style={styles.campaignDescriptionText}
        />

        {(descriptionText?.length ?? 0) > 360 && (
          <Text
            style={styles.readMore}
            onPress={() => {
              setReadMore((prev) => !prev);
            }}
          >
            {readMore ? ' Read Less' : ' Read More'}
            &nbsp;
          </Text>
        )}
      </Wrapper>

      <TranslateButton
        fromLanguage={props.data?.description.language}
        containerStyle={{
          alignSelf: 'flex-start',
          marginTop: 4,
        }}
        isTranslating={isTranslating}
        isTranslated={isTranslated}
        onPress={() => {
          setTranslateText((prev) => !prev);
        }}
      />

      {!props.hideTimestamp ? (
        <Text style={styles.timeText}>
          {dayjs(Number(props.data?.created_at)).fromNow()}
        </Text>
      ) : null}
    </View>
  );
}

interface IAuthorsProps {
  onSeeAuthors: () => void;
  authors: DeepPartial<Maybe<User[]> | undefined>;
}

function Authors(props: IAuthorsProps) {
  return (
    <View style={{ flexDirection: 'row', marginRight: 4 }}>
      <View
        style={{
          borderRadius: 9,
          backgroundColor: 'gray',
        }}
      >
        <TouchableOpacity
          onPress={() => props.onSeeAuthors()}
          style={styles.campaignAuthorsContainer}
          activeOpacity={0.5}
        >
          {props.authors?.map((author) => (
            <Avatar
              key={author?.id}
              source={{ uri: author?.profile_image ?? '' }}
              rounded
              size={20}
              containerStyle={{
                marginRight: -10,
              }}
            />
          ))}
          <View
            style={{
              paddingLeft: 8,
            }}
          >
            <ChevronRight width={20} height={20} />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}
