import AsyncStorage from '@react-native-async-storage/async-storage';
import { RouteProp, useFocusEffect } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useLocales } from 'expo-localization';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Animated,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Text,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styles from './ViewMomentInNature.style';
import ViewMomentInNatureHeader from './ViewMomentInNatureHeader';
import Button from '/components/Button';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import MetaTags from '/components/MetaTags';
import MomentInNatureComponent from '/components/MomentInNatureComponent/MomentInNatureComponent';
import { ScrollViewType } from '/components/common/ScrollView/ScrollView';
import {
  ClientEventAction,
  ClientEventElement,
  useGetMomentInNatureQuery,
} from '/generated/graphql';
import useScrollHintAnimation from '/hooks/animation/useScrollHintAnimation';
import { shorten } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';
import useClientEvents from '/hooks/useClientEvents';

interface Props {
  route: RouteProp<any>;
  navigation: StackNavigationProp<any>;
}

const LIFETIME_SCROLL_HINT_TIMES = 2;
const REPEAT_SCROLL_HINT_TIMES = 3;

export default function ViewMomentInNature(props: Props) {
  const id = props.route.params?.id;

  const [isAtTop, setIsAtTop] = React.useState(true);

  const scrollViewRef = useRef<ScrollViewType>(null);

  const hasScrolled = useRef(false);
  const hasAnimatedScrollHintTimes = useRef(0);
  const beginAnimateTiemout = useRef<NodeJS.Timeout | null>(null);
  const { translateY, animate } = useScrollHintAnimation();

  const { languageCode } = useLocales()[0];
  const [shouldTranslate, setShouldTranslate] = useState(false);
  const [{ data, fetching, error }, refetch] = useGetMomentInNatureQuery({
    variables: {
      id,
      languageCode: shouldTranslate ? languageCode : undefined,
    },
  });

  const clientEvents = useClientEvents();

  const scheduleScrollHint = useCallback(() => {
    if (hasAnimatedScrollHintTimes.current < REPEAT_SCROLL_HINT_TIMES) {
      beginAnimateTiemout.current = setTimeout(() => {
        animate();

        hasAnimatedScrollHintTimes.current++;
        if (hasAnimatedScrollHintTimes.current < REPEAT_SCROLL_HINT_TIMES) {
          scheduleScrollHint();
        }
      }, 6000);
    }
  }, [animate]);

  useEffect(() => {
    /** Only use the scroll hint animation twice after installing the app */
    AsyncStorage.getItem('min-scroll-hint').then((value) => {
      if (
        !value ||
        isNaN(Number(value)) ||
        Number(value) < LIFETIME_SCROLL_HINT_TIMES
      ) {
        scheduleScrollHint();

        const prevValue = isNaN(Number(value)) ? 0 : Number(value);

        AsyncStorage.setItem('min-scroll-hint', String(prevValue + 1));
      }
    });
  }, [animate, scheduleScrollHint]);

  useFocusEffect(
    useCallback(() => {
      if (!id) return;

      clientEvents([
        {
          action: ClientEventAction.Impression,
          element: ClientEventElement.MomentInNature,
          page: 'moment_in_nature',
          momentInNatureId: id,
        },
      ]);
    }, [id, clientEvents]),
  );

  function onScroll(event: NativeSyntheticEvent<NativeScrollEvent>) {
    if (event.nativeEvent.contentOffset.y > 6 && !hasScrolled.current) {
      hasScrolled.current = true;
      if (beginAnimateTiemout.current)
        clearTimeout(beginAnimateTiemout.current);
    }

    if (event.nativeEvent.contentOffset.y < 30) {
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
    }
  }

  function scrollToOffset(y: number) {
    scrollViewRef.current?.scrollTo({
      y,
      animated: true,
    });
  }

  return (
    <>
      <MetaTags
        title={`Moment in Nature by ${shorten(
          data?.getMomentInNature.user.name || 'Key Conservation user',
          100,
        )}`}
        description={shorten(data?.getMomentInNature.caption.text || '', 140)}
        thumbnail={getCDNImageUri({
          uri: data?.getMomentInNature.video,
          isThumbnail: true,
        })}
      />
      <StatusBar style="light" />
      <ViewMomentInNatureHeader
        data={data?.getMomentInNature}
        isOpaque={!isAtTop || !!error}
      />
      {fetching && !data?.getMomentInNature ? (
        <LoadingSpinnerOverlay
          style={{
            zIndex: 1,
          }}
        />
      ) : error ? (
        <SafeAreaView style={styles.errorContainer}>
          <Text style={styles.errorText}>
            There was a problem fetching this post
          </Text>
          <Button
            label="Retry"
            containerStyle={{
              marginTop: 8,
            }}
            onPress={() => refetch()}
          />
        </SafeAreaView>
      ) : data ? (
        <Animated.ScrollView
          style={{
            flex: 1,
            transform: [
              {
                translateY: translateY,
              },
            ],
          }}
          contentContainerStyle={{
            paddingBottom: 12,
          }}
          onScroll={onScroll}
          ref={scrollViewRef}
        >
          <MomentInNatureComponent
            safeAreaInsetTop={100}
            translation={{
              isTranslated:
                shouldTranslate && !!data?.getMomentInNature?.caption?.text,
              isTranslating: shouldTranslate && fetching,
              onToggleTranslate() {
                setShouldTranslate(!shouldTranslate);
              },
            }}
            onScrollToContent={scrollToOffset}
            data={data?.getMomentInNature}
          />
        </Animated.ScrollView>
      ) : null}
    </>
  );
}
