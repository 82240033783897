import { View, Text, ViewStyle, Platform, Pressable } from 'react-native';
import React from 'react';
import { StyleProp } from 'react-native';
import ScrollView from '../ScrollView/ScrollView';
import styles from './styles';
import { KEY_GRAY } from '/constants';
import { Dropdown } from 'react-native-element-dropdown';
import HorizontalContainer from '../Generic/HorizontalContainer';
import { Entypo } from '@expo/vector-icons';

type ContainerProps = {
  style?: StyleProp<ViewStyle>;
};

function Container(props: React.PropsWithChildren<ContainerProps>) {
  return (
    <View style={[styles.container, props.style]}>
      <Text style={[styles.titleText]}>FILTER</Text>
      <ScrollView
        horizontal
        contentContainerStyle={{
          paddingBottom: 4,
          paddingLeft: 3, // Padding so button shadows are not clipped
        }}
        style={{
          marginLeft: -3, // Negative margin to account for padding
        }}
        showsHorizontalScrollIndicator={Platform.OS === 'web'}
      >
        {props.children}
      </ScrollView>
    </View>
  );
}

type FilterProps<ValueT = any> = {
  label: string;
} & (
  | {
      type: 'dropdown';
      options: {
        label: string;
        value: ValueT;
      }[];
      value: ValueT;
      onChange: (value: ValueT) => void;
    }
  | {
      type: 'button';
      onPress: () => void;
      buttonLabel: string;
    }
);

function Filter(props: FilterProps) {
  return (
    <View style={styles.fieldContainer}>
      <Text style={styles.fieldLabel}>{props.label}</Text>
      {props.type === 'dropdown' ? (
        <Dropdown
          style={styles.filterButton}
          selectedTextStyle={styles.filterButtonText}
          data={props.options}
          labelField="label"
          valueField="value"
          onChange={(item) => {
            props.onChange(item.value);
          }}
          placeholder="Select..."
          placeholderStyle={{
            color: KEY_GRAY,
            fontFamily: 'Lato-Bold',
          }}
          value={props.value}
        />
      ) : (
        <Pressable onPress={props.onPress} style={styles.filterButton}>
          <HorizontalContainer
            style={{
              justifyContent: 'space-between',
            }}
          >
            <Text numberOfLines={1} style={styles.filterButtonText}>
              {props.buttonLabel}
            </Text>
            <Entypo
              name="chevron-small-down"
              size={20}
              style={{
                width: 20,
                height: 20,
              }}
              color="gray"
            />
          </HorizontalContainer>
        </Pressable>
      )}
    </View>
  );
}

const FilterComponent = {
  Container,
  Filter,
};

export default FilterComponent;
