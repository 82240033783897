import { UpdateResolver } from '@urql/exchange-graphcache';

const inviteEventAttendee: UpdateResolver = (result, args, cache) => {
  const eventId = (args as any).input?.eventId;
  if (!eventId) return;

  cache.invalidate(
    {
      __typename: 'Event',
      id: eventId as string,
    },
    'attendees',
  );
  cache.invalidate(
    {
      __typename: 'Event',
      id: eventId as string,
    },
    'attendees',
    {
      confirmed: false,
      query: '',
    },
  );
};

export default inviteEventAttendee;
