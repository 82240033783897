/**
 * From https://github.com/Gamote/lottie-react/blob/main/src/components/Lottie.ts
 */

import { useEffect } from 'react';
import { LottieComponentProps } from './types';
import useLottie from './useLottie.web';
import useLottieInteractivity from './useLottieInteractivity.web';
import { LottieViewProps } from 'lottie-react-native';

export default function LottieView(_props: LottieViewProps) {
  const props = {
    ..._props,
    animationData: _props.source,
    autoPlay: _props.autoPlay,
    loop: _props.loop,
    style: _props.style,
  } as LottieComponentProps;
  const { style, interactivity, ...lottieProps } = props;

  /**
   * Initialize the 'useLottie' hook
   */
  const {
    View,
    play,
    stop,
    pause,
    setSpeed,
    goToAndStop,
    goToAndPlay,
    setDirection,
    playSegments,
    setSubframe,
    getDuration,
    destroy,
    animationContainerRef,
    animationLoaded,
    animationItem,
  } = useLottie(lottieProps, style);

  /**
   * Make the hook variables/methods available through the provided 'lottieRef'
   */
  useEffect(() => {
    if (props.lottieRef) {
      props.lottieRef.current = {
        play,
        stop,
        pause,
        setSpeed,
        goToAndPlay,
        goToAndStop,
        setDirection,
        playSegments,
        setSubframe,
        getDuration,
        destroy,
        animationContainerRef,
        animationLoaded,
        animationItem,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lottieRef?.current]);

  return useLottieInteractivity({
    lottieObj: {
      View,
      play,
      stop,
      pause,
      setSpeed,
      goToAndStop,
      goToAndPlay,
      setDirection,
      playSegments,
      setSubframe,
      getDuration,
      destroy,
      animationContainerRef,
      animationLoaded,
      animationItem,
    },
    actions: interactivity?.actions ?? [],
    mode: interactivity?.mode ?? 'scroll',
  });
}
