import {
  Link,
  RouteProp,
  useIsFocused,
  useLinkTo,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  ComponentProps,
  JSXElementConstructor,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Text, View } from 'react-native';
import LoadingSpinnerOverlay from './LoadingSpinnerOverlay';
import { useAuthContext, useTeamContext } from '/context';
import { TeamMemberRole, UserRole } from '/generated/graphql';
import { isUnderPrivileged } from '/util';

type WithAuthRequiredOptions = {
  requireUserRole?: UserRole;
  excludeTeamMembers?: boolean;
  /** Optionally change where user is redirected to. Default is `/` */
  redirectRoute?: (route: RouteProp<any>) => string;
};

/**
 * A higher order component to wrap around screens that require authentication
 */
export default function withAuthRequired<
  ComponentType extends JSXElementConstructor<any>,
>(Component: ComponentType, options?: WithAuthRequiredOptions) {
  return (props: ComponentProps<ComponentType>) => {
    const { isAuthenticating, fetching, userData, userAttributes } =
      useAuthContext();
    const { teams, loading } = useTeamContext();
    const { canGoBack, goBack, replace } =
      useNavigation<StackNavigationProp<any>>();
    const linkTo = useLinkTo();
    const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined);

    const isFocused = useIsFocused();

    const route = useRoute();

    // Are we ready to render protected content?
    const [ready, setReady] = useState(false);

    const init = useCallback(
      function () {
        if (redirectTo) setRedirectTo(undefined);

        const isAuthorizedTeamMember = teams?.some((team) => {
          return !isUnderPrivileged(
            TeamMemberRole.Creator,
            team.membership?.team_role,
          );
        });
        const matchesRequiredRole = options?.requireUserRole
          ? userData?.role === options?.requireUserRole ||
            (options?.requireUserRole === UserRole.Conservationist &&
              isAuthorizedTeamMember &&
              !options?.excludeTeamMembers)
          : true;

        const isAuthorized = userAttributes?.sub && matchesRequiredRole;

        // If user data is present, set app to ready
        if (isAuthorized) {
          setReady(true);
        }
        // Otherwise, redirect
        else {
          const shouldGoBack = !!userData?.id && !options?.redirectRoute;

          if (shouldGoBack && canGoBack()) goBack();

          let params = '';

          if (route.path) {
            const returnTo = encodeURIComponent(route.path);
            params = `?returnto=${returnTo}`;
          }

          /** If we're not not signed in, default redirect route should be
           * log in screen */
          const DEFAULT_REDIRECT_ROUTE = shouldGoBack ? '/' : `/login${params}`;

          let redirectRoute = DEFAULT_REDIRECT_ROUTE;

          if (typeof options?.redirectRoute === 'function') {
            redirectRoute = options.redirectRoute(route) || redirectRoute;
          } else if (options?.redirectRoute) {
            redirectRoute = options.redirectRoute;
          }

          replace('main');
          linkTo(redirectRoute);
          setRedirectTo(redirectRoute);
        }
      },
      [
        canGoBack,
        goBack,
        linkTo,
        redirectTo,
        replace,
        route,
        teams,
        userAttributes?.sub,
        userData?.id,
        userData?.role,
      ],
    );

    useEffect(() => {
      /** If auth state changes, reset ready state */
      setReady(false);
    }, [userAttributes?.sub]);

    useEffect(() => {
      if (!isFocused) return;

      // If app loads into an auth-required screen, we will wait for authentication to complete
      // before determining readiness to render restricted content
      if (
        !isAuthenticating &&
        (!loading || !options?.requireUserRole) &&
        !fetching
      )
        init();
    }, [isFocused, userAttributes, isAuthenticating, loading, fetching, init]);

    if (redirectTo)
      return (
        <View>
          <Text>Unathorized. Redirecting...</Text>
          <Link to={redirectTo || '/'}>
            <Text>Click here if you are not automatically redirected</Text>
          </Link>
        </View>
      );
    if (userAttributes?.sub && ready === true) return <Component {...props} />;
    else return <LoadingSpinnerOverlay />;
  };
}
