import { View, Text, StyleSheet, StyleProp, TextStyle } from 'react-native';
import React, { useState } from 'react';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import Avatar from '../Avatar';
import Button from '../Button';
import { FontAwesome } from '@expo/vector-icons';
import { IUserPickerUser } from './UserPicker';

type Props = {
  user: IUserPickerUser;
  darkTheme: boolean;
  subtitle?: string;
  subtitleStyle?: StyleProp<TextStyle>;
  onRemove: () => void | Promise<void>;
};

export default function UserPickerUserCard({
  user,
  subtitle,
  subtitleStyle,
  onRemove,
  ...props
}: Props) {
  const [deleting, setDeleting] = useState(false);

  return (
    <HorizontalContainer
      style={{
        marginTop: 6,
        opacity: deleting ? 0.5 : 1,
        pointerEvents: deleting ? 'none' : 'auto',
      }}
      key={user.id}
    >
      <Avatar
        rounded
        size={48}
        containerStyle={{
          marginRight: 8,
        }}
        source={{ uri: user.profile_image }}
      />

      <View style={{ flex: 1 }}>
        <Text
          style={[
            styles.userName,
            props.darkTheme ? { color: 'white' } : undefined,
          ]}
          numberOfLines={2}
        >
          {user.name}
        </Text>

        {subtitle ? (
          <Text
            style={[
              styles.userSubtitle,
              props.darkTheme ? { color: 'white' } : undefined,
              subtitleStyle,
            ]}
            numberOfLines={2}
          >
            {subtitle}
          </Text>
        ) : null}
      </View>

      <Button
        loading={deleting}
        onPress={async () => {
          try {
            setDeleting(true);
            await onRemove();
          } catch {
          } finally {
            setDeleting(false);
          }
        }}
        label={<FontAwesome name="trash-o" size={24} color="black" />}
      />
    </HorizontalContainer>
  );
}

const styles = StyleSheet.create({
  userName: {
    flexShrink: 1,
    fontFamily: 'Lato-Bold',
    fontSize: 15,
  },
  userSubtitle: {
    flexShrink: 1,
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 14,
  },
});
