import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { Text, View } from 'react-native';
import GenericError from '../../../components/common/Generic/GenericError';
import Button from '/components/Button';
import SearchableSection from '/components/ImpactTab/SearchableSection';
import JobPostCard from '/components/JobBoard/elements/JobPostCard';
import ListLoading from '/components/ListLoading';
import TruncatedList from '/components/TruncatedList/TruncatedList';
import { useAuthContext, useTeamContext } from '/context';
import { UserRole, useListJobPostsQuery } from '/generated/graphql';
import useDebouncedState from '/hooks/useDebouncedState';
import { isOrganizationProfileComplete } from '/util';

export default function OrgImpactJobPosts() {
  const { userData } = useAuthContext();
  const { activeTeam } = useTeamContext();

  const navigation = useNavigation<StackNavigationProp<any>>();

  const userId =
    userData?.role === UserRole.Conservationist
      ? userData?.id
      : activeTeam?.user.id;

  const [, query, setQuery] = useDebouncedState('');

  const [{ data, fetching, error }, refetch] = useListJobPostsQuery({
    variables: {
      limit: 2,
      filter: {
        title: query,
        userId,
      },
    },
  });

  return (
    <SearchableSection
      title="Job Posts"
      contentContainerStyle={{
        backgroundColor: 'white',
        paddingHorizontal: 16,
      }}
      renderIcon={() => (
        <FontAwesome5 name="briefcase" size={22} color="black" />
      )}
      onQueryChange={setQuery}
    >
      {fetching ? (
        <ListLoading />
      ) : error ? (
        <GenericError onRetry={refetch} />
      ) : !query.length && !data?.listJobPosts.items.length ? (
        <View
          style={{
            alignItems: 'center',
            alignSelf: 'center',
            padding: 24,
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              alignSelf: 'center',
              fontFamily: 'Lato-Bold',
              fontSize: 16,
            }}
          >
            {!query ? 'No job posts yet.' : `No job posts matching "${query}".`}
          </Text>
          {!query &&
          activeTeam?.user &&
          isOrganizationProfileComplete(activeTeam?.user) ? (
            <Button
              label="Create Job Post"
              containerStyle={{
                marginTop: 8,
              }}
              onPress={() => {
                navigation.navigate('CreateJobPost');
              }}
            />
          ) : null}
        </View>
      ) : (
        <TruncatedList
          maxLength={2}
          onViewMore={() => {
            navigation.navigate('ListJobPosts', {
              userId,
              query,
            });
          }}
          items={data?.listJobPosts.items ?? []}
          total={data?.listJobPosts.total}
          renderItem={(item) => {
            return (
              <JobPostCard
                data={item}
                onPress={() => {
                  navigation.navigate('ViewJobPost', { id: item.id });
                }}
              />
            );
          }}
        />
      )}
    </SearchableSection>
  );
}
