import React from 'react';
import { StyleSheet, Text, ViewStyle } from 'react-native';

import SectionContainer from '../Section/SectionContainer';
import KeyIconGray from '/assets/jsicons/KeyCon/KeyIconGray';

const GenericListEmptyComponent = (props: {
  placeholderText?: string;
  style?: ViewStyle;
  tintColor?: string;
}) => {
  return (
    <SectionContainer style={[styles.container, props.style]}>
      <KeyIconGray fill={props.tintColor} />
      <Text
        style={[
          styles.text,
          props.tintColor
            ? {
                color: props.tintColor,
              }
            : undefined,
        ]}
      >
        {props.placeholderText || 'Nothing to see here'}
      </Text>
    </SectionContainer>
  );
};

export default GenericListEmptyComponent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  text: {
    fontFamily: 'Lato-Bold',
    fontSize: 20,
    textAlign: 'center',
    paddingTop: 15,
    color: '#505050',
  },
});
