import { AntDesign, FontAwesome } from '@expo/vector-icons';
import React, { useMemo, useRef, useState } from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import UserPickerModal from './UserPickerModal';
import UserPickerUserCard from './UserPickerUserCard';
import { KEY_GRAY, KEY_GREEN, PRIMARY_BUTTON_BACKGROUND } from '/constants';
import { useModalContext } from '/context';
import { User, UserRole } from '/generated/graphql';

const MAX_USERS_PER_PAGE = 5;

export interface IUserPickerUser
  extends Pick<User, 'id' | 'name' | 'profile_image'> {}

type Props = {
  style?: StyleProp<ViewStyle>;
  darkTheme?: boolean;
  buttonStyle?: StyleProp<ViewStyle>;
  /** Override the default button label */
  buttonLabel?: React.ReactNode;
  role?: UserRole;
  users: IUserPickerUser[];
  addButtonText?: string;
  showFilters?: boolean;
  searchModalTitle?: string;
  maxUsers?: number;
  /** IDs of users to exclude from search results */
  excludeUserIds?: string[];
  /** If true, the user will be able to select themselves */
  includeSelf?: boolean;
  getSubtitle?: (user: IUserPickerUser) => string;
  getSubtitleStyle?: (user: IUserPickerUser) => StyleProp<TextStyle>;
  onChange: (users: IUserPickerUser[]) => void;
  /** Override default behavior of removing user from list */
  onRemoveUser?: (user: IUserPickerUser) => void | Promise<void>;
};

export default function UserPicker({
  onChange,
  getSubtitle,
  getSubtitleStyle,
  onRemoveUser,
  ...props
}: Props) {
  const { spawnModal, closeModal } = useModalContext();

  const [page, setPage] = useState(0);

  const addUserModalIdRef = useRef<string | undefined>();
  function onAddUser() {
    addUserModalIdRef.current = spawnModal({
      title: props.searchModalTitle ?? 'Select User',
      style: {
        maxWidth: 480,
      },
      component: (
        <UserPickerModal
          role={props.role}
          excludeUserIds={Array.from(
            new Set([
              ...props.users.map((user) => user.id),
              ...(props.excludeUserIds ?? []),
            ]),
          )}
          includeSelf={props.includeSelf ?? false}
          showFilters={props.showFilters}
          onClose={(data) => {
            if (data) {
              onChange([...props.users, data]);
            }

            closeModal(addUserModalIdRef.current ?? '');
          }}
        />
      ),
    });
  }

  const renderedUsers = useMemo(() => {
    return props.users
      ?.slice(page * MAX_USERS_PER_PAGE, (page + 1) * MAX_USERS_PER_PAGE)
      .map((user) => {
        return (
          <UserPickerUserCard
            key={user.id}
            user={user}
            darkTheme={props.darkTheme ?? false}
            subtitle={getSubtitle?.(user)}
            subtitleStyle={getSubtitleStyle?.(user)}
            onRemove={
              typeof onRemoveUser === 'function'
                ? () => onRemoveUser(user)
                : () => {
                    onChange(props.users.filter((u) => u.id !== user.id));
                  }
            }
          />
        );
      });
  }, [
    props.users,
    props.darkTheme,
    page,
    getSubtitle,
    getSubtitleStyle,
    onRemoveUser,
    onChange,
  ]);

  const userTypeText =
    props.role === UserRole.Supporter
      ? 'Supporter'
      : props.role === UserRole.Conservationist
      ? 'Organization'
      : 'User';

  const numPages = Math.ceil((props.users?.length ?? 0) / MAX_USERS_PER_PAGE);

  return (
    <View style={props.style}>
      {props.maxUsers && props.users.length >= props.maxUsers ? null : (
        <Pressable
          style={[styles.addButton, props.buttonStyle]}
          onPress={onAddUser}
        >
          {props.buttonLabel ?? (
            <HorizontalContainer>
              <View
                style={{
                  marginRight: 6,
                }}
              >
                <FontAwesome name="user" size={24} color={'black'} />
                <FontAwesome
                  style={{
                    position: 'absolute',
                    right: -4,
                    bottom: -4,
                  }}
                  name="plus"
                  size={16}
                  color={KEY_GREEN}
                />
              </View>
              <Text style={styles.addButtonText}>
                {props.addButtonText ?? `Add ${userTypeText}`}
              </Text>
            </HorizontalContainer>
          )}
        </Pressable>
      )}

      {(props?.users?.length ?? 0) > MAX_USERS_PER_PAGE && (
        <View style={styles.paginationContainer}>
          <Pressable
            onPress={() => setPage(page - 1)}
            disabled={page === 0}
            style={[
              styles.paginationButton,
              {
                opacity: page === 0 ? 0.5 : 1,
              },
            ]}
          >
            <AntDesign
              name="caretleft"
              size={22}
              color={props.darkTheme ? 'white' : 'black'}
            />
          </Pressable>

          <Text
            style={[
              styles.paginationText,
              props.darkTheme ? { color: 'lightgray' } : undefined,
            ]}
          >
            {page + 1} of {numPages}
          </Text>
          <Pressable
            testID="campaign-picker-next-button"
            onPress={() => setPage(page + 1)}
            disabled={page === numPages - 1}
            style={[
              styles.paginationButton,
              {
                opacity: page === numPages - 1 ? 0.5 : 1,
              },
            ]}
          >
            <AntDesign
              name="caretright"
              size={22}
              color={props.darkTheme ? 'white' : 'black'}
            />
          </Pressable>
        </View>
      )}

      {renderedUsers}
    </View>
  );
}

const styles = StyleSheet.create({
  addButton: {
    backgroundColor: PRIMARY_BUTTON_BACKGROUND,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    paddingVertical: 12,
    marginVertical: 4,
  },
  addButtonText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 16,
  },
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 240,
    alignSelf: 'center',
    marginVertical: 8,
  },
  paginationText: {
    flex: 1,
    textAlign: 'center',
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 15,
  },
  paginationButton: {
    padding: 10,
  },
});
