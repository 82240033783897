import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import {
  GetNotificationsForUserDocument,
  NotificationType,
} from '/generated/graphql';
import { isValidJson } from '/util';

const EventPartnerFragment = gql`
  fragment DeleteEventPartnerFragment on Event {
    id
    partnership(userId: $userId) {
      userId
      eventId
    }
  }
`;

const deleteEventPartner: UpdateResolver = (result, args, cache) => {
  //   removeItemFromPaginatedField({
  //     cache,
  //     entity: {
  //       __typename: 'Event',
  //       id: args.eventId as string,
  //     },
  //     paginatedField: {
  //       name: 'attendees',
  //       fragment: ListEventAttendeesFragmentFragmentDoc,
  //     },
  //     removeItem: {
  //       userId: args.userId as string,
  //       eventId: args.eventId as string,
  //     },
  //   });

  // Go through all notifications, remove those whose `data` field contains the strings
  // `eventId: ${eventId}` and `userId: ${userId}`.
  const queries = cache.inspectFields('Query');
  const getNotificationsQueries = queries.filter(
    (q) => q.fieldName === 'getNotificationsForUser',
  );

  getNotificationsQueries.forEach((q) => {
    if (q.arguments?.userId !== args.userId) return;

    cache.updateQuery(
      {
        query: GetNotificationsForUserDocument,
        variables: q.arguments,
      },
      (data) => {
        const prev = data?.getNotificationsForUser as any;
        return {
          ...data,
          __typename: 'Query',
          getNotificationsForUser: {
            __typename: 'GetAllNotificationsResponse',
            ...prev,
            actionable: ((prev?.actionable as any[]) ?? []).reduce(
              (acc, cur) => {
                const section = {
                  ...cur,
                  notifications: cur.notifications.filter((n: any) => {
                    if (n.type !== NotificationType.EventPartnershipInvite)
                      return true;

                    const parsedData = isValidJson(n.data)
                      ? JSON.parse(n.data)
                      : {};

                    return parsedData?.eventId !== args.eventId;
                  }),
                };

                if (section.notifications.length === 0) return acc;
                return [...acc, section];
              },
              [],
            ),
          },
        };
      },
    );
  });

  const ARGUMENT_SETS_TUPLE = [undefined, { userId: args.userId }] as const;

  ARGUMENT_SETS_TUPLE.forEach((_args) => {
    const event = cache.readFragment(
      EventPartnerFragment,
      {
        __typename: 'Event',
        id: args.eventId as string,
      },
      _args,
    );

    if (event?.partnership?.userId === args.userId) {
      cache.link(
        {
          __typename: 'Event',
          id: args.eventId as string,
        },
        'partnership',
        _args ?? null,
        _args ? null : undefined,
      );
    }
  });

  // cache.invalidate(
  //   {
  //     __typename: 'Event',
  //     id: args.eventId as string,
  //   },
  //   'hosts',
  // );
};

export default deleteEventPartner;
