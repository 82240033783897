import Constants from 'expo-constants';

interface IEnvironmentVariables {
  WEB_APP_URL: string;
  DEV_ENV_PW: string | undefined;
  ENV_NAME: string;
  GRAPHQL_API: string;
  CDN_ENDPOINT: string;
  SENTRY_DSN: string;
  IOS_GOOGLE_MAPS_KEY: string;
  ANDROID_GOOGLE_MAPS_KEY: string;
  WEB_GOOGLE_MAPS_KEY: string;
  PRIVATE_DOCUMENTS_URL: string;
  FACEBOOK_APP_ID: string;
  TESTER_ONLY_MODE: boolean; // TODO: Remove this before release
}

if (!Constants.expoConfig?.extra)
  console.error(
    'Configuration Error: Env.ts found no environment variables in Constants.expoConfig.extra. Make sure you are running a build that properly includes environment variables.',
  );

export default (Constants.expoConfig?.extra || {}) as IEnvironmentVariables;
