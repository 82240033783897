import { UpdateResolver } from '@urql/exchange-graphcache';

const attendEvent: UpdateResolver = (result, args, cache) => {
  if (!args.userId)
    cache.link(
      {
        __typename: 'Event',
        id: args.eventId as string,
      },
      'attendee',
      result.attendEvent as any,
    );
  else
    cache.link(
      {
        __typename: 'Event',
        id: args.eventId as string,
      },
      'attendee',
      {
        userId: args.userId as string,
      },
      result.attendEvent as any,
    );
};

export default attendEvent;
