import type { NavigationAction } from '@react-navigation/core';
import { useLinkProps } from '@react-navigation/native';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { openURL } from 'expo-linking';
import * as React from 'react';
import {
  GestureResponderEvent,
  Platform,
  Pressable,
  Text,
  TextProps,
} from 'react-native';
import isURL from 'validator/lib/isURL';

type Props<ParamList extends ReactNavigation.RootParamList> = {
  to: To<ParamList> | string;
  action?: NavigationAction;
  target?: string;
  noLinkProps?: boolean;
  onPress?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent,
  ) => void;
} & (TextProps & { children: React.ReactNode });

/**
 * Component to render link to another screen or external address using a path.
 * Uses an anchor tag on the web.
 *
 * @param props.to Absolute path to screen (e.g. `/feeds/hot`) or any URL.
 * @param props.action Optional action to use for in-page navigation. By default, the path is parsed to an action based on linking config.
 * @param props.children Child elements to render the content.
 */
export default function Link<ParamList extends ReactNavigation.RootParamList>({
  to,
  action,
  onPress: props_onPress,
  ...rest
}: Props<ParamList>) {
  const linkProps = useLinkProps<ParamList>({ to, action });

  const onPress = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent,
  ) => {
    props_onPress?.(e);

    if (e.defaultPrevented) return;

    if (typeof to === 'string' && isURL(to)) {
      e.preventDefault();

      const { hostname } = new URL(to);

      if (Platform.OS === 'web')
        window.open(
          to,
          rest.target ??
            (hostname.includes('keyconservation') ? undefined : '_blank'),
        );
      else openURL(to);
    } else {
      linkProps.onPress(e);
    }
  };

  return React.createElement(
    Platform.OS === 'web' ? Text : (Pressable as any),
    {
      ...(rest.noLinkProps || Platform.OS !== 'web' ? {} : linkProps),
      ...rest,
      ...Platform.select({
        web: { onClick: onPress } as any,
        default: { onPress },
      }),
      style: [
        Platform.select({
          web: {
            cursor: 'pointer',
          },
        }),
        rest.style,
      ],
    },
  );
}
