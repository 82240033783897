import { ResizeMode } from 'expo-av';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import React, { Component, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Image as ImageComponent,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import ffmpeg from '/util/ffmpeg';

import {
  ActionSheetProps,
  connectActionSheet,
} from '@mtourj/react-native-action-sheet';

import { Feather, FontAwesome, Ionicons } from '@expo/vector-icons';
import { isCancelError } from 'aws-amplify/storage';
import styles from '../../constants/UploadMedia';
import Button from '../Button';
import withFileUpload, { FileUploadProps } from '../withFileUpload';
import { determineIfVideo } from '/util';

import { Asset } from 'expo-media-library';
import _ from 'lodash';
import { useDropzone as _useDropzone } from 'react-dropzone';
import MediaEditor, { MediaEditorMedia } from '../MediaEditor/MediaEditor';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import getCDNImageUri from '/util/getCDNImageUri';

const PLACEHOLDER = require('/assets/images/blank-profile-picture.webp');

const MEDIA_TYPES = {
  All: ImagePicker.MediaTypeOptions.All,
  Images: ImagePicker.MediaTypeOptions.Images,
  Videos: ImagePicker.MediaTypeOptions.Videos,
};

const DragAndDrop = ({
  children,
  style,
  onDrop,
  onDragEnter,
  onDragLeave,
  accept,
  multiple = false,
}: React.PropsWithChildren<any>) => {
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    multiple,
    accept,
    onDrop,
  });

  useEffect(() => {
    if (isDragActive) {
      onDragEnter?.();
    } else {
      onDragLeave?.();
    }
  }, [isDragActive, onDragEnter, onDragLeave]);

  const inputProps = getInputProps();
  const rootProps = getRootProps();

  return Platform.OS === 'web' ? (
    <div
      {...rootProps}
      onClick={undefined}
      style={{ display: 'flex', flexDirection: 'column', ...style }}
    >
      <input
        {...getInputProps()}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();

          inputProps?.onDrop?.(e);
        }}
      />
      {children}
    </div>
  ) : (
    <View style={style}>{children}</View>
  );
};

const useDropzone =
  Platform.OS === 'web'
    ? _useDropzone
    : () => ({
        getRootProps() {
          return undefined;
        },
        getInputProps() {
          return undefined;
        },
        isDragActive: false,
      });

export type UploadMediaItem = {
  uri: string;
  thumbnailUri?: string;
  pendingUpload?: boolean;
};

interface IUploadMediaProps extends React.PropsWithRef<{}> {
  media?: {
    uri: string;
    thumbnailUri?: string;
    pendingUpload?: boolean;
  }[];
  /** ONLY WORKS ON IMAGES. If set, upload media will use those dimensions when opening ImagePicker */
  targetMediaDimensions?: {
    width: number;
    height: number;
  };
  /** If true, images uploaded here will not be deleted when user's data is deleted */
  persistAfterUserDeletion?: boolean;
  disabled?: boolean;
  /** Default is 'All' */
  mediaType?: 'All' | 'Images' | 'Videos';
  style?: StyleProp<ViewStyle>;
  fontSize?: number;
  circular?: boolean;
  multiple?: boolean;
  /** If `multiple` is set, this dictates the maximum number of files */
  selectionLimit?: number;
  /** Setting to `true` will display a placeholder image when empty */
  isAvatar?: boolean;
  size?: 'auto' | number;
  removable?: boolean;
  title?: string;
  hideProgressIndicator?: boolean;
  previewResizeMode?: ResizeMode;
  /** Replace default UploadMedia component with a custom component */
  renderComponent?: (pressed: boolean, onPress: () => void) => JSX.Element;
  placeholderImage?: JSX.Element | (() => JSX.Element);
  onChangeMedia: (media: { uri: string; thumbnailUri?: string }[]) => any;
  /** Callback function called when an upload starts */
  onUploadStart?: (media: { uri: string; thumbnailUri?: string }[]) => void;
  /** Callback function called when upload progress has been made */
  onUploadProgress?: (
    progress: number,
    media: { uri: string; thumbnailUri?: string }[],
  ) => void;
  /** Callback function called when an uploads ends in a success */
  onUploadSuccess?: () => void;
  /** Callback function called when upload ends in a failure
   * @param retry Callback function to retry upload */
  onUploadError?: (retry: () => void) => void;
  /** Callback function called when loading user selected media fails */
  onError?: (message: string) => void;
  /** Callback function called when upload ends, regardless of success */
  onUploadEnd?: () => void;
}

type MediaType = Omit<MediaEditorMedia, 'asset'> & {
  asset?: Pick<Asset, 'mediaType' | 'width' | 'height' | 'uri' | 'duration'> & {
    id?: string;
  };
  pendingUpload: boolean;
};

interface IUploadMediaState {
  media: MediaType[];
  currentMedia: MediaType | undefined;
  /** If the parent component loses `thumbnailUri` of a media item,
   * this map will be used to retrieve the thumbnail URI. */
  thumbnailMap: { [uri: string]: string | undefined };
  currentIndex: number;
  /** Any object URLs that need to be revoked on component unmount to avoid
   * memory leaks */
  revokeObjectURLs: string[];
  editorVisible: boolean;
  generatingThumbnail: boolean;
  /** User-facing error message, assigned when an error occurs while user is picking media */
  error: string | undefined;
  uploading: boolean;
  uploadProgress: number;
  uploadError: boolean;
  cancelUpload: (() => void) | undefined;
}

class UploadMedia extends Component<
  IUploadMediaProps & ActionSheetProps & FileUploadProps,
  IUploadMediaState
> {
  constructor(props: IUploadMediaProps & ActionSheetProps & FileUploadProps) {
    super(props);

    this.state = {
      media:
        this.props.media?.map((m) => ({
          uri: m?.uri || '',
          thumbnailUri: m?.thumbnailUri || '',
          cropData: undefined,
          pendingUpload: false,
        })) ?? [],
      currentIndex: 0,
      currentMedia: undefined,
      thumbnailMap: {},
      uploading: false,
      uploadProgress: 0,
      uploadError: false,
      cancelUpload: undefined,
      error: undefined,
      revokeObjectURLs: [],
      generatingThumbnail: false,
      editorVisible: false,
    };
  }

  _getActionSheetOptions(mediaIndex: number) {
    const mediaType =
      this.props.mediaType === 'Images'
        ? 'Image'
        : this.props.mediaType === 'Videos'
        ? 'Video'
        : 'Media';

    return {
      cached: {
        title: 'Media Picker',
        options: [
          'Adjust Crop',
          `Change ${mediaType}`,
          `Remove ${mediaType}`,
          'Cancel',
        ],
        cancelButtonIndex: 3,
        destructiveButtonIndex: 2,
        onPress: (index: number | undefined) => {
          switch (index) {
            case 0: {
              this._editItems();
              return;
            }
            case 1: {
              this._pickMedia();
              return;
            }
            case 2: {
              this.removeMedia(mediaIndex);
              return;
            }
            default: {
              return;
            }
          }
        },
      },
      uncached: {
        title: 'Media Picker',
        options: [`Change ${mediaType}`, `Remove ${mediaType}`, 'Cancel'],
        cancelButtonIndex: 2,
        destructiveButtonIndex: 1,
        onPress: (index: number | undefined) => {
          switch (index) {
            case 0: {
              this._pickMedia();
              return;
            }
            case 1: {
              this.removeMedia(mediaIndex);
              return;
            }
            default: {
              return;
            }
          }
        },
      },
    };
  }

  _editItems = () => {
    this.setState({
      editorVisible: true,
    });
  };

  cancelUpload() {
    this.state.cancelUpload?.();

    this.setState((prevState) => ({
      currentMedia: undefined,
      media: prevState.media.filter((m) => !m.pendingUpload),
      uploading: false,
      uploadProgress: 0,
      cancelUpload: undefined,
    }));
  }

  _pickMedia = async (files?: File[]) => {
    try {
      /**
       * If we are on Web, then we want to use the document picker instead because
       * ExpoMediaLibrary, which ImagePicker depends on, does not support the browser.
       */
      if (Platform.OS === 'web') {
        let targetTypes;

        if (this.props.mediaType === 'All' || !this.props.mediaType) {
          targetTypes = ['image/*', 'video/*'];
        } else if (this.props.mediaType === 'Videos') {
          targetTypes = ['video/*'];
        } else {
          targetTypes = ['image/*'];
        }

        let _files: DocumentPicker.DocumentPickerAsset[] = [];

        if (!files?.length) {
          const pickerResult = await DocumentPicker.getDocumentAsync({
            type: targetTypes,
            multiple: this.props.multiple,
          });

          if (pickerResult.canceled) return;

          if (!pickerResult.assets?.length) {
            this.onError('An unknown error occurred');
            return;
          }

          if (
            typeof this.props.selectionLimit === 'number' &&
            pickerResult.assets.length > this.props.selectionLimit
          ) {
            // eslint-disable-next-line no-alert
            alert(`You can only select ${this.props.selectionLimit} items`);
            _files = pickerResult.assets.slice(0, this.props.selectionLimit);
          }

          _files = pickerResult.assets;
        } else {
          _files = files.map((f) => ({
            file: f,
            uri: URL.createObjectURL(f),
            name: f.name,
            lastModified: f.lastModified,
            mimeType: f.type,
            size: f.size,
          }));
        }

        let pickerErrors = new Set<string>();

        let validatedMedia: {
          item: DocumentPicker.DocumentPickerAsset;
          dimensions: {
            width: number | undefined;
            height: number | undefined;
          };
        }[] = [];

        const getImageDimensions = async (
          doc: DocumentPicker.DocumentPickerAsset,
        ) => {
          const { width, height }: any = await new Promise((resolve) => {
            const img = new Image();
            img.addEventListener('load', function () {
              resolve({
                width: this.naturalWidth,
                height: this.naturalHeight,
              });
            });
            img.src = (doc as any).uri;
          });

          return {
            width: width as number,
            height: height as number,
          };
        };

        for (let i = 0; i < _files.length; i++) {
          const item = _files[i];

          if (!item) continue;

          const isVideo = determineIfVideo(item.name);

          // Make sure the file matches the media type specified
          if (
            (this.props.mediaType === 'Images' && isVideo) ||
            (this.props.mediaType === 'Videos' && !isVideo)
          ) {
            let typeText = this.props.mediaType.toLowerCase();

            URL.revokeObjectURL(item.uri);

            pickerErrors.add(`Please only select ${typeText}`);

            continue;
          }

          // Get the size of the file in MB
          if (item.size) {
            const documentSize = item.size / 1024 / 1024;

            if (documentSize > 200) {
              this.onError('Media too large, max 200MB');
              URL.revokeObjectURL(item.uri);
              continue;
            }
          }

          validatedMedia.push({
            item,
            dimensions: isVideo
              ? { width: undefined, height: undefined }
              : await getImageDimensions(item),
          });
        }

        if (pickerErrors.size > 0)
          this.onError(
            'Some items could not be uploaded:\n' + Array.from(pickerErrors)[0],
          );

        if (!validatedMedia.length) return;

        // Add selected files' object URL to the revokeObjectURLs array so that they can be
        // revoked on component unmount
        this.setState((prevState) => ({
          revokeObjectURLs: [
            ...prevState.revokeObjectURLs,
            ...validatedMedia.map((m) => m.item.uri),
          ],
        }));

        const newMedia: MediaType[] = validatedMedia.map((media) => ({
          uri: media.item.uri,
          pendingUpload: true,
          asset: {
            uri: media.item.uri,
            duration: 0,
            width: media.dimensions.width as any,
            height: media.dimensions.height as any,
            mediaType: determineIfVideo(media.item.name) ? 'video' : 'photo',
          },
        }));

        /** If at least one item is a photo, open the editor for cropping */
        if (newMedia.some((m) => m.asset?.mediaType === 'photo')) {
          this.setState((prevState) => ({
            media: [...prevState.media, ...newMedia],
            editorVisible: true,
          }));
          /** Otherwise skip the editor (No thumbnail picker support on web yet) */
        } else {
          this.onEditorClose(newMedia as MediaEditorMedia[]);
        }
      } else {
        const result = await ImagePicker.launchImageLibraryAsync({
          selectionLimit: this.props.multiple ? this.props.selectionLimit : 1,
          mediaTypes: this.props.mediaType
            ? MEDIA_TYPES[this.props.mediaType]
            : MEDIA_TYPES.All,
          allowsMultipleSelection: this.props.multiple,
        });

        if (result.canceled || !result.assets.length) return;

        const media = result.assets.map((asset) => {
          const type = asset.type;

          const isVideo = type ? type === 'video' : determineIfVideo(asset.uri); // `type` is optional, so we fallback on checking the uri

          return {
            uri: asset.uri,
            pendingUpload: true,
            asset: {
              id: asset.assetId,
              uri: asset.uri,
              duration: asset.duration,
              width: asset.width,
              height: asset.height,
              filename: asset.fileName || undefined,
              mediaType: isVideo ? 'video' : 'photo',
            },
          } as MediaType;
        });

        /** If at least one item is a photo, open the editor for cropping */
        if (media.some((m) => m.asset?.mediaType === 'photo')) {
          this.setState((prevState) => ({
            media: [...prevState.media, ...media],
            editorVisible: true,
          }));
          /** Otherwise skip the editor (No thumbnail picker support on web yet) */
        } else {
          this.onEditorClose(media as MediaEditorMedia[]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  onError = (errorMessage: string) => {
    this.setState({
      error: errorMessage,
    });
    this.props.onError?.(errorMessage);
  };

  onEdit = () => {
    // strange stuff happens when we try to edit while uploading
    if (this.state.uploading) return;

    if (
      !this.props.multiple &&
      this.props.removable &&
      this.state.currentMedia?.uri
    ) {
      // TODO: Bring this back (where original media is stored to allow re-adjusting the crop)
      // const actionSheetOptions = this.state.media.original
      //   ? this._getActionSheetOptions(this.state.currentIndex).cached
      //   : this._getActionSheetOptions(this.state.currentIndex).uncached;
      const actionSheetOptions = this._getActionSheetOptions(
        this.state.currentIndex,
      ).uncached;

      this.props.showActionSheetWithOptions(
        actionSheetOptions,
        actionSheetOptions.onPress,
      );
    } else {
      this._pickMedia();
    }
  };

  ensureValidCurrentIndex() {
    let currentIndex = Math.min(
      this.state.currentIndex || 0,
      Math.max(0, this.state.media.length - 1),
    );

    if (this.state.currentIndex !== currentIndex) {
      this.setState({
        currentIndex,
      });
    }

    if (this.state.currentMedia !== this.state.media[currentIndex]) {
      this.setState({
        currentMedia: this.state.media[currentIndex],
      });
    }
  }

  componentDidMount() {
    this.ensureValidCurrentIndex();
  }

  componentDidUpdate(prevProps: IUploadMediaProps) {
    this.ensureValidCurrentIndex();

    /** If `media` in props change, sync */
    if (this.props.media !== prevProps.media) {
      const derivedState =
        this.props.media?.map((m) => ({
          ...m,
          pendingUpload: false,
          /** Ensure thumbnail is not lost even if parent doesn't store it */
          thumbnailUri: m.thumbnailUri || this.state.thumbnailMap[m.uri],
        })) ?? [];

      if (
        !this.state.uploading &&
        !this.state.uploadError &&
        !this.state.generatingThumbnail &&
        Array.isArray(this.props.media) &&
        !_.isEqual(derivedState, this.state.media)
      ) {
        this.setState({
          media: derivedState,
        });
      }
    }
  }

  componentWillUnmount() {
    // Cancel uploads if unmounting
    if (this.state.uploading) {
      this.props.onUploadEnd?.();
      this.state.cancelUpload?.();
    }

    this.state.revokeObjectURLs.forEach((url) => {
      URL.revokeObjectURL(url);
    });

    this.clearState();
  }

  onEditorClose = (media: MediaEditorMedia[]) => {
    this.setState({ editorVisible: false });

    if (!media.length) {
      /** If we cancelled editing, remove all items
       * that were added to state during the editing process */
      this.setState({
        media: this.state.media.filter((m) => !m.asset),
      });
      return;
    }

    if (this.state.uploading) {
      this.cancelUpload();
    }

    const newMedia = media.map((m) => ({
      ...m,
      pendingUpload: true,
      /** Clear `asset` to signal this item can not be edited again */
      asset: undefined,
    }));

    this.setState(
      (prevState) => ({
        media: this.props.multiple
          ? [...prevState.media.filter((m) => !m.asset), ...newMedia]
          : newMedia,
      }),
      () => {
        // Since media has indeed changed, update parent state and begin upload
        // this.props.onChangeMedia?.(newMedia);
        this.uploadMedia();
      },
    );
  };

  // Uploads media in state
  uploadMedia = () => {
    if (!this.state.media.length) {
      this.setState({
        uploadError: true,
        uploading: false,
      });
      console.log('UploadMedia.uploadMedia() failed: state.media was empty!');
      return;
    }

    this.setState({
      uploading: true,
      uploadProgress: 0,
      uploadError: false,
    });

    const itemsToUpload = this.state.media.filter((m) => m.pendingUpload);

    const uris = itemsToUpload.map((m) => m.uri);
    const thumbnailUris = itemsToUpload.map((m) => m.thumbnailUri);

    this.props.onUploadStart?.(itemsToUpload);

    const cancelUpload = this.props.uploadMultiple({
      persistAfterUserDeletion: this.props.persistAfterUserDeletion,
      uris: uris.concat(...(thumbnailUris.filter((t) => t) as string[])),
      onUploadProgress: (loaded, total) => {
        const progress = (loaded / total) * 100;

        this.setState({
          uploading: true,
          uploadProgress: progress,
        });

        this.props.onUploadProgress?.(progress, itemsToUpload);
      },
      onUploadComplete: async (result) => {
        this.setState({
          uploadProgress: 100,
          uploading: false,
          cancelUpload: undefined,
        });

        try {
          let thumbResultOffset = 0;

          const finalizedThumbnails = await Promise.all(
            thumbnailUris.map(async (t, i) => {
              if (t) {
                return result[i + uris.length - thumbResultOffset];
              } else {
                thumbResultOffset++;

                const isVideo =
                  itemsToUpload[i].asset?.mediaType === 'video' ||
                  determineIfVideo(result[i]);

                /** If this item is a video, generate a thumbnail for it */
                if (isVideo) {
                  this.setState({ generatingThumbnail: true });

                  try {
                    return await ffmpeg.generateVideoThumbnail({
                      source: result[i],
                      useOriginalFilename: true,
                    });
                  } catch (err) {
                    console.log(
                      '[UploadMedia] Failed to generate thumbnail',
                      err,
                    );
                  }
                } else {
                  /** Otherwise, the image itself can also be thumbnail */
                  return result[i];
                }
              }
            }),
          );

          this.setState(
            (prevState) => ({
              media: prevState.media.map((m) => {
                const index = uris.indexOf(m.uri);

                /** If this item was just uploaded, make sure we have a valid
                 * thumbnail for it. If not, generate one.
                 */
                if (index > -1) {
                  let thumb = finalizedThumbnails[index];

                  return {
                    ...m,
                    pendingUpload: false,
                    uri: result[index],
                    thumbnailUri: thumb,
                  };
                }

                return m;
              }),
              thumbnailMap: (result as string[]).reduce((acc, m, i) => {
                acc[m] = finalizedThumbnails[i];
                return acc;
              }, prevState.thumbnailMap),
            }),
            () => {
              this.props.onChangeMedia?.(this.state.media);
            },
          );
        } catch (error) {
          console.log('Error in UploadMedia uploadMedia() callback', error);
        } finally {
          this.setState({ generatingThumbnail: false });
        }

        this.props.onUploadEnd?.();
        this.props.onUploadSuccess?.();
      },
      onUploadFailed: (error) => {
        if (error.errors.every((err: any) => isCancelError(err)))
          // If this is a cancel error, then just return; UploadMedia only experiences a cancel error
          // if we are beginning to upload different media instead, so we don't want to set uploading to false
          // or signal that uploading has ended
          return;

        this.setState({
          uploading: false,
          uploadError: true,
        });

        this.props.onUploadEnd?.();
        this.props.onUploadError?.(this.retry);
      },
    });

    this.setState({
      cancelUpload,
    });
  };

  retry = () => {
    /** If there is no error, there is nothing to retry */
    if (!this.state.uploadError) return;

    this.uploadMedia();
  };

  removeCurrentMedia() {
    this.removeMedia(this.state.currentIndex);
  }

  removeMedia(targetIndex: number) {
    this.setState(
      (prevState) => {
        if (prevState.media[targetIndex]?.uri?.startsWith('data:')) {
          URL.revokeObjectURL(prevState.media[targetIndex].uri);
        }

        return {
          media: prevState.media.filter((m, i) => i !== targetIndex),
          currentIndex: Math.min(
            prevState.media.length - 2,
            prevState.currentIndex,
          ),
        };
      },
      () => this.props.onChangeMedia?.(this.state.media),
    );
  }

  renderPlaceholderImage() {
    if (!this.props.placeholderImage) return null;

    return typeof this.props.placeholderImage === 'function'
      ? this.props.placeholderImage()
      : this.props.placeholderImage;
  }

  clearState = () => {
    this.setState(
      {
        media: [],
        revokeObjectURLs: [],
        thumbnailMap: {},
      },
      () => this.props.onChangeMedia?.(this.state.media),
    );
  };

  render() {
    const { media } = this.state;

    const textStyle = [
      styles.touchableText,
      {
        fontSize: this.props.fontSize || 10,
      },
    ];

    const flattenedStyle = StyleSheet.flatten(this.props.style ?? {});
    const styleWidth =
      typeof flattenedStyle.width !== 'number'
        ? undefined
        : flattenedStyle.width;
    const styleHeight =
      typeof flattenedStyle.height !== 'number'
        ? undefined
        : flattenedStyle.height;
    const sizeProp =
      typeof this.props.size === 'string' ? undefined : this.props.size;
    const componentWidth = styleWidth || sizeProp || 100;
    const componentHeight = styleHeight || sizeProp || 100;

    let accept: any = {};

    switch (this.props.mediaType) {
      case 'Images':
        accept['image/*'] = [];
        break;
      case 'Videos':
        accept['video/*'] = [];
        break;
      default:
        accept['image/*'] = [];
        accept['video/*'] = [];
        break;
    }

    const mediaToBeEdited = this.state.media
      .filter((m) => !!m.asset)
      .map((m) => ({
        ...m,
        asset: m.asset!,
      }));

    return (
      <DragAndDrop
        accept={accept}
        multiple={this.props.multiple}
        onDrop={(acceptedFiles: File[]) => {
          if (acceptedFiles.length) this._pickMedia(acceptedFiles);
        }}
      >
        <MediaEditor
          circleCrop={this.props.circular}
          visible={this.state.editorVisible}
          targetMediaDimensions={this.props.targetMediaDimensions}
          onFinishEditing={this.onEditorClose}
          /** Only include items that have an `asset`. Those are the only
           * items we are interested in editing. */
          media={mediaToBeEdited}
        />
        {/* Render custom component */}
        {this.props.renderComponent ? (
          <Pressable
            onPress={this.onEdit}
            style={[
              flattenedStyle,
              {
                opacity:
                  this.state.uploading || this.state.generatingThumbnail
                    ? 0.5
                    : 1,
                pointerEvents: this.props.disabled ? 'none' : 'auto',
              },
            ]}
          >
            {({ pressed }) =>
              this.props.renderComponent?.(pressed, () => {
                this.onEdit();
              })
            }
          </Pressable>
        ) : (
          <>
            {/* Render default UploadMedia component */}
            <Pressable
              onPress={this.onEdit}
              style={[
                styles.container,
                {
                  pointerEvents: this.props.disabled ? 'none' : 'auto',
                  borderRadius: this.props.circular ? 2048 : 8,
                  width:
                    this.props.size === 'auto'
                      ? undefined
                      : this.props.size || 100,
                  height:
                    this.props.size === 'auto'
                      ? '100%'
                      : this.props.size || 100,
                },
                flattenedStyle,
              ]}
            >
              {/* Error Overlay */}
              {this.state.error ? (
                <View style={styles.errorOverlay}>
                  <Feather name="alert-triangle" size={28} color="crimson" />
                  {(componentWidth + componentHeight) / 2 < 128 ? null : (
                    <Text style={styles.errorText}>{this.state.error}</Text>
                  )}
                  {componentHeight > 200 ? (
                    <Button
                      label="Dismiss"
                      containerStyle={{
                        marginTop: 8,
                      }}
                      onPress={() => {
                        this.setState({ error: undefined });
                      }}
                    />
                  ) : null}
                </View>
              ) : null}

              {!this.props.multiple ||
              (media.length === 0 && !this.state.generatingThumbnail) ? (
                <View
                  style={[
                    styles.imageButton,
                    {
                      backgroundColor: this.state.generatingThumbnail
                        ? 'rgba(0,0,0,0.5)'
                        : 'transparent',
                    },
                  ]}
                >
                  {/* TEXT OVERLAYS */}
                  {this.state.currentMedia?.uri &&
                  !this.state.generatingThumbnail ? (
                    <>
                      <View style={styles.editOverlay}>
                        <Text
                          style={[
                            ...textStyle,
                            {
                              color: 'white',
                              fontSize: 11,
                              width: '100%',
                              textAlign: 'center',
                            },
                          ]}
                        >
                          Change
                        </Text>
                      </View>
                    </>
                  ) : (
                    (!this.state.generatingThumbnail &&
                      this.renderPlaceholderImage?.()) || (
                      <Text style={textStyle}>
                        {this.state.generatingThumbnail && (
                          <ActivityIndicator
                            style={{
                              marginRight: 8,
                            }}
                            color="white"
                            size="small"
                          />
                        )}
                        {this.state.generatingThumbnail
                          ? 'Creating thumbnail...'
                          : this.props.title ||
                            `Click to choose ${
                              this.props.mediaType === 'Images'
                                ? 'image'
                                : this.props.mediaType === 'Videos'
                                ? 'video'
                                : 'media'
                            }...`}
                      </Text>
                    )
                  )}
                </View>
              ) : this.props.multiple && this.state.currentMedia ? (
                <Button
                  disabled={
                    this.state.currentMedia.pendingUpload &&
                    this.state.uploading
                  }
                  label={
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesome
                        size={17}
                        name="trash-o"
                        style={{
                          marginRight: 4,
                        }}
                        color={KEY_GRAY}
                      />
                      <Text
                        style={{
                          color: KEY_GRAY,
                          fontFamily: 'Lato-Bold',
                          fontSize: 17,
                        }}
                      >
                        Remove
                      </Text>
                    </View>
                  }
                  onPress={() => {
                    this.removeCurrentMedia();
                  }}
                  containerStyle={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                  }}
                />
              ) : null}

              <MediaPreview
                currentMedia={this.state.currentMedia}
                hideProgressIndicator={!!this.props.hideProgressIndicator}
                isAvatar={!!this.props.isAvatar}
                isUploading={this.state.uploading}
                resizeMode={this.props.previewResizeMode}
                shouldRenderAvatarPlaceholder={!this.props.placeholderImage}
                uploadProgress={this.state.uploadProgress}
                key={this.state.currentMedia?.uri || 'no-media'}
              />
            </Pressable>
            {this.state.uploading && !this.props.isAvatar ? (
              <View
                style={[
                  styles.uploadIndicator,
                  {
                    display: this.props.hideProgressIndicator ? 'none' : 'flex',
                  },
                ]}
              >
                <ActivityIndicator size="small" />
                <Text
                  style={[
                    styles.uploadIndicatorText,
                    {
                      fontSize: this.props.fontSize || 14,
                    },
                  ]}
                >
                  Uploading... ({Math.round(this.state.uploadProgress)}%)
                </Text>
              </View>
            ) : (
              this.state.uploadError &&
              !this.props.isAvatar &&
              this.state.media.some((m) => m.pendingUpload) && (
                <View style={styles.uploadIndicator}>
                  <Text style={styles.uploadErrorText}>Upload failed</Text>
                  <Pressable
                    style={styles.retryUploadButton}
                    onPress={() => {
                      this.retry();
                    }}
                  >
                    <Text style={styles.retryUploadButtonText}>Retry</Text>
                  </Pressable>
                </View>
              )
            )}

            {/* LIST MEDIA */}
            {this.props.multiple ? (
              <FlatList
                horizontal
                style={{
                  marginTop: 8,
                }}
                data={media}
                renderItem={({ item, index }) => {
                  return (
                    <Pressable
                      onPress={() => {
                        this.setState({
                          currentIndex: index,
                        });
                      }}
                      style={[
                        styles.mediaTileContainer,
                        {
                          borderColor:
                            this.state.currentIndex === index
                              ? KEY_GREEN
                              : 'transparent',
                        },
                      ]}
                    >
                      <MediaPreview
                        currentMedia={item}
                        isAvatar={false}
                        isUploading={item.pendingUpload}
                        resizeMode={this.props.previewResizeMode}
                        shouldRenderAvatarPlaceholder={false}
                        hideProgressIndicator
                        uploadProgress={this.state.uploadProgress}
                        key={this.state.currentMedia?.uri}
                      />

                      {item.pendingUpload ? (
                        <View
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                          }}
                        >
                          {this.state.uploading ? (
                            <ActivityIndicator size={32} color={'white'} />
                          ) : this.state.uploadError ? (
                            <Feather
                              style={{
                                zIndex: 10,
                                elevation: 10,
                              }}
                              name="alert-triangle"
                              size={32}
                              color="crimson"
                            />
                          ) : null}
                        </View>
                      ) : null}
                    </Pressable>
                  );
                }}
                ListFooterComponent={
                  <Pressable onPress={() => this._pickMedia()}>
                    <View
                      style={[
                        styles.mediaTileContainer,
                        {
                          backgroundColor: 'transparent',
                        },
                      ]}
                    >
                      <View
                        style={{
                          flex: 1,
                          borderWidth: 1,
                          borderColor: 'rgba(0,0,0,0.1)',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Ionicons name="add" size={28} color={'black'} />
                      </View>
                    </View>
                  </Pressable>
                }
              />
            ) : null}
          </>
        )}
      </DragAndDrop>
    );
  }
}

export default connectActionSheet<IUploadMediaProps>(
  withFileUpload<ActionSheetProps & IUploadMediaProps>(UploadMedia),
);

type MediaPreviewProps = {
  currentMedia: MediaType | undefined;
  resizeMode: ResizeMode | undefined;
  isUploading: boolean;
  uploadProgress: number;
  isAvatar: boolean;
  hideProgressIndicator: boolean;
  shouldRenderAvatarPlaceholder: boolean;
};

function MediaPreview({
  currentMedia,
  resizeMode,
  isUploading,
  uploadProgress,
  isAvatar,
  shouldRenderAvatarPlaceholder,
  hideProgressIndicator,
}: MediaPreviewProps) {
  const [isLoading, setIsLoading] = useState(!!currentMedia?.uri);

  return (
    <View style={styles.imageContain}>
      {currentMedia?.uri ? (
        !currentMedia?.thumbnailUri && Platform.OS === 'android' ? (
          <ImageComponent
            testID="upload-media-image"
            source={{
              uri: getCDNImageUri({ uri: currentMedia.uri, isThumbnail: true }),
            }}
            onLoadEnd={() => {
              if (isLoading) setIsLoading(false);
            }}
            resizeMode={resizeMode ?? 'contain'}
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: 'gray',
            }}
          />
        ) : (
          <>
            <ImageComponent
              testID="upload-media-image"
              onLoadEnd={() => {
                if (isLoading) setIsLoading(false);
              }}
              source={{
                uri: currentMedia?.uri.startsWith('data:video')
                  ? undefined
                  : getCDNImageUri({
                      uri: currentMedia.thumbnailUri || currentMedia?.uri,
                      isThumbnail: true,
                    }) || undefined,
              }}
              resizeMode={resizeMode ?? 'contain'}
              style={{
                height: '100%',
                width: '100%',
                backgroundColor: 'gray',
              }}
            />
            <View
              style={[
                StyleSheet.absoluteFill,
                {
                  pointerEvents: 'none',
                  backgroundColor: 'rgba(10, 10, 10, 0.4)',
                  display:
                    (isLoading || isUploading) && !hideProgressIndicator
                      ? 'flex'
                      : 'none',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1,
                  elevation: 1,
                },
              ]}
            >
              <ActivityIndicator color="white" size="small" />
              {isUploading ? (
                <Text
                  style={{
                    color: 'white',
                    marginLeft: 6,
                    fontFamily: 'Lato-Bold',
                  }}
                >
                  {Math.round(uploadProgress)}%
                </Text>
              ) : null}
            </View>
            <View
              style={[
                styles.videoIconContainer,
                {
                  display: determineIfVideo(currentMedia?.uri)
                    ? 'flex'
                    : 'none',
                },
              ]}
            >
              {/* Video Icon */}
              <ImageComponent
                source={require('/assets/icons/video.png')}
                style={{
                  width: 16,
                  height: 16,
                }}
                resizeMode={'contain'}
              />
            </View>
          </>
        )
      ) : isAvatar && shouldRenderAvatarPlaceholder ? (
        <ImageComponent
          source={PLACEHOLDER}
          style={{
            backgroundColor: 'black',
            opacity: 0.3,
            height: '100%',
            width: '100%',
          }}
        />
      ) : null}
      {/* Couldn't coerce android to display a full video preview, only an image of the video. iOS, on the other hand, cannot display an image of the video. */}
    </View>
  );
}
