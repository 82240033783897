import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';

export default function useDebouncedState<T = any>(value?: T, delay = 300) {
  const [val, _setVal] = useState<T>(value as any);
  const [valDebounced, _setValDebounced] = useState<T>(value as any);
  const setValueDebouncedRef = useRef(debounce(_setValDebounced, delay));
  const setValue = useCallback((v: T) => {
    _setVal(v);
    setValueDebouncedRef.current(v);
  }, []);
  const setValueImmediate = useCallback((v: T) => {
    setValueDebouncedRef.current(v);
    _setVal(v);
    _setValDebounced(v);
  }, []);

  return [val, valDebounced, setValue, setValueImmediate] as [
    value: T,
    debouncedValue: T,
    setValue: (v: T) => void,
    setValueImmediate: (v: T) => void,
  ];
}
