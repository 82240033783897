import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CombinedError } from 'urql';
import { useAuthContext } from './AuthProvider';
import Alert from '/Alert';
import {
  Team,
  TeamMember,
  TeamMemberRole,
  TeamMembershipStatus,
  useGetMyTeamMembershipsQuery,
  User,
  UserRole,
} from '/generated/graphql';
import AsyncStorage from '@react-native-async-storage/async-storage';

/** Cut-down, use-case specific types */
export interface ITeamOwner
  extends Pick<
    User,
    | 'id'
    | 'name'
    | 'is_verified'
    | 'profile_image'
    | 'cover_image'
    | 'unread_notifications'
    | 'accepting_donations'
    | 'donation_currency'
  > {}

interface ITeamMember
  extends Pick<
    TeamMember,
    | 'userId'
    | 'teamId'
    | 'membership_status'
    | 'team_role'
    | 'title'
    | 'confirmed_at'
    | 'requested_at'
  > {
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
  initiator: Pick<User, 'id' | 'name' | 'profile_image'>;
}

export interface ITeamProviderTeam
  extends Omit<Team, 'membership' | 'members' | 'user' | 'userId'> {
  membership?: Omit<ITeamMember, 'user' | 'initiator'> | undefined | null;
  user: ITeamOwner;
}

export interface ITeamContext {
  teams: ITeamProviderTeam[];
  loading: boolean;
  error: CombinedError | undefined;
  /** Team that is currently selected to act on behalf of */
  activeTeam: ITeamProviderTeam | undefined;
  /** If there is a currently active team, this will be our role on that team */
  activeTeamRole: TeamMemberRole | undefined;
  setActiveTeam: (teamId: string) => void;
  refresh: () => void;
}

export const TeamContext = React.createContext({} as ITeamContext);

export const useTeamContext = () => useContext(TeamContext);

export default function TeamContextProvider(
  props: React.PropsWithChildren<{}>,
) {
  const { userData } = useAuthContext();

  const [_activeTeam, _setActiveTeam] = useState<ITeamProviderTeam>();
  const [hasInitialized, setHasInitialized] = useState(false);

  const [
    { data: teams, fetching: loading, error, stale },
    getMyTeamMemberships,
  ] = useGetMyTeamMembershipsQuery({
    variables: { membershipStatus: TeamMembershipStatus.Confirmed },
    requestPolicy: 'cache-and-network',
    pause: userData?.role !== UserRole.Supporter,
  });

  useEffect(() => {
    if (!userData || loading) return;

    // Automatically set selected team to first index
    if (teams?.getMyTeamMemberships.total && !_activeTeam) {
      AsyncStorage.getItem('activeTeamId')
        .then((teamId) => {
          const { team, ...membership } =
            (teamId &&
              teams?.getMyTeamMemberships.items.find(
                (t) => t.teamId === teamId,
              )) ||
            (teams.getMyTeamMemberships.items[0] as TeamMember);

          _setActiveTeam({ ...team, membership } as ITeamProviderTeam);
        })
        .finally(() => {
          setHasInitialized(true);
        });
    } else {
      setHasInitialized(true);
    }
  }, [teams, _activeTeam, loading, stale, userData]);

  useEffect(() => {
    if (!userData?.id) return;

    // If currently signed in user changed, get teams for that user
    getMyTeamMemberships({ requestPolicy: 'network-only' });
  }, [userData?.id, getMyTeamMemberships]);

  const refresh = useCallback(
    function () {
      userData?.role === UserRole.Supporter &&
        getMyTeamMemberships({ requestPolicy: 'network-only' });
    },
    [getMyTeamMemberships, userData?.role],
  );

  const setActiveTeam = useCallback(
    function (teamId: string | undefined) {
      if (teamId === undefined) {
        _setActiveTeam(undefined);
        return;
      }

      const targetMembership = teams?.getMyTeamMemberships.items.find(
        (membership) => membership.team.id === teamId,
      );

      if (!targetMembership) {
        console.error(
          `TeamContextProvider: Failed to set active team because provided teamId "${teamId}" is not one of the teams user is a part of`,
        );

        Alert.notify({
          message: 'Failed to select team',
          color: 'crimson',
          textColor: 'white',
        });
        return;
      }

      _setActiveTeam({
        ...targetMembership.team,
        membership: targetMembership,
      } as ITeamProviderTeam);

      AsyncStorage.setItem('activeTeamId', teamId);
    },
    [teams],
  );

  return (
    <TeamContext.Provider
      value={{
        teams:
          (teams?.getMyTeamMemberships?.items.map((membership) => ({
            ...membership.team,
            membership,
          })) as ITeamProviderTeam[]) || [],
        loading: loading || stale || !hasInitialized,
        error,
        activeTeam:
          userData?.role === UserRole.Conservationist
            ? teams?.getMyTeamMemberships.items?.[0]?.team
            : _activeTeam,
        activeTeamRole:
          userData?.role === UserRole.Conservationist
            ? undefined
            : _activeTeam?.membership?.team_role ?? undefined,
        setActiveTeam,
        refresh,
      }}
    >
      {props.children}
    </TeamContext.Provider>
  );
}
