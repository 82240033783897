import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { FlatList, ListRenderItem, Pressable, Text, View } from 'react-native';
import useCommonStyles from '../ContentCard/ContentCard.style';
import Button from '/components/Button';
import GenericListFooter from '/components/GenericListFooter';
import { KEY_GRAY } from '/constants';

interface Props<ItemT = any> {
  loading: boolean;
  stale: boolean;
  hasError: boolean;
  hasMore: boolean;
  data: ItemT[];
  total: number;
  onRetry: () => void;
  onClose: () => void;
  onPaginate: () => void;
  renderItem: ListRenderItem<ItemT>;
}

export interface ContentCardResultsProps {
  query: string;
  geohash: string;
  onClose: () => void;
}

export default function ContentCardResultsBase(props: Props) {
  const { styles: commonStyles } = useCommonStyles();

  return props.hasError ? (
    <View style={commonStyles('errorContainer')}>
      <Pressable
        style={{
          alignSelf: 'flex-end',
          padding: 10,
        }}
        onPress={props.onClose}
      >
        <AntDesign name="close" size={20} color={KEY_GRAY} />
      </Pressable>
      <Text style={commonStyles('errorText')}>
        There was an error fetching results
      </Text>
      <Button
        containerStyle={{
          marginTop: 10,
        }}
        label="Retry"
        onPress={() => {
          props.onRetry();
        }}
      />
    </View>
  ) : (
    <FlatList
      style={{ alignSelf: 'stretch', minWidth: '100%' }}
      data={props.data}
      stickyHeaderIndices={[0]}
      ListHeaderComponent={
        <View style={commonStyles('contentCardHeader')}>
          <Text style={commonStyles('contentCardTitle')}>
            {!props.total && props.loading
              ? 'Loading...'
              : `${props.total} result${(props.total ?? 0) > 1 ? 's' : ''}`}
          </Text>

          <Pressable
            style={{
              padding: 10,
            }}
            onPress={props.onClose}
          >
            <AntDesign name="close" size={20} color={KEY_GRAY} />
          </Pressable>
        </View>
      }
      renderItem={props.renderItem}
      onEndReached={() => {
        props.onPaginate();
      }}
      ListFooterComponent={
        <GenericListFooter
          hasMore={props.hasMore}
          loading={props.loading || props.stale}
          onFetchMore={props.onPaginate}
        />
      }
    />
  );
}
