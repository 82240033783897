import acceptCampaignConnectSubmission from './Mutation/acceptCampaignConnectSubmission';
import addBookmark from './Mutation/addBookmark';
import addComment from './Mutation/addComment';
import addConnection from './Mutation/addConnection';
import addLike from './Mutation/addLike';
import addPartner from './Mutation/addPartner';
import archiveDirectMessage from './Mutation/archiveDirectMessage';
import archiveKeyBulletinPost from './Mutation/archiveKeyBulletinPost';
import archiveResearchTopic from './Mutation/archiveResearchTopic';
import archiveUserReport from './Mutation/archiveUserReport';
import attendEvent from './Mutation/attendEvent';
import blockUser from './Mutation/blockUser';
import confirmSkillRequestSelection from './Mutation/confirmSkillRequestSelection';
import createBigIssue from './Mutation/createBigIssue';
import createCampaignUpdate from './Mutation/createCampaignUpdate';
import createCreativeConnectProjectBookmark from './Mutation/createCreativeConnectProjectBookmark';
import createDirectMessage from './Mutation/createDirectMessage';
import createGroupDiscussionCategory from './Mutation/createGroupDiscussionCategory';
import createGroupDiscussionPost from './Mutation/createGroupDiscussionPost';
import createJobPostBookmark from './Mutation/createJobPostBookmark';
import createMessage from './Mutation/createMessage';
import createNewsPostBookmark from './Mutation/createNewsPostBookmark';
import createOfficialBroadcastMessage from './Mutation/createOfficialBroadcastMessage';
import createResearchTopicGroup from './Mutation/createResearchTopicGroup';
import createSharedDocument from './Mutation/createSharedDocument';
import createSkillRequestApplication from './Mutation/createSkillRequestApplication';
import createSurveySubmission from './Mutation/createSurveySubmission';
import createUser from './Mutation/createUser';
import declineSkillRequestSelection from './Mutation/declineSkillRequestSelection';
import deleteBigIssue from './Mutation/deleteBigIssue';
import deleteCampaignConnectInvite from './Mutation/deleteCampaignConnectInvite';
import deleteCampaignDraft from './Mutation/deleteCampaignDraft';
import deleteCampaignUpdate from './Mutation/deleteCampaignUpdate';
import deleteCreativeConnectCollaborator from './Mutation/deleteCreativeConnectCollaborator';
import deleteCreativeConnectProject from './Mutation/deleteCreativeConnectProject';
import deleteCreativeConnectProjectBookmark from './Mutation/deleteCreativeConnectProjectBookmark';
import deleteDiscussionBoardMessage from './Mutation/deleteDiscussionBoardMessage';
import deleteEventAttendee from './Mutation/deleteEventAttendee';
import deleteEventPartner from './Mutation/deleteEventPartner';
import deleteGroupDiscussionCategory from './Mutation/deleteGroupDiscussionCategory';
import deleteGroupDiscussionPost from './Mutation/deleteGroupDiscussionPost';
import deleteGroupMember from './Mutation/deleteGroupMember';
import deleteJobPost from './Mutation/deleteJobPost';
import deleteJobPostBookmark from './Mutation/deleteJobPostBookmark';
import deleteMomentInNature from './Mutation/deleteMomentInNature';
import deleteNewsPost from './Mutation/deleteNewsPost';
import deleteNewsPostBookmark from './Mutation/deleteNewsPostBookmark';
import deleteResearchTopicGroup from './Mutation/deleteResearchTopicGroup';
import deleteSharedDocument from './Mutation/deleteSharedDocument';
import deleteSpeciesMedia from './Mutation/deleteSpeciesMedia';
import deleteSpeciesUserContent from './Mutation/deleteSpeciesUserContent';
import deleteSpeciesUserContentFact from './Mutation/deleteSpeciesUserContentFact';
import deleteSpeciesUserContentMedia from './Mutation/deleteSpeciesUserContentMedia';
import followResearchTopic from './Mutation/followResearchTopic';
import followUser from './Mutation/followUser';
import fulfillCampaignConnectInvite from './Mutation/fulfillCampaignConnectInvite';
import inviteCreativeConnectCollaborator from './Mutation/inviteCreativeConnectCollaborator';
import inviteEventAttendee from './Mutation/inviteEventAttendee';
import inviteGroupMember from './Mutation/inviteGroupMember';
import inviteTeamMember from './Mutation/inviteTeamMember';
import joinCreativeConnectCollaborator from './Mutation/joinCreativeConnectCollaborator';
import joinGroup from './Mutation/joinGroup';
import joinTeam from './Mutation/joinTeam';
import leaveTeam from './Mutation/leaveTeam';
import markAllNotificationsRead from './Mutation/markAllNotificationsRead';
import putSpeciesUserContent from './Mutation/putSpeciesUserContent';
import registerForVolunteerRequest from './Mutation/registerForVolunteerRequest';
import removeBookmark from './Mutation/removeBookmark';
import removeComment from './Mutation/removeComment';
import removeConnection from './Mutation/removeConnection';
import removeLike from './Mutation/removeLike';
import removePartnership from './Mutation/removePartnership';
import removeTeamMember from './Mutation/removeTeamMember';
import removeVolunteer from './Mutation/removeVolunteer';
import selectApplicant from './Mutation/selectApplicant';
import setDefaultGroupDiscussionCategory from './Mutation/setDefaultGroupDiscussionCategory';
import sortApplicant from './Mutation/sortApplicant';
import unblockUser from './Mutation/unblockUser';
import unfollowResearchTopic from './Mutation/unfollowResearchTopic';
import unfollowUser from './Mutation/unfollowUser';
import unselectApplicant from './Mutation/unselectApplicant';
import updateBigIssue from './Mutation/updateBigIssue';
import updateCampaignPost from './Mutation/updateCampaignPost';
import updateGroupDiscussionCategory from './Mutation/updateGroupDiscussionCategory';
import updateUserProfile from './Mutation/updateUserProfile';
import withdrawVolunteerRegistration from './Mutation/withdrawVolunteerRegistration';
import newDirectConversationMessage from './Subscription/NewDirectConversationMessage';
import newDirectMessage from './Subscription/NewDirectMessage';
import bookmarkEvent from './Mutation/bookmarkEvent';
import unbookmarkEvent from './Mutation/unbookmarkEvent';
import createCreativeConnectScheduledPremiereNotification from './Mutation/createCreativeConnectScheduledPremiereNotification';
import deleteCreativeConnectScheduledPremiereNotification from './Mutation/deleteCreativeConnectScheduledPremiereNotification';

export default {
  Mutation: {
    archiveKeyBulletinPost,
    removeVolunteer,
    registerForVolunteerRequest,
    withdrawVolunteerRegistration,
    createCampaignUpdate,
    createJobPostBookmark,
    deleteJobPost,
    deleteJobPostBookmark,
    deleteBigIssue,
    deleteCampaignDraft,
    deleteCampaignUpdate,
    deleteDiscussionBoardMessage,
    deleteMomentInNature,
    deleteSpeciesUserContent,
    createSkillRequestApplication,
    followResearchTopic,
    unfollowResearchTopic,
    deleteResearchTopicGroup,
    createResearchTopicGroup,
    deleteSpeciesUserContentMedia,
    archiveResearchTopic,
    createUser,
    deleteNewsPost,
    deleteSpeciesMedia,
    deleteSpeciesUserContentFact,
    createSurveySubmission,
    joinTeam,
    leaveTeam: leaveTeam,
    removeTeamMember,
    inviteTeamMember,
    markAllNotificationsRead,
    archiveUserReport,
    addPartner,
    removePartnership,
    confirmSkillRequestSelection,
    declineSkillRequestSelection,
    addComment,
    removeComment,
    addBookmark,
    followUser,
    unfollowUser,
    addConnection,
    removeConnection,
    removeBookmark,
    createMessage,
    createDirectMessage,
    createOfficialBroadcastMessage,
    sortApplicant,
    selectApplicant,
    unselectApplicant,
    updateUserProfile,
    blockUser,
    unblockUser,
    archiveDirectMessage,
    addLike,
    removeLike,
    setDefaultGroupDiscussionCategory,
    deleteGroupDiscussionCategory,
    putSpeciesUserContent,
    createNewsPostBookmark,
    deleteNewsPostBookmark,
    deleteGroupMember,
    joinGroup,
    inviteGroupMember,
    createGroupDiscussionCategory,
    createGroupDiscussionPost,
    deleteGroupDiscussionPost,
    createBigIssue,
    updateBigIssue,
    joinCreativeConnectCollaborator,
    deleteCreativeConnectCollaborator,
    inviteCreativeConnectCollaborator,
    createSharedDocument,
    deleteSharedDocument,
    updateGroupDiscussionCategory,
    createCreativeConnectProjectBookmark,
    deleteCreativeConnectProjectBookmark,
    deleteCampaignConnectInvite,
    acceptCampaignConnectSubmission,
    rejectCampaignConnectSubmission: acceptCampaignConnectSubmission,
    updateCampaignPost,
    deleteCreativeConnectProject,
    fulfillCampaignConnectInvite,
    attendEvent,
    deleteEventAttendee,
    inviteEventAttendee,
    deleteEventPartner,
    bookmarkEvent,
    unbookmarkEvent,
    createCreativeConnectScheduledPremiereNotification,
    deleteCreativeConnectScheduledPremiereNotification,
  },
  Subscription: {
    newDirectMessage,
    newDirectConversationMessage,
  },
};
