/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "cdn",
            "endpoint": "https://1jiet5zqeg.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "ffmpegapi",
            "endpoint": "https://6zmlpj5qdj.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "googlemapsapi",
            "endpoint": "https://orn36gc9bk.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "sesemailapi",
            "endpoint": "https://gucavrnnh2.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "stripeconnect",
            "endpoint": "https://fzlouvnkrg.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:cf7c86b1-d454-41a8-94d8-0631b1c8496b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KVU1JXEyt",
    "aws_user_pools_web_client_id": "la9r13v7oq5fglls19dhr9bri",
    "oauth": {
        "domain": "keyconservation-oath-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/login/,keyconservation://login/,https://app.keyconservation.org/login/,https://dev.keyconservation.app/login/",
        "redirectSignOut": "http://localhost:19006/,keyconservation://login/,https://app.keyconservation.org/login/,https://dev.keyconservation.app/login/,https://dev.keyconservation.app/,https://app.keyconservation.org/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "amplify-hosting-prerender-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1yk5kqo9wr1qg.cloudfront.net",
    "aws_user_files_s3_bucket": "userassetsstorage232432-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
