import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import {
  ActivityIndicator,
  StyleProp,
  TextInputProps,
  ViewStyle,
} from 'react-native';
import TextInputWithIcon from '../TextInputWithIcon';
import { KEY_GRAY } from '/constants';

type Props = TextInputProps & {
  disableDebounce?: boolean;
  debounceDelay?: number;
  loading?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  tintColor?: string;
};

export default function SearchBar({
  containerStyle,
  loading,
  style,
  tintColor,
  ...props
}: Props) {
  return (
    <TextInputWithIcon
      iconPosition="left"
      icon={
        loading ? (
          <ActivityIndicator
            size={16}
            style={{
              marginHorizontal: 8,
            }}
            color={tintColor ?? KEY_GRAY}
          />
        ) : (
          <FontAwesome
            name="search"
            size={16}
            color={tintColor ?? KEY_GRAY}
            style={{
              marginHorizontal: 8,
            }}
          />
        )
      }
      placeholder={props.placeholder || 'Search...'}
      placeholderTextColor={'gray'}
      containerStyle={[
        {
          padding: 12,
          paddingLeft: 32,
          paddingVertical: 10,
          backgroundColor: '#fefefe',
          marginHorizontal: 8,
          marginTop: 8,
        },
        containerStyle,
      ]}
      style={[
        {
          color: tintColor ?? KEY_GRAY,
          fontSize: 16,
        },
        style,
      ]}
      {...props}
    />
  );
}
