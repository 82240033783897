import { DataGrid, GridColumns } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Pressable, StyleSheet, Text, TextInput, View } from 'react-native';
import Button from '/components/Button';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import withAdminAccess from '/components/withAdminAccess';
import { ALERT_RED, KEY_GREEN, TEXT_INPUT } from '/constants';
import { useModalContext } from '/context';
import {
  SearchUsersWithTesterStatusQuery,
  TesterStatus,
  useApproveTesterMutation,
  useRevokeTesterApprovalMutation,
  useSearchUsersWithTesterStatusQuery,
} from '/generated/graphql';
import Alert from '/Alert';
import { useLinkTo } from '@react-navigation/native';

function Testers() {
  const [query, setQuery] = useState('');

  const [nextToken, setNextToken] = useState<string>();
  const [prevToken, setPrevToken] = useState<string>();

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const { spawnModal } = useModalContext();

  const [{ data, fetching, error }, searchUsers] =
    useSearchUsersWithTesterStatusQuery({
      variables: {
        nextToken,
        prevToken,
        filter: {
          query,
          tester_status: [
            TesterStatus.Interested,
            TesterStatus.Approved,
            TesterStatus.Revoked,
          ],
        },
        limit: pageSize,
      },
    });

  function onPageChange(pageNumber: number) {
    setCurrentPage(pageNumber);
    if (pageNumber < currentPage) {
      // If no prevToken, do nothing
      if (!data?.searchUsers.prevToken) return;

      // If we want the previous page, set prevToken
      setNextToken(undefined);
      setPrevToken(data?.searchUsers.prevToken);
    } else {
      if (!data?.searchUsers.nextToken) return;

      // If we want the next page, set nextToken
      setPrevToken(undefined);
      setNextToken(data?.searchUsers.nextToken);
    }
  }

  function onViewTester(
    user: SearchUsersWithTesterStatusQuery['searchUsers']['items'][0],
  ) {
    console.log('viewing', user.id);
    spawnModal({
      title: 'Tester',
      style: {
        maxWidth: 420,
      },
      component: <TesterModal user={user} />,
    });
  }

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      sortable: false,
    },
    {
      field: 'created_at',
      headerName: 'Joined Date',
      width: 200,
      sortable: false,
      valueFormatter: (params) => {
        return dayjs(Number(params.value)).format('LLL');
      },
    },
    {
      field: 'tester_status',
      headerName: 'Status',
      width: 120,
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'View',
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <Button label="VIEW" onPress={() => onViewTester(params.row)} />
      ),
    },
  ];

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          padding: 8,
        }}
      >
        <Text
          style={{
            marginBottom: 8,
            fontFamily: 'Lato-Bold',
          }}
        >
          FILTER
        </Text>
        <TextInput
          placeholderTextColor="gray"
          placeholder="Filter supporters"
          style={TEXT_INPUT}
          value={query}
          onChangeText={setQuery}
        />
      </View>
      {error ? (
        <View
          style={{
            padding: 16,
            alignSelf: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: 'Lato-Bold',
              fontSize: 16,
              color: 'crimson',
              textAlign: 'center',
            }}
          >
            Failed to fetch supporters.
          </Text>
          <Button
            label="Retry"
            onPress={() => {
              searchUsers({ requestPolicy: 'network-only' });
            }}
          />
        </View>
      ) : (
        <DataGrid
          loading={fetching}
          disableSelectionOnClick
          rows={data?.searchUsers.items ?? []}
          rowCount={data?.searchUsers.total ?? 0}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          paginationMode="server"
          sortingMode="server"
          page={currentPage}
          onPageChange={onPageChange}
        />
      )}
    </View>
  );
}

function TesterModal({
  user: initialUser,
}: {
  user: SearchUsersWithTesterStatusQuery['searchUsers']['items'][0];
}) {
  const linkTo = useLinkTo();

  const [user, setUser] = useState(initialUser);

  const [{ fetching: revoking }, revokeTester] =
    useRevokeTesterApprovalMutation();
  const [{ fetching: approving }, approveTester] = useApproveTesterMutation();

  function onRevokeTesterApproval() {
    const _revoke = () => {
      revokeTester({ userId: user.id }).then((data) => {
        if (data.data?.revokeTesterApproval) {
          setUser((prev) => ({
            ...prev,
            ...data.data?.revokeTesterApproval,
          }));
        }
      });
    };

    Alert.alert('Revoke Access', 'Are you sure you want to revoke access?', [
      { text: 'Cancel', style: 'cancel' },
      { text: 'Revoke', onPress: _revoke, style: 'destructive' },
    ]);
  }

  function onApproveTester() {
    const _approve = () => {
      approveTester({ userId: user.id }).then((data) => {
        if (data.data?.approveTester) {
          setUser((prev) => ({
            ...prev,
            ...data.data?.approveTester,
          }));
        }
      });
    };

    Alert.alert(
      'Approve Tester',
      'Are you sure you want to approve this tester?',
      [
        { text: 'Cancel', style: 'cancel' },
        { text: 'Approve', onPress: _approve, style: 'default' },
      ],
    );
  }

  return (
    <View>
      <HorizontalContainer>
        <Button
          label={
            user.tester_status === TesterStatus.Approved
              ? 'Revoke Access'
              : user.tester_status === TesterStatus.Revoked
              ? 'Re-approve'
              : 'Approve'
          }
          style={{
            backgroundColor:
              user.tester_status === TesterStatus.Approved
                ? ALERT_RED
                : KEY_GREEN,
          }}
          loading={revoking || approving}
          onPress={() => {
            if (user.tester_status === TesterStatus.Approved) {
              onRevokeTesterApproval();
            } else {
              onApproveTester();
            }
          }}
        />
      </HorizontalContainer>
      <Text style={testerModalStyles.fieldLabel}>Onboarded</Text>
      <Text style={testerModalStyles.fieldValue}>
        {user.onboarded ? 'Yes' : 'No'}
      </Text>
      <Text style={testerModalStyles.fieldLabel}>Tester Status</Text>
      <Text style={testerModalStyles.fieldValue}>{user.tester_status}</Text>
      <Text style={testerModalStyles.fieldLabel}>Email</Text>
      <Text style={testerModalStyles.fieldValue}>{user.email}</Text>
      <Text style={testerModalStyles.fieldLabel}>Joined Date</Text>
      <Text style={testerModalStyles.fieldValue}>
        {dayjs(Number(user.created_at)).format('LLL')}
      </Text>
      <Text style={testerModalStyles.fieldLabel}>Role</Text>
      <Text style={testerModalStyles.fieldValue}>{user.role}</Text>
      {user.onboarded ? (
        <>
          <Text style={testerModalStyles.fieldLabel}>Name</Text>
          <Text style={testerModalStyles.fieldValue}>{user.name}</Text>
          <Pressable
            onPress={() => {
              linkTo(`/user/${user.id}`);
            }}
          >
            <Text
              style={[
                testerModalStyles.fieldLabel,
                {
                  color: 'dodgerblue',
                },
              ]}
            >
              Go to Profile
            </Text>
          </Pressable>
        </>
      ) : null}
    </View>
  );
}

const testerModalStyles = StyleSheet.create({
  fieldLabel: {
    marginTop: 8,
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
  fieldValue: {
    fontFamily: 'Lato',
    fontSize: 14,
    marginTop: 4,
  },
});

export default withAdminAccess(Testers);
