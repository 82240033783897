import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import ContentCardResultsBase, {
  ContentCardResultsProps,
} from './ContentCardResultsBase';
import GroupCard from '/components/GroupCard/GroupCard';
import { useGetGroupsInGeohashQuery } from '/generated/graphql';

export default function ContentCardResultsGroups(
  props: ContentCardResultsProps,
) {
  const navigation = useNavigation<StackNavigationProp<any>>();

  const [nextToken, setNextToken] = useState<string>();

  const [{ data, fetching, stale, error }, refetch] =
    useGetGroupsInGeohashQuery({
      variables: {
        geohash: props.geohash,
        query: props.query,
        nextToken,
      },
    });

  return (
    <ContentCardResultsBase
      hasError={!!error}
      total={data?.getGroupsInGeohash.total ?? 0}
      loading={fetching}
      stale={stale}
      hasMore={!!data?.getGroupsInGeohash.nextToken}
      onRetry={refetch}
      onClose={props.onClose}
      data={data?.getGroupsInGeohash.items ?? []}
      renderItem={({ item }) => {
        return (
          <GroupCard
            item={item}
            onPress={() => {
              navigation.navigate('ViewGroup', {
                id: item.id,
              });
            }}
          />
        );
      }}
      onPaginate={() => {
        setNextToken(data?.getGroupsInGeohash.nextToken ?? undefined);
      }}
    />
  );
}
