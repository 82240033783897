import { UpdateResolver } from '@urql/exchange-graphcache';

const createCreativeConnectScheduledPremiereNotification: UpdateResolver = (
  result,
  args,
  cache,
) => {
  const projectId = args?.creativeConnectProjectId as any;

  cache.link(
    {
      __typename: 'CreativeConnectProject',
      id: projectId,
    },
    'scheduled_premiere_notification',
    result?.createCreativeConnectScheduledPremiereNotification as any,
  );
};

export default createCreativeConnectScheduledPremiereNotification;
