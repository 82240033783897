import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import Alert from '/Alert';
import Button from '/components/Button';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import { useDeleteEventAttendeeMutation } from '/generated/graphql';

type Props = {
  eventId: string;
  userId: string;
  attendeeId: string;
};

export default function EventAttendanceInviteCTA(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [{ fetching: deleting }, deleteEventAttendee] =
    useDeleteEventAttendeeMutation();

  function onViewInvite() {
    navigate('ViewEvent', {
      id: props.eventId,
      vUId: props.userId,
    });
  }

  function onDeleteInvite() {
    const _delete = async () => {
      try {
        const { error: err } = await deleteEventAttendee({
          id: props.attendeeId,
        });

        if (err) {
          throw err;
        }

        Alert.notify({
          message: 'Invite deleted successfully',
          color: KEY_GREEN,
        });
      } catch (error) {
        console.error('Error deleting event attendance invite', error);
        Alert.alert(
          'Oh no',
          'There was a problem deleting the invite. Please try again later.',
        );
      }
    };

    Alert.alert(
      'Are you sure?',
      'Are you sure you want to delete this event attendance invite?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: _delete,
        },
      ],
    );
  }

  return (
    <HorizontalContainer
      style={{
        pointerEvents: deleting ? 'none' : 'auto',
        opacity: deleting ? 0.5 : 1,
      }}
    >
      <Button
        label="View"
        containerStyle={{ marginRight: 6 }}
        onPress={onViewInvite}
      />
      <Button
        loading={deleting}
        onPress={onDeleteInvite}
        label={
          <FontAwesome
            size={20.5}
            name="trash-o"
            style={{
              marginRight: 4,
            }}
            color={KEY_GRAY}
          />
        }
      />
    </HorizontalContainer>
  );
}
