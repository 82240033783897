import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';

import TabNavigator from './navigators/TabNavigator';

import { isEmpty } from '/util';

import { useLinkTo } from '@react-navigation/native';
import { ActivityIndicator, Platform, TextInput } from 'react-native';
import LoginStack from './navigators/LoginStack';
import { useAuthContext } from '/context';
import TakeSurveyScreen from '/screens/TakeSurveyScreen';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking';
import { View } from 'react-native';
import Button from '/components/Button';
import SectionText from '/components/common/Section/SectionText';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import { KEY_GRAY, TEXT_INPUT } from '/constants';
import env from '/env';
import { TesterStatus } from '/generated/graphql';
import TesterOnlyModeLandingScreen from '/screens/TesterOnlyMode/TesterOnlyModeLandingScreen';
import TesterSignupScreen from '/screens/TesterOnlyMode/TesterSignupScreen';

const OnboardStackComponent = React.lazy(
  () => import('./navigators/OnboardStack'),
);
const OnboardStack = () => {
  return (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <OnboardStackComponent />
    </React.Suspense>
  );
};

const RootStack = createStackNavigator();

const AppNavigator = () => {
  const {
    user: cognitoUser,
    userData,
    userAttributes,
    isAuthenticating,
    fetching,
    fetchUserError,
    hasInitialized,
  } = useAuthContext();

  const [checkingDevEnvAuth, setCheckingDevEnvAuth] = useState(
    env.ENV_NAME === 'development',
  );
  const [storedDevPw, setStoredDevPw] = useState<string>();
  const [devPwInput, setDevPwInput] = useState<string>('');

  const hasCheckedDevEnvAuth = useRef(false);
  useEffect(() => {
    if (hasCheckedDevEnvAuth.current) return;
    hasCheckedDevEnvAuth.current = true;

    if (env.ENV_NAME === 'development') {
      const init = async () => {
        try {
          const pw = await AsyncStorage.getItem('devPw');
          if (!pw) return;
          setStoredDevPw(pw);
        } catch {
        } finally {
          setCheckingDevEnvAuth(false);
        }
      };
      init();
    }
  }, []);

  const handleSubmitDevPw = () => {
    if (devPwInput && devPwInput === env.DEV_ENV_PW) {
      setStoredDevPw(devPwInput);
      AsyncStorage.setItem('devPw', devPwInput);
    }
  };

  const linkTo = useLinkTo();

  const linkingUrl = Linking.useURL();

  /** iOS and Android ONLY */
  const handleLinkingUrl: Linking.URLListener = useCallback(
    ({ url }) => {
      try {
        const { hostname, path, queryParams, scheme } = Linking.parse(url);

        const isCustomScheme = scheme === 'keyconservation';
        const hasQueryParams = !isEmpty(queryParams);

        // If this is a deep link that uses the keyconservation:// scheme,
        // then the `hostname` will actually be part of the path
        let fullPath = `${
          isCustomScheme && hostname?.trim().length ? '/' + hostname : ''
        }${path?.trim().length ? '/' + path : ''}`;

        let queryParamsString = hasQueryParams
          ? url.slice(url.indexOf('?'))
          : '';

        linkTo(fullPath + queryParamsString);
      } catch (error) {
        console.warn('Error handling linking url', error);
      }
    },
    [linkTo],
  );

  useEffect(() => {
    /** Web linking is automatically handled by React Navigation on page load */
    if (!linkingUrl || Platform.OS === 'web') return;

    handleLinkingUrl({ url: linkingUrl });
  }, [handleLinkingUrl, linkingUrl]);

  const userId = cognitoUser?.userId;
  const onboarded = userData?.onboarded;

  // useful debug:
  // console.log(
  //   'AppNavigator',
  //   sub,
  //   onboarded,
  //   isAuthenticating,
  //   fetching,
  //   fetchUserError,
  // );

  const requirePassword =
    !__DEV__ &&
    env.ENV_NAME === 'development' &&
    (checkingDevEnvAuth || storedDevPw !== env.DEV_ENV_PW);

  if (requirePassword) {
    return checkingDevEnvAuth ? (
      <ActivityIndicator color={KEY_GRAY} size="large" />
    ) : (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <SectionText
          style={{
            marginBottom: 8,
          }}
        >
          Enter Password
        </SectionText>
        <TextInput
          style={TEXT_INPUT}
          value={devPwInput}
          onChangeText={setDevPwInput}
          onSubmitEditing={handleSubmitDevPw}
          secureTextEntry
        />
        <Button
          label="Submit"
          onPress={handleSubmitDevPw}
          containerStyle={{
            marginTop: 8,
          }}
        />
      </View>
    );
  }

  if (!hasInitialized) {
    return <LoadingSpinnerOverlay />;
  }

  const shouldHideTabs = !userId || (!!userId && fetchUserError && !onboarded);

  return (
    <>
      <RootStack.Navigator
        screenOptions={{
          title: 'Key Conservation',
          headerShown: false,
        }}
      >
        {/* Conditionally replace TabNavigator stack with Onboard stack if authenticated but not onboarded */}
        {userId &&
        env.TESTER_ONLY_MODE &&
        userData?.tester_status !== TesterStatus.Approved ? (
          <RootStack.Screen
            name="TesterSignupScreen"
            component={TesterSignupScreen}
          />
        ) : userId && !onboarded ? (
          <RootStack.Screen
            options={{
              headerShown: false,
            }}
            name="Onboard"
            component={OnboardStack}
          />
        ) : // If running natively, require user to log in before accessing content
        shouldHideTabs &&
          (Platform.OS !== 'web' || env.TESTER_ONLY_MODE) ? null : (
          <RootStack.Screen name="tabs" component={TabNavigator} />
        )}
        {env.TESTER_ONLY_MODE &&
        userData?.tester_status !== TesterStatus.Approved ? (
          <RootStack.Screen
            name="TesterOnlyModeLandingScreen"
            component={TesterOnlyModeLandingScreen}
          />
        ) : null}
        {/* Conditionally include auth stack if not authenticated  */}
        {(!!userId &&
          !!userAttributes?.email_verified &&
          !(isAuthenticating || fetching || fetchUserError)) ||
        !shouldHideTabs ? null : (
          <RootStack.Screen
            options={() => ({
              title: 'Sign In',
              headerShown: false,
            })}
            name="auth"
            component={LoginStack}
          />
        )}

        {/* Shared modal screens */}
        <RootStack.Screen
          component={TakeSurveyScreen}
          name="TakeSurvey"
          options={TakeSurveyScreenOptions}
        />
      </RootStack.Navigator>
    </>
  );
};

const TakeSurveyScreenOptions = (): StackNavigationOptions => ({
  headerTitle: 'SURVEY',
  presentation: 'modal',
});

export default AppNavigator;
