import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { FlatList, Pressable, Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import SectionContainer from '/components/common/Section/SectionContainer';
import EventCard from '/components/Events/EventCard/EventCard';
import { UserProfileFragmentFragment } from '/generated/graphql';
import SectionText from '/components/common/Section/SectionText';

interface IUser extends Pick<UserProfileFragmentFragment, 'id' | 'events'> {}

type Props = {
  data: IUser | undefined | null;
};

export default function UserEvents(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const { styles: commonStyles } = useCommonStyles();

  const onSeeMorePressed = () => {
    if (!props.data) return;
    navigate('ListEvents', {
      attendedOrHostedByUserId: props.data.id,
    });
  };

  return props.data?.events.items.length ? (
    <SectionContainer>
      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={commonStyles('sectionTitle')}>
          EVENTS ({props.data.events.total})
        </Text>
      </View>

      <SectionText
        style={{
          marginBottom: 8,
        }}
      >
        These are events this user is attending or hosting
      </SectionText>

      <FlatList
        horizontal
        contentContainerStyle={{
          paddingBottom: 8,
        }}
        data={props.data.events.items}
        renderItem={({ item }) => {
          return (
            <EventCard
              style={{
                width: 280,
                marginRight: 8,
                height: '100%',
              }}
              showControls
              key={item.id}
              event={item}
            />
          );
        }}
      />

      {props.data.events.nextToken ? (
        <View style={commonStyles('seeAllButtonContainer')}>
          <Pressable
            style={({ pressed }) => [
              commonStyles('seeAllButton'),
              {
                opacity: pressed ? 0.5 : 1,
              },
            ]}
            onPress={onSeeMorePressed}
          >
            <Text style={commonStyles('seeAllButtonText')}>See All {`>`}</Text>
          </Pressable>
        </View>
      ) : null}
    </SectionContainer>
  ) : null;
}
