import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import LottieView from './LottieView/LottieView';

interface ILoadingSpinnerOverlayProps {
  style?: StyleProp<ViewStyle>;
}

export default class LoadingSpinnerOverlay extends React.Component<ILoadingSpinnerOverlayProps> {
  render() {
    return (
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            zIndex: 99,
            backgroundColor: 'rgba(40, 40, 40, 0.7)',
            alignItems: 'center',
            justifyContent: 'center',
          },
          this.props.style,
        ]}
      >
        <LottieView
          autoPlay
          loop
          style={{
            width: 100,
            height: 100,
          }}
          source={require('/assets/icons/KeyLoadingIndicator.json')}
        />
      </View>
    );
  }
}
