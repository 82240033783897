import React from 'react';
import { StyleProp, Text, TextProps, TextStyle } from 'react-native';
import { SECTION_TEXT_STYLE } from '/constants';

interface Props extends TextProps {
  style?: StyleProp<TextStyle>;
  onPress?: () => void;
}

export default function SectionText({
  onPress,
  style,
  children,
  ...rest
}: React.PropsWithChildren<Props>) {
  return (
    <Text {...rest} onPress={onPress} style={[SECTION_TEXT_STYLE, style]}>
      {children}
    </Text>
  );
}
