import { AntDesign, Feather } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import Animated from 'react-native-reanimated';
import Hoverable from '/components/Hoverable';
import TabBar from '/components/TabBar/TabBar';
import { KEY_GRAY, KEY_LIGHT_GRAY } from '/constants';

type DiscoverSearchTabKey =
  | 'organizations'
  | 'people'
  | 'species'
  | 'skills'
  | 'topics'
  | 'groups'
  | 'jobs';

export type DiscoverSearchTab = {
  key: DiscoverSearchTabKey;
  title: string;
  mapEnabled: boolean;
};

const SEARCH_TABS: DiscoverSearchTab[] = [
  {
    key: 'organizations',
    title: 'ORGANIZATIONS',
    mapEnabled: true,
  },
  {
    key: 'people',
    title: 'PEOPLE',
    mapEnabled: false,
  },
  {
    key: 'species',
    title: 'SPECIES',
    mapEnabled: false,
  },
  {
    key: 'skills',
    title: 'SKILLS',
    mapEnabled: false,
  },
  {
    key: 'topics',
    title: 'TOPICS',
    mapEnabled: false,
  },
  {
    key: 'groups',
    title: 'GROUPS',
    mapEnabled: true,
  },
  {
    key: 'jobs',
    title: 'JOBS',
    mapEnabled: true,
  },
];

interface Props {
  onQueryChanged: (query: string) => void;
  onTabChange: (tab: DiscoverSearchTab) => void;
}

export default function SearchBox(props: Props) {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const route = useRoute<RouteProp<any>>();

  const [tabIndex, _setTabIndex] = useState(Number(route.params?.t) || 0);

  const searchInputRef = useRef<TextInput>(null);

  const [query, _setQuery] = useState<string | undefined>(
    route.params?.q || undefined,
  );
  const [queryDebounced, _setQueryDebounced] = useState<string>(
    route.params?.q || '',
  );
  const setQueryDebounced = debounce(_setQueryDebounced, 500, {
    trailing: true,
  });
  const setQuery = useCallback(
    (q: string | undefined) => {
      _setQuery(q);
      navigation.setParams({ q });
      setQueryDebounced(q ?? '');
    },
    [navigation, setQueryDebounced],
  );

  function goToListView() {
    navigation.navigate('Search', {
      q: query,
      t: tabIndex,
    });
  }

  useEffect(() => {
    props.onTabChange(SEARCH_TABS[tabIndex]);
  }, [tabIndex, props]);

  useEffect(() => {
    props.onQueryChanged(queryDebounced);
  }, [props, queryDebounced]);

  function handleTabChange(index: number) {
    if (SEARCH_TABS[index].mapEnabled === false) {
      navigation.navigate('Search', {
        q: query,
        t: index,
      });
      return;
    }

    navigation.setParams({ t: index });
    _setTabIndex(index);
  }

  return (
    /* SEARCH COMPONENT */
    <Hoverable
    //   onHoverIn={() => {
    //     setIsHovering(true);
    //   }}
    //   onHoverOut={() => {
    //     setIsHovering(false);
    //   }}
    >
      <View
        style={{
          marginHorizontal: 10,
          overflow: 'visible',
        }}
      >
        <Animated.View
          style={{
            backgroundColor: 'white',
            borderRadius: 6,
            overflow: 'hidden',
          }}
        >
          <Pressable
            onPress={() => {
              searchInputRef.current?.focus();
            }}
            style={styles.searchInputContainer}
          >
            <AntDesign
              name="search1"
              size={28}
              color={KEY_GRAY}
              style={styles.searchInputIcon}
            />
            <TextInput
              ref={searchInputRef}
              autoCorrect={false}
              placeholder="Search"
              placeholderTextColor={'gray'}
              style={styles.searchInput}
              value={query}
              onChangeText={setQuery}
              onFocus={() => {
                if (query === undefined) setQuery('');
              }}
              onBlur={() => {
                if (query === '') setQuery(undefined);
              }}
            />

            {!queryDebounced.length ? null : (
              <Pressable
                onPress={() => {
                  goToListView();
                }}
                style={({ pressed }) => [
                  styles.listViewButton,
                  {
                    opacity: pressed ? 0.5 : 1,
                  },
                ]}
              >
                <Feather name="list" size={20} color={'black'} />
                <Text style={styles.listViewButtonText}>LIST</Text>
              </Pressable>
            )}
          </Pressable>
          <TabBar
            index={tabIndex}
            onChange={handleTabChange}
            tabs={SEARCH_TABS}
            highlightPosition="top"
            tabBarStyle={{
              height: 42,
            }}
          />
        </Animated.View>
      </View>
    </Hoverable>
  );
}

const styles = StyleSheet.create({
  tabsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 4,
  },
  searchInputContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: KEY_LIGHT_GRAY,
    ...Platform.select({
      web: {
        cursor: 'text' as any,
      },
    }),
  },
  searchInputIcon: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    ...Platform.select({
      web: {
        cursor: 'default' as any,
      },
    }),
  },
  searchInput: {
    flex: 1,
    fontFamily: 'Lato',
    fontSize: 19,
    letterSpacing: 0.1,
  },
  listViewButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    marginRight: 4,
  },
  listViewButtonText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
    color: 'black',
    marginLeft: 4,
    marginTop: 3,
  },
});
