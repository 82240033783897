import { ActionSheetProvider } from '@mtourj/react-native-action-sheet';
import { MenuProvider } from 'react-native-popup-menu';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Client } from 'urql';
import AlertProvider from './AlertProvider';
import AuthProvider, { useAuthContext } from './AuthProvider';
import HabitatPickerProvider, {
  useHabitatPickerContext,
} from './HabitatPickerProvider';
import LoadingProvider, { useLoadingContext } from './LoadingProvider';
import ModalProvider, { useModalContext } from './ModalProvider';
import NotificationProvider from './NotificationProvider';
import OnboardProvider, { useOnboardContext } from './OnboardProvider';
import TeamContextProvider, { useTeamContext } from './TeamProvider';
import UrqlProvider from './UrqlProvider';
import VideoPlayerContextProvider, {
  useVideoPlayerContext,
} from './VideoPlayerContext';
import StripeProvider from './StripeProvider';
import MetaTagProvider from './MetaTagProvider';
import UpdatesProvider from './UpdatesProvider';
import AppInfoProvider from './AppInfoProvider';
import AsyncJobProvider from './AsyncJobProvider';
import ServerTimeProvider from './ServerTimeProvider';
import ReferrerProvider from './ReferrerProvider';

export {
  useAuthContext,
  useModalContext,
  useOnboardContext,
  useLoadingContext,
  useHabitatPickerContext,
  useVideoPlayerContext,
  useTeamContext,
};

interface AppContextProviderProps {
  /** Force this to be the urql client instance */
  testUrqlClient?: Client;
}

export function AppContextProvider(
  props: React.PropsWithChildren<AppContextProviderProps>,
) {
  return (
    <SafeAreaProvider
      style={{
        overflow: 'hidden',
      }}
    >
      <ReferrerProvider>
        <UrqlProvider testClient={props.testUrqlClient}>
          <AppInfoProvider>
            <ServerTimeProvider>
              <AlertProvider>
                <LoadingProvider>
                  <UpdatesProvider>
                    <AuthProvider>
                      <StripeProvider>
                        <TeamContextProvider>
                          <ActionSheetProvider>
                            <VideoPlayerContextProvider>
                              <ModalProvider>
                                <NotificationProvider>
                                  <HabitatPickerProvider>
                                    <OnboardProvider>
                                      <MetaTagProvider>
                                        <AsyncJobProvider>
                                          <MenuProvider>
                                            {props.children}
                                          </MenuProvider>
                                        </AsyncJobProvider>
                                      </MetaTagProvider>
                                    </OnboardProvider>
                                  </HabitatPickerProvider>
                                </NotificationProvider>
                              </ModalProvider>
                            </VideoPlayerContextProvider>
                          </ActionSheetProvider>
                        </TeamContextProvider>
                      </StripeProvider>
                    </AuthProvider>
                  </UpdatesProvider>
                </LoadingProvider>
              </AlertProvider>
            </ServerTimeProvider>
          </AppInfoProvider>
        </UrqlProvider>
      </ReferrerProvider>
    </SafeAreaProvider>
  );
}
