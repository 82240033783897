import {
  View,
  Text,
  StyleProp,
  ViewStyle,
  Pressable,
  StyleSheet,
} from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { AntDesign } from '@expo/vector-icons';

type Props<ItemT> = {
  style?: StyleProp<ViewStyle>;
  itemsPerPage?: number;
  /** Default is `top` */
  paginationControlsPosition?: 'top' | 'bottom';
  paginationControlsStyle?: StyleProp<ViewStyle>;
  data: ItemT[];
  renderItem: (item: ItemT, index: number) => React.ReactNode;
  textColor?: string;
  caretColor?: string;
};

const DEFAULT_MAX_PER_PAGE = 5;

export default function PaginatedList<ItemT = any>(props: Props<ItemT>) {
  const MAX_ITEMS_PER_PAGE = props.itemsPerPage ?? DEFAULT_MAX_PER_PAGE;

  const [page, setPage] = useState(0);

  const numPages = Math.ceil((props.data?.length ?? 0) / MAX_ITEMS_PER_PAGE);

  useEffect(() => {
    /** Make sure as list changes, we are always on a page that exists */
    setPage((prev) => Math.min(prev, Math.max(0, numPages - 1)));
  }, [props.data?.length, numPages, page]);

  const paginationControls = useMemo(
    () =>
      (props?.data?.length ?? 0) > MAX_ITEMS_PER_PAGE && (
        <View
          style={[styles.paginationContainer, props.paginationControlsStyle]}
        >
          <Pressable
            onPress={() => setPage(page - 1)}
            disabled={page === 0}
            style={[
              styles.paginationButton,
              {
                opacity: page === 0 ? 0.5 : 1,
              },
            ]}
          >
            <AntDesign
              name="caretleft"
              size={22}
              color={props.caretColor || 'black'}
            />
          </Pressable>

          <Text
            style={[
              styles.paginationText,
              { color: props.textColor || 'gray' },
            ]}
          >
            {page + 1} of {numPages}
          </Text>
          <Pressable
            onPress={() => setPage(page + 1)}
            disabled={page === numPages - 1}
            style={[
              styles.paginationButton,
              {
                opacity: page === numPages - 1 ? 0.5 : 1,
              },
            ]}
          >
            <AntDesign
              name="caretright"
              size={22}
              color={props.caretColor || 'black'}
            />
          </Pressable>
        </View>
      ),
    [
      MAX_ITEMS_PER_PAGE,
      numPages,
      page,
      props.caretColor,
      props?.data?.length,
      props.paginationControlsStyle,
      props.textColor,
    ],
  );

  return (
    <View style={props.style}>
      {props.paginationControlsPosition === 'bottom'
        ? null
        : paginationControls}

      {props.data
        .slice(page * MAX_ITEMS_PER_PAGE, (page + 1) * MAX_ITEMS_PER_PAGE)
        .map((item, index) =>
          props.renderItem(item, index + page * MAX_ITEMS_PER_PAGE),
        )}

      {props.paginationControlsPosition === 'bottom'
        ? paginationControls
        : null}
    </View>
  );
}

const styles = StyleSheet.create({
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 240,
    alignSelf: 'center',
    marginVertical: 8,
  },
  paginationText: {
    flex: 1,
    textAlign: 'center',
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 15,
  },
  paginationButton: {
    padding: 10,
  },
});
