import { useCallback } from 'react';
import useReferrer from './useReferrer';
import { ClientEventInput, useClientEventsMutation } from '/generated/graphql';

export default function useClientEvents() {
  const referrer = useReferrer();
  const [, clientEventsMutation] = useClientEventsMutation();

  return useCallback(
    (events: ClientEventInput[]) =>
      clientEventsMutation(
        { events },
        {
          fetchOptions: {
            headers: {
              ['x-referrer']: referrer,
            } as any,
          },
        },
      ),
    [clientEventsMutation, referrer],
  );
}
