import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Button from '../Button';
import SearchableSection from '../ImpactTab/SearchableSection';
import ListLoading from '../ListLoading';
import MomentInNatureCarouselBase from '../MomentInNatureCarouselBase/MomentInNatureCarouselBase';
import GenericError from '../common/Generic/GenericError';
import MomentInNatureIcon from '/assets/jsicons/MomentInNatureIcon';
import { useGetMomentsInNatureForUserQuery } from '/generated/graphql';
import useDebouncedState from '/hooks/useDebouncedState';
import { isOrganizationProfileComplete } from '/util';
import { useTeamContext } from '/context';

type Props = {
  userId: string;
};

export default function MyMomentsInNature(props: Props) {
  const { activeTeam } = useTeamContext();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const [, queryDebounced, setQuery] = useDebouncedState('');
  const [nextToken, setNextToken] = useState<string | null>(null);

  const [{ data, fetching, stale, error }, refetch] =
    useGetMomentsInNatureForUserQuery({
      variables: {
        userId: props.userId,
        nextToken: nextToken,
        query: queryDebounced,
      },
    });

  function onLoadMore() {
    if (data?.getUser?.moments.nextToken) {
      setNextToken(data.getUser.moments.nextToken);
    }
  }

  return (
    <SearchableSection
      title="Moments in Nature"
      loading={fetching}
      contentContainerStyle={{
        backgroundColor: 'white',
        paddingHorizontal: 16,
      }}
      renderIcon={() => (
        <MomentInNatureIcon
          width={26}
          height={26}
          color="black"
          style={{
            marginRight: 6,
            paddingBottom: 2,
          }}
        />
      )}
      onQueryChange={setQuery}
    >
      {!data && fetching ? (
        <ListLoading />
      ) : !data && error ? (
        <GenericError onRetry={refetch} />
      ) : !data?.getUser?.moments.items.length ? (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>
            {!queryDebounced.length
              ? 'No moments in nature yet.'
              : `No moments in nature matching "${queryDebounced}".`}
          </Text>
          {isOrganizationProfileComplete(activeTeam?.user as any) ? (
            <Button
              label="Create Moment in Nature"
              containerStyle={{
                marginTop: 8,
              }}
              onPress={() => {
                navigation.navigate('CreateMomentInNature');
              }}
            />
          ) : null}
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <MomentInNatureCarouselBase
            compact
            shouldAutoplay={false}
            items={data?.getUser?.moments.items || []}
            hasMore={!!data?.getUser?.moments.nextToken}
            loading={fetching || stale}
            onLoadMore={onLoadMore}
          />
        </View>
      )}
    </SearchableSection>
  );
}

const styles = StyleSheet.create({
  emptyContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    padding: 24,
  },
  emptyText: {
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
});
