import { useForegroundPermissions } from 'expo-location';
import React, { useMemo, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Linking,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { Platform, Text, View } from 'react-native';
import MapView, { Marker, PROVIDER_GOOGLE, Region } from 'react-native-maps';
import { KEY_GRAY, TEXT_INPUT } from '/constants';
import LocationInput from '../LocationInput/LocationInput';
import Button from '../Button';
import reverseGeocode from '/util/mapping/reverseGeocode';

type LocationInputType = {
  name: string;
  latitude?: number;
  longitude?: number;
};

type Props = {
  containerStyle?: ViewStyle;
  inputStyle?: ViewStyle;
  onChange: (value: LocationInputType) => void;
  value: Partial<LocationInputType> | undefined;
};

export default function LocationInputWithMap({
  value,
  onChange,
  ...props
}: Props) {
  const [status, requestPermission] = useForegroundPermissions();

  const mapRef = useRef<MapView | null>();

  /** When `region` is set on MapView, it does not allow user to drag around the map.
   * This flag is used to temporarily set `region`, then unset it to unblock dragging.*/
  const [forceRegion, setForceRegion] = useState(false);

  const [originLat, setOriginLat] = useState<number>();
  const [originLon, setOriginLon] = useState<number>();

  const [geocoding, setGeocoding] = useState(false);

  /** Map camera behavior */
  const previousLocation = useRef<string>();
  const mapRegion = useMemo<Region | undefined>(() => {
    if (!forceRegion && Platform.OS !== 'ios') {
      return undefined;
    }

    setImmediate(() => {
      setForceRegion(false);
    });

    if (!value?.latitude || !value?.longitude) {
      return undefined;
    }

    if (value.name === previousLocation.current && Platform.OS !== 'ios') {
      return undefined;
    }

    const shouldZoomIn = !!value?.latitude;

    const region: Region = {
      latitude: value?.latitude ?? 0,
      longitude: value?.longitude ?? 0,
      latitudeDelta: shouldZoomIn ? 0.04 : 1,
      longitudeDelta: shouldZoomIn ? 0.04 : 1,
    };

    previousLocation.current = value.name;

    return region;
  }, [value?.latitude, value?.longitude, value?.name, forceRegion]);

  async function onMoveMarker({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) {
    setGeocoding(true);

    try {
      const geocodeResults = await reverseGeocode({
        latitude,
        longitude,
      });

      const result = geocodeResults[0] ?? {};

      const formattedAddress = result.plus_code?.long_name
        ? result.formatted_address.replace(
            result.plus_code?.long_name,
            `${latitude}, ${longitude}`,
          )
        : result.formatted_address;

      onChange({
        name: formattedAddress || `${latitude}, ${longitude}`,
        latitude,
        longitude,
      });
    } catch (err) {
      console.log('error reverse geocoding', err);
      onChange({
        name: '',
      } as LocationInputType);
    } finally {
      setGeocoding(false);
    }
  }

  return (
    <View style={props.containerStyle}>
      <View
        style={{
          zIndex: 1,
          borderRadius: 8,
          marginBottom: 8,
        }}
      >
        {geocoding && (
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                zIndex: 2,
                backgroundColor: 'rgba(50, 50, 50, 0.4)',
                justifyContent: 'center',
              },
            ]}
          >
            <ActivityIndicator
              size="small"
              color={KEY_GRAY}
              style={{
                alignSelf: 'center',
              }}
            />
          </View>
        )}
        <LocationInput
          pointerEvents={geocoding ? 'none' : 'auto'}
          hidePoweredByGoogle
          style={[TEXT_INPUT, props.inputStyle]}
          originLocation={
            originLat && originLon
              ? {
                  lat: originLat,
                  lon: originLon,
                }
              : undefined
          }
          containerStyle={{
            marginTop: 8,
            zIndex: 1,
          }}
          value={value?.name}
          onChangeText={(text, isValid, prediction) => {
            onChange({
              name: text,
              latitude: prediction?.latitude,
              longitude: prediction?.longitude,
            });

            setForceRegion(true);
          }}
        />
      </View>
      <View
        style={{
          borderRadius: 6,
          overflow: 'hidden',
        }}
      >
        {!status?.granted && Platform.OS === 'android' && (
          <View style={styles.mapNoPermissionOverlay}>
            <Text style={styles.mapNoPermissionOverlayText}>
              Please give Key Conservation permission to use location services
              in order to use the map.
            </Text>
            <Button
              containerStyle={{
                marginTop: 8,
              }}
              label={status?.canAskAgain ? 'Allow' : 'Go to settings'}
              onPress={() => {
                if (status?.canAskAgain) {
                  requestPermission();
                } else {
                  Linking.openSettings();
                }
              }}
            />
          </View>
        )}
        <MapView
          provider={PROVIDER_GOOGLE}
          onLongPress={(e) => {
            const latitude = e.nativeEvent.coordinate.latitude;
            const longitude = e.nativeEvent.coordinate.longitude;

            onMoveMarker({
              latitude,
              longitude,
            });
          }}
          ref={(r) => (mapRef.current = r)}
          /** @ts-ignore - For web only */
          options={{
            styles: [
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }], // Turn off markers for points of interest.
              },
            ],
          }}
          onPress={(e) => {
            if (Platform.OS !== 'web') return;

            // @ts-ignore
            const latitude = e.latLng.lat();
            // @ts-ignore
            const longitude = e.latLng.lng();

            onMoveMarker({
              latitude,
              longitude,
            });
          }}
          onRegionChangeComplete={(region) => {
            if (!region) return;
            setOriginLat(region.latitude);
            setOriginLon(region.longitude);
          }}
          style={styles.map}
          initialRegion={{
            latitudeDelta: value?.latitude ? 0.1 : 100,
            longitudeDelta: value?.longitude ? 0.1 : 100,
            latitude: value?.latitude ?? 0,
            longitude: value?.longitude ?? 0,
          }}
          region={mapRegion}
        >
          {value?.latitude ? (
            <Marker
              coordinate={{
                latitude: value?.latitude ?? 0,
                longitude: value?.longitude ?? 0,
              }}
              draggable
              onDragEnd={(e) => {
                e.persist?.();

                let latitude = e.nativeEvent?.coordinate.latitude;
                let longitude = e.nativeEvent?.coordinate.longitude;

                /** Behaves differently on the web */
                if (Platform.OS === 'web') {
                  // @ts-ignore
                  latitude = e.latLng?.lat();
                  // @ts-ignore
                  longitude = e.latLng?.lng();
                }

                if (
                  typeof latitude !== 'number' ||
                  typeof longitude !== 'number'
                )
                  return;

                onMoveMarker({
                  latitude,
                  longitude,
                });
              }}
            />
          ) : null}
        </MapView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  map: {
    flex: 1,
    borderRadius: 6,
    overflow: 'hidden',
    height: 300,
    width: '100%',
    /** Without minHeight set, map won't appear in the browser */
    minHeight: 300,
  },
  mapNoPermissionOverlay: {
    zIndex: 1,
    elevation: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mapNoPermissionOverlayText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'white',
    textAlign: 'center',
    padding: 12,
  },
});
