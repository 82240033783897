import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import SectionContainer from '/components/common/Section/SectionContainer';
import GridList from '/components/GridList';
import { Group, PaginatedGroups, User } from '/generated/graphql';
import Avatar from '/components/Avatar';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

interface IGroup extends Pick<Group, 'group_logo' | 'name' | 'id'> {}

interface IPaginatedGroups
  extends Pick<PaginatedGroups, 'total' | 'nextToken'> {
  items: Array<IGroup>;
}

interface IUser extends Pick<User, 'id'> {
  groups: IPaginatedGroups;
}

type UserGroupsProps = {
  data: IUser | undefined | null;
};

export default function UserGroups(props: UserGroupsProps) {
  const { styles: commonStyles } = useCommonStyles();

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  function onViewGroup(id: string) {
    navigate('ViewGroup', { id });
  }

  function onSeeAllGroups() {
    navigate('ListUserGroups', {
      userId: props.data?.id,
    });
  }

  return props.data?.groups.items.length ? (
    <SectionContainer>
      <View style={commonStyles('sectionTitleContainer')}>
        <Text style={commonStyles('sectionTitle')}>
          GROUPS ({props.data.groups.total})
        </Text>
      </View>

      <GridList
        data={props.data.groups.items}
        renderItem={({ item, tileWidth }) => {
          return (
            <Pressable
              onPress={() => {
                onViewGroup(item.id);
              }}
              style={{
                flex: 1,
                justifyContent: 'flex-start',
              }}
            >
              <Avatar
                rounded
                source={{ uri: item.group_logo ?? '' }}
                size={tileWidth - 8}
              />
              <Text style={styles.groupName}>{item.name.toUpperCase()}</Text>
            </Pressable>
          );
        }}
      />

      {props.data.groups.nextToken ? (
        <View style={commonStyles('seeAllButtonContainer')}>
          <Pressable
            style={({ pressed }) => [
              commonStyles('seeAllButton'),
              {
                opacity: pressed ? 0.5 : 1,
              },
            ]}
            onPress={onSeeAllGroups}
          >
            <Text style={commonStyles('seeAllButtonText')}>See All {`>`}</Text>
          </Pressable>
        </View>
      ) : null}
    </SectionContainer>
  ) : null;
}

const styles = StyleSheet.create({
  groupName: {
    marginTop: 8,
    textTransform: 'uppercase',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
    textAlign: 'center',
  },
});
