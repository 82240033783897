import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  videoIconTopLeftContainer: {
    position: 'absolute',
    left: 10,
    top: 10,
    zIndex: 1,
  },
  videoIconBottomLeftContainer: {
    position: 'absolute',
    left: 10,
    bottom: 10,
    zIndex: 1,
  },
  errorContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#222',
  },
  errorText: {
    textAlign: 'center',
    fontFamily: 'Lato',
    color: '#ddd',
    fontSize: 16,
  },
  retryButton: {
    padding: 8,
    alignItems: 'center',
    flexDirection: 'row',
  },
  retryButtonText: {
    color: '#fff',
    fontFamily: 'Lato',
    fontSize: 16,
    paddingLeft: 4,
  },
});
