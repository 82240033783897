import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Platform,
  StyleProp,
  Text,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import styles from './MediaViewer.style';
import MediaItem from './elements/MediaItem';
import { useFullscreenMediaViewer } from '/context/FullscreenMediaViewerProvider';
import { MaterialIcons } from '@expo/vector-icons';

// This component is responsible for rendering campaign post media
interface IMediaViewerProps {
  sourceUris?: string[];
  style?: StyleProp<ViewStyle>;
  videoIndicatorPosition?: 'top-left' | 'bottom-left';
  noAutoplay?: boolean;
  /** If true, the media will be resized to fit the width of the content
   * Note: This will only work if there is only one image */
  fitToContentWidth?: boolean;
  onLayout?: (width: number, height: number) => void;
  thumbnailUri?: string;
  /** If set, MediaViewer will not try to get a resized version of media */
  useOriginalSource?: boolean;
  /** Override internally determined maxHeight */
  maxHeight?: number;
  /** Display media in a fix height instead of adapting to content */
  fixedHeight?: number;
}

const COLLAGE_FIXED_RATIO = 1.78;

const MediaViewer = ({ onLayout, ...props }: IMediaViewerProps) => {
  const { showMedia } = useFullscreenMediaViewer();

  const sourceUris = useMemo(
    () => (props.sourceUris ?? []).filter(Boolean),
    [props.sourceUris],
  );

  const isCollage = sourceUris.length > 1;

  const [naturalMediaHeight, setNaturalMediaHeight] = useState(460);
  const [naturalMediaWidth, setNaturalMediaWidth] = useState(400);
  const naturalMediaRatio = useMemo(() => {
    return naturalMediaWidth / naturalMediaHeight;
  }, [naturalMediaWidth, naturalMediaHeight]);
  const [mediaWidth, _setMediaWidth] = useState(400);
  const setMediaWidth = debounce(_setMediaWidth, 100, {
    leading: true,
  });
  const mediaHeight = useMemo(() => {
    if (typeof props.fixedHeight === 'number') return props.fixedHeight;

    let ratio = isCollage ? COLLAGE_FIXED_RATIO : naturalMediaRatio;

    // TODO: This needs to be done in a way that is less feedback-loop-prone

    return Math.floor(mediaWidth / ratio);
  }, [props.fixedHeight, isCollage, naturalMediaRatio, mediaWidth]);

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const MIN_HEIGHT = Math.min(Math.max(windowWidth / 2.2, 200), 320);
  const MAX_HEIGHT = props.maxHeight ?? (windowHeight >= 1080 ? 720 : 640);

  const _onLayout = useMemo(
    () =>
      debounce(
        (width: number, height: number) => {
          onLayout?.(width, height);
        },
        50,
        { leading: false },
      ),
    [onLayout],
  );

  useEffect(() => {
    const height =
      typeof props.fixedHeight === 'number'
        ? props.fixedHeight
        : Math.max(Math.min(mediaHeight, MAX_HEIGHT), MIN_HEIGHT);

    _onLayout(mediaWidth, height);
  }, [
    MIN_HEIGHT,
    MAX_HEIGHT,
    mediaHeight,
    mediaWidth,
    _onLayout,
    props.fixedHeight,
  ]);

  useEffect(() => {
    if (sourceUris.length !== 1) return;
    if (Platform.OS !== 'web') return;

    const uri = sourceUris[0];

    const img = new Image();

    img.onload = function () {
      setNaturalMediaHeight(img.naturalHeight);
      setNaturalMediaWidth(img.naturalWidth);
    };

    img.src = uri;
  }, [sourceUris]);

  const actualMediaWidth =
    props.fitToContentWidth && !isCollage
      ? Math.max(MIN_HEIGHT, Math.min(mediaHeight, MAX_HEIGHT)) *
        naturalMediaRatio
      : mediaWidth;

  const targetCDNMediaWidth = useMemo(() => {
    return actualMediaWidth > 400 ? 600 : 400;
  }, [actualMediaWidth]);

  let layout: JSX.Element | null = null;

  function showMediaAtIndex(index: number) {
    showMedia({
      sourceUris,
      initialIndex: index,
      useOriginalSource: props.useOriginalSource ?? false,
    });
  }

  let mediaItemProps = {
    isVideoPlayable: false,
    shouldAutoplayVideo: false,
    targetCDNMediaWidth: targetCDNMediaWidth,
    targetCDNMediaHeight: MAX_HEIGHT,
    useOriginalSource: props.useOriginalSource ?? false,
    videoIndicatorPosition: props.videoIndicatorPosition,
  };

  switch (sourceUris.length) {
    case 0:
      break;
    case 1:
      layout = (
        <MediaItem
          {...mediaItemProps}
          onPress={() => showMediaAtIndex(0)}
          isVideoPlayable
          shouldAutoplayVideo={!props.noAutoplay}
          thumbnailUri={props.thumbnailUri}
          style={styles.single}
          uri={sourceUris[0]}
          onNaturalMediaDimensions={(width, height) => {
            setNaturalMediaHeight(height);
            setNaturalMediaWidth(width);
          }}
        />
      );
      break;
    case 2:
      layout = (
        <View style={styles.double}>
          <MediaItem
            {...mediaItemProps}
            onPress={() => showMediaAtIndex(0)}
            style={styles.single}
            uri={sourceUris[0]}
          />
          <View style={styles.verticalSpacer} />
          <MediaItem
            {...mediaItemProps}
            onPress={() => showMediaAtIndex(1)}
            style={styles.single}
            uri={sourceUris[1]}
          />
        </View>
      );
      break;
    case 3:
      layout = (
        <View style={styles.double}>
          <View style={styles.single}>
            <MediaItem
              {...mediaItemProps}
              onPress={() => showMediaAtIndex(0)}
              style={styles.half}
              uri={sourceUris[0]}
            />
            <View style={styles.horizontalSpacer} />
            <MediaItem
              {...mediaItemProps}
              onPress={() => showMediaAtIndex(1)}
              style={styles.half}
              uri={sourceUris[1]}
            />
          </View>
          <View style={styles.verticalSpacer} />
          <MediaItem
            {...mediaItemProps}
            onPress={() => showMediaAtIndex(2)}
            style={styles.single}
            uri={sourceUris[2]}
          />
        </View>
      );
      break;
    case 4:
    default:
      layout = (
        <View style={styles.double}>
          <View style={styles.single}>
            <MediaItem
              {...mediaItemProps}
              onPress={() => showMediaAtIndex(0)}
              style={styles.half}
              uri={sourceUris[0]}
            />
            <View style={styles.horizontalSpacer} />
            <MediaItem
              {...mediaItemProps}
              onPress={() => showMediaAtIndex(1)}
              style={styles.half}
              uri={sourceUris[1]}
            />
          </View>

          <View style={styles.verticalSpacer} />

          <View style={styles.single}>
            <MediaItem
              {...mediaItemProps}
              onPress={() => showMediaAtIndex(2)}
              style={styles.half}
              uri={sourceUris[2]}
            />
            <View style={styles.horizontalSpacer} />
            <View style={styles.half}>
              <MediaItem
                {...mediaItemProps}
                onPress={() => showMediaAtIndex(3)}
                style={{ flex: 1 }}
                uri={sourceUris[3]}
                hideErrorRetryButton={sourceUris.length > 4}
              />
              {sourceUris.length > 4 ? (
                <View pointerEvents="none" style={styles.moreOverlay}>
                  <Text style={styles.moreOverlayText}>
                    +{sourceUris.length - 3}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>
      );
  }

  return (
    <View
      style={[
        {
          overflow: 'hidden',
          borderRadius: 6,
        },
        props.style,
        typeof props.fixedHeight === 'number'
          ? {
              minHeight: props.fixedHeight,
              maxHeight: props.fixedHeight,
            }
          : {
              minHeight: MIN_HEIGHT,
              maxHeight: MAX_HEIGHT,
            },
      ]}
      onLayout={({ nativeEvent }) => {
        setMediaWidth(nativeEvent.layout.width);
      }}
    >
      <View
        style={{
          width: actualMediaWidth,
          height: mediaHeight,
          minHeight:
            typeof props.fixedHeight === 'number' ? undefined : MIN_HEIGHT,
          maxHeight:
            typeof props.fixedHeight === 'number' ? undefined : MAX_HEIGHT,
          alignSelf: 'center',
          overflow: 'hidden',
        }}
      >
        {layout ?? (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <MaterialIcons name="image-not-supported" size={48} color="gray" />
          </View>
        )}
      </View>
    </View>
  );
};

export default MediaViewer;
