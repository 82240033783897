import { Platform, StyleSheet } from 'react-native';
import { BUTTON_SHADOW, KEY_LIGHT_GRAY } from '/constants';

export default StyleSheet.create({
  container: {},
  titleText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
  },
  fieldLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    marginTop: 8,
    marginBottom: 4,
    textTransform: 'uppercase',
  },
  fieldContainer: {
    marginRight: 6,
  },
  filterButton: {
    borderWidth: 1,
    maxWidth: 180,
    borderColor: KEY_LIGHT_GRAY,
    borderRadius: 6,
    marginBottom: 6,
    padding: 8,
    backgroundColor: 'white',
    alignSelf: 'flex-start',
    minWidth: 140,
    ...BUTTON_SHADOW,
    ...(Platform.OS === 'web'
      ? {
          // @ts-ignore
          cursor: 'pointer',
        }
      : {}),
  },
  filterButtonText: {
    fontFamily: 'Lato-Bold',
    color: 'black',
    fontSize: 16,
  },
});
