import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { FlatList, Pressable, StyleSheet, Text, View } from 'react-native';
import Alert from '/Alert';
import CreativeConnectDark from '/assets/jsicons/CreativeConnectDark';
import Button from '/components/Button';
import CreativeConnectProjectCard from '/components/CreativeConnect/CreativeConnectProjectCard/CreativeConnectProjectCard';
import ListLoading from '/components/ListLoading';
import GenericError from '/components/common/Generic/GenericError';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionContainer from '/components/common/Section/SectionContainer';
import {
  ALERT_RED,
  CARD_TITLE_FONT_SIZE,
  CREATIVE_CONNECT_BACKGROUND_GRAY,
  KEY_GREEN,
  KEY_LIGHT_GRAY,
} from '/constants';
import {
  ListMyCreativeConnectInvitesQuery,
  useDeleteCreativeConnectCollaboratorMutation,
  useJoinCreativeConnectCollaboratorMutation,
  useListMyCreativeConnectApplicationsQuery,
  useListMyCreativeConnectInvitesQuery,
  useListMyCreativeConnectProjectsQuery,
} from '/generated/graphql';
import { base64EncodeObject } from '/util';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';

type Props = {
  userId: string;
};

export default function MyCreativeConnect(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [projects, refetchProjects] = useListMyCreativeConnectProjectsQuery({
    variables: {
      userId: props.userId,
    },
    requestPolicy: 'cache-and-network',
  });
  const [applications, refetchApplications] =
    useListMyCreativeConnectApplicationsQuery({
      variables: {
        userId: props.userId,
      },
    });
  const [invites, refetchInvites] = useListMyCreativeConnectInvitesQuery({
    variables: {
      userId: props.userId,
    },
  });

  function onViewStats() {
    navigate('CreativeConnectAnalyticsDashboard', {
      userId: props.userId,
    });
  }

  const FEATURE_FLAG_SHOULD_SHOW =
    projects.data?.listMyCreativeConnectProjects.total ||
    applications.data?.listMyCreativeConnectApplications.total ||
    invites.data?.listMyCreativeConnectInvites.total;

  return !FEATURE_FLAG_SHOULD_SHOW ? null : (
    <SectionContainer
      style={{
        backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
      }}
    >
      <HorizontalContainer>
        <CreativeConnectDark width={21} height={21} />
        <Text style={styles.title}>Creative Connect</Text>
      </HorizontalContainer>

      {invites.data?.listMyCreativeConnectInvites.items.length ||
      invites.error ? (
        <>
          <Text style={styles.sectionTitle}>INVITES</Text>
          <Text style={styles.sectionText}>
            Projects you are invited to collaborate on
          </Text>

          {invites.fetching ? (
            <ListLoading color="white" />
          ) : invites.error ? (
            <GenericError onRetry={refetchInvites} />
          ) : !invites.data?.listMyCreativeConnectInvites.items.length ? (
            <Text style={styles.emptyText}>No Invites</Text>
          ) : (
            <FlatList
              horizontal
              contentContainerStyle={styles.horizontalFlatListContentContainer}
              data={invites.data?.listMyCreativeConnectInvites.items ?? []}
              renderItem={({ item }) => (
                <CreativeConnectInvite userId={props.userId} data={item} />
              )}
            />
          )}

          {invites.data?.listMyCreativeConnectInvites.nextToken ? (
            <Text
              onPress={() => {
                navigate('ListCreativeConnectProjects', {
                  ccf: base64EncodeObject({
                    collaboratorUserId: props.userId,
                    collaboratorConfirmed: false,
                    collaboratorInitiatedByCollaborator: false,
                  }),
                });
              }}
              style={styles.seeAllButton}
            >
              See All {`>`}
            </Text>
          ) : null}
        </>
      ) : null}

      <Text style={styles.sectionTitle}>MY PROJECTS</Text>
      <Text style={styles.sectionText}>
        Projects you created or collaborate on
      </Text>
      {projects.fetching ? (
        <ListLoading color="white" />
      ) : projects.error ? (
        <GenericError onRetry={refetchProjects} />
      ) : !projects.data?.listMyCreativeConnectProjects.items.length ? (
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={styles.emptyText}>No Projects</Text>
        </View>
      ) : (
        <>
          <Pressable
            onPress={onViewStats}
            style={({ pressed }) => [
              styles.viewStatsButton,
              {
                opacity: pressed ? 0.5 : 1,
              },
            ]}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Ionicons
                name="stats-chart-sharp"
                size={20}
                color="white"
                style={{
                  marginRight: 6,
                }}
              />
              <Text style={styles.viewStatsButtonText}>
                View Creative Connect Analytics
              </Text>
            </View>

            <ChevronRight fill="white" />
          </Pressable>

          <FlatList
            horizontal
            contentContainerStyle={styles.horizontalFlatListContentContainer}
            data={projects.data?.listMyCreativeConnectProjects.items ?? []}
            renderItem={({ item }) => {
              return (
                <View>
                  <CreativeConnectProjectCard
                    data={item}
                    displayCreativeTypeAndStatus
                    style={{
                      width: 300,
                      marginRight: 8,
                    }}
                    viewAsUserId={props.userId}
                  />
                </View>
              );
            }}
          />
        </>
      )}

      {projects.data?.listMyCreativeConnectProjects.nextToken ? (
        <Text
          onPress={() => {
            navigate('ListCreativeConnectProjects', {
              ccf: base64EncodeObject({
                confirmedCollaboratorOrCreatedByUserId: props.userId,
              }),
            });
          }}
          style={styles.seeAllButton}
        >
          See All {`>`}
        </Text>
      ) : null}

      {applications.data?.listMyCreativeConnectApplications.items.length ||
      applications.error ? (
        <>
          <Text style={styles.sectionTitle}>MY APPLICATIONS</Text>
          <Text style={styles.sectionText}>
            Projects you applied to collaborate on
          </Text>
          {applications.fetching ? (
            <ListLoading color="white" />
          ) : applications.error ? (
            <GenericError onRetry={refetchApplications} />
          ) : !applications.data?.listMyCreativeConnectApplications.items
              .length ? (
            <Text style={styles.emptyText}>No Applications</Text>
          ) : (
            <FlatList
              horizontal
              contentContainerStyle={styles.horizontalFlatListContentContainer}
              data={
                applications.data?.listMyCreativeConnectApplications.items ?? []
              }
              renderItem={({ item }) => {
                return (
                  <View>
                    <CreativeConnectProjectCard
                      data={item}
                      smallThumbnail
                      displayCreativeTypeAndStatus
                      style={{
                        width: 300,
                        marginRight: 8,
                      }}
                      viewAsUserId={props.userId}
                    />
                  </View>
                );
              }}
            />
          )}

          {applications.data?.listMyCreativeConnectApplications.nextToken ? (
            <Text
              onPress={() => {
                navigate('ListCreativeConnectProjects', {
                  ccf: base64EncodeObject({
                    collaboratorUserId: props.userId,
                    collaboratorConfirmed: false,
                    collaboratorInitiatedByCollaborator: true,
                  }),
                });
              }}
              style={styles.seeAllButton}
            >
              See All {`>`}
            </Text>
          ) : null}
        </>
      ) : null}
    </SectionContainer>
  );
}

function CreativeConnectInvite(props: {
  userId: string;
  data: ListMyCreativeConnectInvitesQuery['listMyCreativeConnectInvites']['items'][number];
}) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [{ fetching: joining }, joinCreativeConnect] =
    useJoinCreativeConnectCollaboratorMutation();
  const [{ fetching: deleting }, leaveCreativeConnect] =
    useDeleteCreativeConnectCollaboratorMutation();

  function onAcceptInvite(projectId: string) {
    joinCreativeConnect({
      input: {
        projectId: projectId,
        userId: props.userId,
      },
    })
      .then(({ error }) => {
        if (error) throw error;

        Alert.notify({
          message: 'Invitation accepted!',
          color: KEY_GREEN,
        });
      })
      .catch((err) => {
        console.log('joinCreativeConnect error', err);
        Alert.alert(
          'Error',
          'Failed to join project. Please try again or report this issue if it persists.',
        );
      });
  }

  function onDeleteInvite(projectId: string) {
    leaveCreativeConnect({
      projectId: projectId,
      userId: props.userId,
    })
      .then(({ error }) => {
        if (error) throw error;

        Alert.notify({
          message: 'Invite deleted!',
          color: KEY_GREEN,
        });
      })
      .catch((err) => {
        console.log('leaveCreativeConnect error', err);
        Alert.alert(
          'Error',
          'Failed to delete invite. Please try again or report this issue if it persists.',
        );
      });
  }

  return (
    <View>
      <CreativeConnectProjectCard
        data={props.data}
        displayCreativeTypeAndStatus
        style={{
          width: 300,
          marginRight: 8,
        }}
        onPress={() => {
          navigate('ViewCreativeConnectLanding', {
            id: props.data.id,
            vUId: props.userId,
          });
        }}
        viewAsUserId={props.userId}
      />
      <HorizontalContainer
        style={{
          justifyContent: 'flex-end',
          paddingBottom: 3,
          marginHorizontal: 4,
          marginTop: 8,
        }}
      >
        <Button
          label="Accept"
          containerStyle={{
            marginRight: 6,
          }}
          style={{
            backgroundColor: KEY_GREEN,
          }}
          disabled={joining || deleting}
          loading={joining}
          onPress={() => {
            onAcceptInvite(props.data.id);
          }}
        />
        <Button
          label={<FontAwesome name="trash-o" size={20.5} color={ALERT_RED} />}
          loading={deleting}
          disabled={joining || deleting}
          onPress={() => {
            onDeleteInvite(props.data.id);
          }}
        />
      </HorizontalContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    color: 'white',
    fontFamily: 'Lato-Bold',
    fontSize: CARD_TITLE_FONT_SIZE,
    marginLeft: 8,
  },
  sectionTitle: {
    marginTop: 16,
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 17,
    color: KEY_LIGHT_GRAY,
  },
  sectionText: {
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 15,
    marginBottom: 8,
  },
  seeAllButton: {
    alignSelf: 'flex-end',
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'white',
    marginTop: 6,
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'gray',
    marginVertical: 8,
    alignSelf: 'center',
  },
  horizontalFlatListContentContainer: {
    paddingBottom: 8,
  },
  viewStatsButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'white',
  },
  viewStatsButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
    marginVertical: 8,
    marginBottom: 16,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: '#bbb',
    borderBottomColor: '#bbb',
  },
});
