import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Button from '/components/Button';
import ScrollView from '/components/common/ScrollView/ScrollView';
import RadioOption from '/components/RadioOption';
import { KEY_GREEN } from '/constants';
import { EventType } from '/generated/graphql';
import {
  eventTypeToLabel,
  eventTypesToLabels,
} from '../../../../util/eventTypeLabels';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';

type Props = {
  initialSelectedTypes?: EventType[];
  onRequestClose: (selectedTypes: EventType[]) => void;
};

export default function EventTypeFilterModal({
  initialSelectedTypes = [],
  onRequestClose,
}: Props) {
  const [selectedTypes, setSelectedTypes] =
    useState<EventType[]>(initialSelectedTypes);

  const toggleType = (type: EventType) => {
    setSelectedTypes((prev) => {
      const newTypes = prev.includes(type)
        ? prev.filter((t) => t !== type)
        : [...prev, type];
      return newTypes.length === 0 ? [] : newTypes;
    });
  };

  const isAnySelected = selectedTypes.length === 0;

  return (
    <>
      <ScrollView>
        <RadioOption
          key="Any"
          title="Any"
          isSelected={isAnySelected}
          onPress={() => setSelectedTypes([])}
          style={styles.checkbox}
        />
        {[EventType.Online, EventType.Hybrid, EventType.InPerson].map(
          (type) => (
            <RadioOption
              key={type}
              title={eventTypeToLabel(type)}
              isSelected={selectedTypes.includes(type)}
              onPress={() => toggleType(type)}
              style={styles.checkbox}
            />
          ),
        )}
      </ScrollView>
      <View style={styles.footer}>
        <Text style={styles.selectionText}>
          {isAnySelected
            ? 'All event types will be shown'
            : `Showing ${eventTypesToLabels(selectedTypes)} events`}
        </Text>

        <HorizontalContainer
          style={{
            alignSelf: 'flex-end',
          }}
        >
          {selectedTypes.length ? (
            <Button
              label="Reset"
              containerStyle={{
                marginRight: 8,
              }}
              onPress={() => setSelectedTypes([])}
            />
          ) : null}
          <Button
            label="Apply"
            onPress={() => onRequestClose(selectedTypes)}
            style={styles.applyButton}
          />
        </HorizontalContainer>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  checkbox: {
    marginBottom: 6,
  },
  footer: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectionText: {
    flex: 1,
    fontSize: 14,
    color: 'gray',
  },
  applyButton: {
    backgroundColor: KEY_GREEN,
  },
});
