import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React from 'react';
import {
  Image,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import Avatar from '/components/Avatar';
import ButtonToggle from '/components/ButtonToggle/ButtonToggle';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import { KEY_GRAY, KEY_LIGHT_GRAY } from '/constants';
import {
  CreativeConnectProject,
  PaginatedCreativeConnectCollaborators,
  User,
} from '/generated/graphql';
import getCreativeTypeLabel from '/util/creative-connect/getCreativeTypeLabel';
import getCDNImageUri from '/util/getCDNImageUri';
import CreativeTypeIcon from '../CreativeTypeIcon/CreativeTypeIcon';

export interface ICreativeConnectProject
  extends Pick<
    CreativeConnectProject,
    | 'id'
    | 'title'
    | 'media'
    | 'schedule_for_premiere'
    | 'release_date'
    | 'creative_type'
    | 'description'
    | 'is_paid'
    | 'created_at'
    | 'locations'
    | 'application_deadline'
    | 'is_premiered'
    | 'is_series'
  > {
  created_by: Pick<User, 'id' | 'name' | 'profile_image'>;
  collaborators: Pick<
    PaginatedCreativeConnectCollaborators,
    'total' | 'nextToken'
  > & {
    items: {
      user: Pick<User, 'id' | 'profile_image'>;
    }[];
  };
}

type OnPressEvent = {
  /** Default is `true` */
  shouldOverrideDefault: boolean;
};

type Props = {
  style?: StyleProp<ViewStyle>;
  /** Default is `dark` */
  theme?: 'light' | 'dark';
  /** If set, default navigation behavior will focus the
   * "open for collaboration" section */
  navigateShouldFocusOpenForCollaboration?: boolean;
  viewAsUserId?: string;
  smallThumbnail?: boolean;
  displayCreativeTypeAndStatus?: boolean | 'creative-type-only';
  hideApplicationDeadline?: boolean;
  /** Overrides default onPress behavior */
  onPress?: (event: OnPressEvent) => void;
  data: ICreativeConnectProject;
};

export default function CreativeConnectProjectCard(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const shouldDisplayApplicationDeadline =
    props.data?.application_deadline &&
    !props.hideApplicationDeadline &&
    !(
      props.data.schedule_for_premiere &&
      dayjs(props.data.release_date).isAfter(dayjs())
    );

  return (
    <Pressable
      onPress={() => {
        if (props.onPress) {
          const event: OnPressEvent = {
            shouldOverrideDefault: true,
          };

          props.onPress(event);

          if (event.shouldOverrideDefault) {
            return;
          }
        }

        const params: any = {
          id: props.data.id,
        };

        if (props.viewAsUserId) {
          params.vUId = props.viewAsUserId;
        }

        if (props.navigateShouldFocusOpenForCollaboration) {
          params.fc = 1;
        }

        navigate('ViewCreativeConnectLanding', params);
      }}
      style={[styles.container, props.style]}
    >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flex: 1 }}>
          {/* THUMBNAIL */}
          {props.smallThumbnail ? null : (
            <Image
              source={{
                uri: getCDNImageUri({
                  uri: props.data?.media?.[0],
                  isThumbnail: true,
                  dimensions: {
                    width: 300,
                    height: 150,
                  },
                }),
              }}
              style={styles.thumbnail}
            />
          )}

          {/* CREATIVE TYPES */}
          {props.displayCreativeTypeAndStatus ? (
            <ButtonToggle
              key={props.data.creative_type.toString()}
              label={
                <HorizontalContainer>
                  <CreativeTypeIcon
                    creativeType={props.data.creative_type}
                    size={12}
                    color={props.theme === 'light' ? 'white' : 'black'}
                    style={{
                      marginRight: 3,
                    }}
                  />
                  <Text
                    style={[
                      styles.creativeTypeButtonText,
                      {
                        color: props.theme === 'light' ? 'white' : 'black',
                      },
                    ]}
                  >
                    {getCreativeTypeLabel(props.data.creative_type) +
                      (props.data.is_series ? ' Series' : '')}
                  </Text>
                </HorizontalContainer>
              }
              disabled
              color={props.theme === 'light' ? KEY_GRAY : 'white'}
              style={styles.creativeTypeButton}
            />
          ) : null}

          {/* TITLE */}
          <Text
            style={[
              styles.title,
              { color: props.theme === 'light' ? 'black' : 'white' },
            ]}
            numberOfLines={2}
          >
            {props.data?.title.text ?? 'Untitled'}
          </Text>

          {/* STATUS */}
          {props.displayCreativeTypeAndStatus &&
          props.displayCreativeTypeAndStatus !== 'creative-type-only' ? (
            <Text
              style={[
                styles.statusText,
                { color: props.theme === 'light' ? 'black' : 'white' },
              ]}
            >
              {props.data.is_premiered
                ? 'RELEASED'
                : props.data.schedule_for_premiere && props.data.release_date
                ? `PREMIERING ${dayjs(props.data.release_date).format('MMM D')}`
                : 'IN DEVELOPMENT'}
            </Text>
          ) : null}

          {/* DESCRIPTION PREVIEW */}
          {props.data?.description.text ? (
            <View
              style={{
                // Safari line-clamp fix
                overflow: 'hidden',
              }}
            >
              <Text
                numberOfLines={2}
                style={[
                  styles.descriptionPreview,
                  { color: props.theme === 'light' ? 'black' : 'white' },
                ]}
              >
                {props.data?.description.text}
              </Text>
            </View>
          ) : null}

          {/* APPLICATIONS DUE */}
          {shouldDisplayApplicationDeadline ? (
            <HorizontalContainer
              style={{
                marginBottom: 2,
              }}
            >
              <FontAwesome5
                name="calendar-alt"
                size={17}
                color={props.theme === 'light' ? 'black' : 'white'}
              />
              <Text
                style={[
                  styles.applyBeforeText,
                  { color: props.theme === 'light' ? 'black' : 'white' },
                ]}
              >
                Apply before{' '}
                {dayjs(props.data?.application_deadline).format('MMM D')}
              </Text>
            </HorizontalContainer>
          ) : null}
        </View>

        {props.smallThumbnail ? (
          <Image
            source={{
              uri: getCDNImageUri({
                uri: props.data?.media?.[0],
                isThumbnail: true,
                dimensions: {
                  width: 300,
                  height: 150,
                },
              }),
            }}
            style={styles.smallThumbnail}
          />
        ) : null}
      </View>

      {/* CREATOR */}
      <Pressable
        onPress={() => {
          navigate('Profile', {
            id: props.data.created_by.id,
          });
        }}
      >
        <HorizontalContainer>
          <Avatar
            rounded
            size={15}
            source={{ uri: props.data.created_by.profile_image }}
          />
          <Text
            style={[
              styles.projectCreatorName,
              { color: props.theme === 'light' ? 'black' : 'white' },
            ]}
            numberOfLines={1}
          >
            {props.data.created_by.name}
          </Text>
        </HorizontalContainer>
      </Pressable>

      {/* COLLABORATORS */}
      {props.data.collaborators.items.length > 0 ? (
        <HorizontalContainer>
          {props.data.collaborators.items
            .slice(0, 8)
            .map((collaborator, index) => {
              return (
                <Avatar
                  key={collaborator.user.id}
                  rounded
                  size={15}
                  source={{ uri: collaborator.user.profile_image }}
                  containerStyle={{
                    marginLeft: index === 0 ? 0 : -10,
                  }}
                />
              );
            })}
          <Text
            style={[
              styles.projectCreatorName,
              {
                marginLeft: 3,
                color: props.theme === 'light' ? 'black' : 'white',
              },
            ]}
          >
            {props.data.collaborators.total} collaborator
            {props.data.collaborators.total === 1 ? '' : 's'}
          </Text>
        </HorizontalContainer>
      ) : null}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 300,
    overflow: 'hidden',
  },
  thumbnail: {
    width: '100%',
    height: 100,
    backgroundColor: 'gray',
    borderRadius: 6,
  },
  smallThumbnail: {
    width: 80,
    height: 80,
    backgroundColor: 'gray',
    borderRadius: 6,
    marginLeft: 8,
  },
  title: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 19,
    fontFamily: 'LeagueSpartan-Bold',
    marginTop: 5,
  },
  projectCreatorName: {
    fontFamily: 'Lato-Bold',
    fontSize: 13,
    marginLeft: 3,
    color: KEY_LIGHT_GRAY,
  },
  descriptionPreview: {
    overflow: 'hidden',
    fontFamily: 'Lato',
    color: KEY_LIGHT_GRAY,
    fontSize: 13,
    transform: [
      {
        translateY: -3,
      },
    ],
  },
  creativeTypeButton: {
    padding: 4,
    paddingHorizontal: 4,
    marginBottom: 0,
    minHeight: 0,
    marginRight: 2,
    alignSelf: 'flex-start',
  },
  creativeTypeButtonText: {
    fontFamily: 'Lato-Bold',
    letterSpacing: 1,
    textAlign: 'center',
    color: 'black',
    fontSize: 11,
  },
  applyBeforeText: {
    marginLeft: 3,
    fontFamily: 'Lato-Bold',
    color: 'white',
    fontSize: 13,
  },
  statusText: {
    fontFamily: 'Lato-Bold',
    fontSize: 13,
    color: KEY_LIGHT_GRAY,
    textTransform: 'uppercase',
  },
});
