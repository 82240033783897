import { EventType } from '/generated/graphql';

export const eventTypeToLabel = (type: EventType): string => {
  switch (type) {
    case EventType.Online:
      return 'Online';
    case EventType.Hybrid:
      return 'Hybrid';
    case EventType.InPerson:
      return 'In Person';
    default:
      return type;
  }
};

export const eventTypesToLabels = (types: EventType[]): string => {
  return types.map(eventTypeToLabel).join(', ');
};
