import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import CampaignFlatList from '../../CampaignFlatList/CampaignFlatList';
import { useCommonStyles } from '../styles';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import GridList from '/components/GridList';
import { KEY_GRAY, KEY_LIGHT_GRAY, SECTION_CONTAINER } from '/constants';
import { UserProfileFragmentFragment } from '/generated/graphql';

interface IUser extends Pick<UserProfileFragmentFragment, 'supporters_past'> {}

interface IClosedCampaignsProps {
  isEditing: boolean;
  userId: string | undefined;
  data: IUser | undefined | null;
}

export default function ClosedCampaigns(props: IClosedCampaignsProps) {
  const { push } = useNavigation<StackNavigationProp<any>>();

  const [shouldRender, setShouldRender] = useState(false);

  const { styles: commonStyles } = useCommonStyles();

  const onViewClosedCampaigns = () => {
    push('ListCampaigns', {
      page: 'profile',
      filter: {
        userId: props.userId,
        closed: true,
      },
    });
  };

  function goToProfile(id: string) {
    if (!id) return;

    push('Profile', { id });
  }

  return (
    <View
      style={[styles.container, { display: shouldRender ? 'flex' : 'none' }]}
    >
      <View
        style={[
          SECTION_CONTAINER,
          { flex: undefined, opacity: props.isEditing ? 0.6 : 1 },
        ]}
      >
        <Text style={commonStyles('sectionTitle')}>OUR CLOSED CAMPAIGNS</Text>
        <Text style={commonStyles('sectionText')}>
          {props.isEditing
            ? 'Your past campaigns will appear here...'
            : 'Check out our past campaigns'}
        </Text>
        {props.isEditing ? null : (
          <Button
            containerStyle={{ marginTop: 12 }}
            style={styles.exploreButton}
            labelStyle={styles.exploreButtonLabel}
            label="EXPLORE CAMPAIGNS"
            onPress={onViewClosedCampaigns}
          />
        )}

        {props.data?.supporters_past?.items.length ? (
          <View
            style={[
              SECTION_CONTAINER,
              {
                marginHorizontal: 0,
                backgroundColor: KEY_LIGHT_GRAY,
              },
            ]}
          >
            <View style={commonStyles('horizontalContainer')}>
              <Ionicons
                style={{
                  transform: [{ rotate: '-45deg' }],
                }}
                name="rocket-outline"
                size={28}
              />
              <Text style={commonStyles('sectionTitle')}>
                PREVIOUSLY EMPOWERED
              </Text>
            </View>
            <Text style={commonStyles('sectionText')}>
              These are the supporters that have empowered our past campaigns
            </Text>

            <GridList
              data={props.data?.supporters_past?.items}
              maxTileWidth={128}
              renderItem={({ item, tileWidth }) => (
                <Pressable
                  onPress={() => {
                    goToProfile(item.id);
                  }}
                  style={commonStyles('supporterTile')}
                >
                  <Avatar
                    source={{ uri: item.profile_image ?? '' }}
                    rounded
                    size={tileWidth - 12}
                  />
                  <Text style={commonStyles('supporterName')}>
                    {item?.name}
                  </Text>
                </Pressable>
              )}
            />

            {props.data.supporters_past?.nextToken ? (
              <Text style={commonStyles('moreSupportersText')}>
                +
                {(props.data.supporters_past.total ?? 0) -
                  props.data.supporters_past.items.length}{' '}
                MORE
              </Text>
            ) : null}
          </View>
        ) : null}
      </View>
      {props.isEditing ? null : (
        <CampaignFlatList
          page="profile"
          onLoad={(data) => {
            let _shouldRender = false;
            if (data.getCampaigns.total) _shouldRender = true;

            if (shouldRender !== _shouldRender) setShouldRender(_shouldRender);
          }}
          disableViewportTracker
          filter={{
            userId: props.userId,
            closed: true,
          }}
          scrollable={false}
          limit={3}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
  },
  exploreButton: {
    backgroundColor: KEY_GRAY,
  },
  exploreButtonLabel: {
    color: 'white',
    paddingVertical: 8,
  },
});
