import React from 'react';
import { Text, TextStyle, View, ViewStyle } from 'react-native';
import Expertise from './elements/Expertise';
import Languages from './elements/Languages';
import NextSteps from './elements/NextSteps';
import OurContributionForm from './elements/OurContributionForm';
import ProjectGoalsForm from './elements/ProjectGoalsForm';
import Accordion from '/components/Accordion/Accordion';
import SkillButton from '/components/SkilledImpact/SkillButton';
import { ValidatedFormFields } from '/components/ValidatedForm/ValidatedForm';
import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';
import { SkillRequest, SkillRequestGoal } from '/generated/graphql';
import Sync from '/assets/jsicons/bottomnavigation/Sync';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';

export interface ISkillRequest
  extends Pick<
    SkillRequest,
    'expertise_required' | 'expertise' | 'our_contribution' | 'skill' | 'goals'
  > {}

type SkillDescriptionFormProps = {
  containerStyle?: ViewStyle;
  headerTextStyle?: TextStyle;
  fields: ValidatedFormFields;
  skill: ISkillRequest;
  accordionContentContainerStyle?: ViewStyle;
  initiallyExpanded?: boolean;
  onChange: (data: Partial<ISkillRequest>) => void;
};

const SkillDescriptionForm = (props: SkillDescriptionFormProps) => {
  const onChangeProjectGoals = (projectGoals: SkillRequestGoal[]) => {
    props.onChange({
      goals: projectGoals,
    });
  };

  const onChangeOurContribution = (ourContribution: string) => {
    props.onChange({
      our_contribution: ourContribution,
    });
  };

  return (
    <Accordion
      titleStyle={props.headerTextStyle}
      icon={<Sync />}
      expanded={props.initiallyExpanded ?? true}
      headerStyle={[styles.accordionHeader, props.containerStyle]}
      contentContainerStyle={[
        styles.accordionContentContainer,
        props.accordionContentContainerStyle,
      ]}
      title={
        <HorizontalContainer>
          <Text style={styles.accordionHeaderText}>Skill Needed</Text>
          <View
            style={{
              paddingVertical: 4,
              marginLeft: 6,
            }}
          >
            <SkillButton skill={props.skill.skill ?? ''} selected disabled />
          </View>
        </HorizontalContainer>
      }
    >
      <View style={styles.bigContainer}>
        <Languages
          fields={props.fields}
          skill={props.skill}
          onChange={props.onChange}
        />
        <Expertise
          fields={props.fields}
          skill={props.skill}
          onChange={props.onChange}
        />
        <ProjectGoalsForm
          fields={props.fields}
          projectGoals={props.skill.goals ?? []}
          onChangeProjectGoals={onChangeProjectGoals}
        />
        <OurContributionForm
          ourContribution={props.skill.our_contribution || ''}
          onChangeContribution={onChangeOurContribution}
        />
        <NextSteps
          data={props.skill}
          fields={props.fields}
          onChange={props.onChange}
        />
      </View>
    </Accordion>
  );
};

export default SkillDescriptionForm;
