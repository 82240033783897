import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import { FlatList } from 'react-native';
import Button from '/components/Button';
import GenericError from '/components/common/Generic/GenericError';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionContainer from '/components/common/Section/SectionContainer';
import SectionTitle from '/components/common/Section/SectionTitle';
import SectionTitleIconContainer from '/components/common/Section/SectionTitleIconContainer';
import EventCard from '/components/Events/EventCard/EventCard';
import ListLoading from '/components/ListLoading';
import GenericListEmptyComponent from '/components/common/Generic/GenericListEmptyComponent';
import SearchBar from '/components/SearchBar/SearchBar';
import { KEY_GRAY, TEXT_INPUT } from '/constants';
import { EventFilterInput, useListEventsQuery } from '/generated/graphql';
import useDebouncedState from '/hooks/useDebouncedState';
import EventFilter from '/components/Events/EventFilter/EventFilter';

export default function DiscoverEvents() {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [query, queryDebounced, setQuery] = useDebouncedState('');
  const [filter, setFilter] = useState<EventFilterInput>();

  const [{ data, fetching, stale, error }, refetch] = useListEventsQuery({
    variables: {
      filter: {
        query: queryDebounced,
        ...filter,
      },
    },
    requestPolicy: 'cache-and-network',
  });

  const onSeeMorePressed = () => {
    navigate('ListEvents', {
      q: query,
    });
  };

  return (
    <SectionContainer>
      <HorizontalContainer style={{ marginBottom: 8 }}>
        <SectionTitleIconContainer>
          <FontAwesome5 name="calendar-alt" size={24} color="black" />
        </SectionTitleIconContainer>
        <SectionTitle style={{ marginBottom: 0 }}>EVENTS</SectionTitle>
      </HorizontalContainer>

      <SearchBar
        containerStyle={{
          ...TEXT_INPUT,
          height: 'auto',
          minHeight: 36,
          marginBottom: 8,
          marginHorizontal: 0,
        }}
        loading={fetching || stale}
        onChangeText={setQuery}
      />

      <EventFilter
        filter={filter}
        onChange={setFilter}
        style={{
          marginBottom: 8,
        }}
      />

      {!data?.listEvents && fetching ? (
        <ListLoading />
      ) : !data?.listEvents && error ? (
        <GenericError onRetry={refetch} />
      ) : data?.listEvents?.items.length === 0 ? (
        <GenericListEmptyComponent placeholderText="No events" />
      ) : (
        <FlatList
          horizontal
          contentContainerStyle={{
            paddingBottom: 8,
          }}
          data={data?.listEvents?.items}
          renderItem={({ item }) => {
            return (
              <EventCard
                style={{
                  width: 280,
                  marginRight: 8,
                  height: '100%',
                }}
                key={item.id}
                event={item}
              />
            );
          }}
        />
      )}

      {data?.listEvents.nextToken ? (
        <Button
          style={{
            backgroundColor: KEY_GRAY,
          }}
          containerStyle={{
            marginTop: 8,
          }}
          labelStyle={{
            color: 'white',
          }}
          label="SEE MORE"
          onPress={onSeeMorePressed}
        />
      ) : null}
    </SectionContainer>
  );
}
