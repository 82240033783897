import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import SkillBadge from '../SkilledImpact/elements/SkillBadge';
import styles from '/constants/TakeAction/TakeActionCallToAction';
import { useAuthContext } from '/context';
import {
  Campaign,
  CampaignImpactReferralInput,
  DonationRequest,
  SkillRequest,
  SkillRequestApplication,
  VolunteerRequest,
} from '/generated/graphql';
import { measureDistanceBetweenCoordinates } from '/util';
import * as Localization from 'expo-localization';
import { KEY_GRAY, KEY_YELLOW } from '/constants';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles';
import { AntDesign, Feather } from '@expo/vector-icons';
import Alert from '/Alert';
import dayjs from 'dayjs';
import ChevronRight from '/assets/jsicons/miscIcons/ChevronRight';
import ProgressBar from '../ProgressBar/ProgressBar';
import { Currency, getDecimalPlacesForCurrency } from 'currency-decimal-places';
import {
  getForegroundPermissionsAsync,
  getLastKnownPositionAsync,
  LocationObjectCoords,
} from 'expo-location';

const useStyles = CreateResponsiveStyle({}, {});

export interface IDonationRequest
  extends Pick<DonationRequest, 'id' | 'total_donated' | 'total_goal'> {}

export interface IVolunteerRequest
  extends Pick<
    VolunteerRequest,
    'id' | 'location' | 'latitude' | 'longitude'
  > {}

export interface ISkilledImpactRequest
  extends Pick<SkillRequest, 'id' | 'skill'> {
  my_application?: Pick<SkillRequestApplication, 'id'> | null | undefined;
}

export interface ITakeActionCallToActionCampaign
  extends Pick<Campaign, 'id' | 'support_needed_by_date'> {
  donation_request?: IDonationRequest | null | undefined;
  volunteer_request?: IVolunteerRequest | null | undefined;
  skilled_impact_requests: ISkilledImpactRequest[] | null | undefined;
}

interface ITakeActionCallToActionProps {
  data: ITakeActionCallToActionCampaign | undefined | null;
  referral: CampaignImpactReferralInput | undefined;
}

export default function TakeActionCallToAction(
  props: ITakeActionCallToActionProps,
) {
  const { deviceSize } = useStyles();

  const { navigate } = useNavigation<any>();
  const { userData } = useAuthContext();

  const [skilledImpactRequest, setSkilledImpactRequest] = useState(
    props?.data?.skilled_impact_requests?.[0],
  );

  useEffect(() => {
    setSkilledImpactRequest(props?.data?.skilled_impact_requests?.[0]);
  }, [props.data?.skilled_impact_requests]);

  function onPressSkilledImpact() {
    if (!skilledImpactRequest?.id) return;

    navigate('GiveSkills', {
      skillRequestId: skilledImpactRequest.id,
      refCCProjectId: props.referral?.referredByCreativeConnectProjectId,
    });
  }

  function onPressVolunteer() {
    if (!props?.data?.volunteer_request?.id) return;

    navigate('VolunteerScreen', {
      requestId: props.data.volunteer_request.id,
      refCCProjectId: props.referral?.referredByCreativeConnectProjectId,
    });
  }

  function onPressDonate() {
    if (!props?.data?.donation_request?.id) return;

    navigate('DonateScreen', {
      campaignId: props.data.id,
      refCCProjectId: props.referral?.referredByCreativeConnectProjectId,
    });
  }

  const [lastKnownLocation, setLastKnownLocation] =
    useState<LocationObjectCoords>();

  const hasCheckedLocation = useRef(false);
  useEffect(() => {
    if (hasCheckedLocation.current) return;

    hasCheckedLocation.current = true;

    getForegroundPermissionsAsync().then(({ status }) => {
      if (status !== 'granted') return;

      getLastKnownPositionAsync({}).then((location) => {
        setLastKnownLocation(location?.coords);
      });
    });
  }, []);

  const volunteerRequestDistanceKm = useMemo(() => {
    if (
      !props.data?.volunteer_request?.latitude ||
      !props.data?.volunteer_request?.longitude
    )
      return null;

    const location = lastKnownLocation ?? userData?.locations?.[0];

    if (
      typeof location?.latitude !== 'number' ||
      typeof location?.longitude !== 'number'
    )
      return null;

    const distanceKm = measureDistanceBetweenCoordinates(
      location.latitude,
      location.longitude,
      props.data.volunteer_request.latitude,
      props.data.volunteer_request.longitude,
    );

    return distanceKm;
  }, [
    lastKnownLocation,
    props.data?.volunteer_request?.latitude,
    props.data?.volunteer_request?.longitude,
    userData?.locations,
  ]);

  const isNearby =
    /** Less than 80km/50mi, is nearby */
    volunteerRequestDistanceKm && volunteerRequestDistanceKm < 80;

  const volunteerRequestDistanceText = useMemo(() => {
    if (!volunteerRequestDistanceKm)
      return props.data?.volunteer_request?.location;

    /** Determine which measurement system to use */
    let measurementSystem: 'metric' | 'imperial' = 'metric';

    const locale = Localization.getLocales()[0];

    /** On iOS and Android, measurementSystem might be specified here */
    if (
      locale.measurementSystem &&
      locale.measurementSystem !== 'metric' &&
      locale.measurementSystem !== 'uk'
    ) {
      measurementSystem = 'imperial';
    } else {
      /** On web, infer system using regionCode */
      switch (locale.regionCode) {
        /** US, Libera, and Myanmar still officially use the imperial system */
        case 'US':
        case 'LR':
        case 'MM':
          measurementSystem = 'imperial';
      }
    }

    const distance =
      measurementSystem === 'metric'
        ? volunteerRequestDistanceKm
        : volunteerRequestDistanceKm * 0.621371;

    return `${distance.toFixed(1)} ${
      measurementSystem === 'metric' ? 'km' : 'mi'
    } away`;
  }, [volunteerRequestDistanceKm, props.data?.volunteer_request?.location]);

  function informVolunteerLocation() {
    let message = `This widget is using ${
      lastKnownLocation
        ? "your device's location"
        : 'the location on your profile'
    } to calculate the distance to the event.`;

    if (!lastKnownLocation && !userData?.locations.length) {
      message =
        'Add your location to your profile to see distance to this event.';
    }

    Alert.alert('Event Location', message);
  }

  const itemWidth = useMemo(() => {
    let totalButtons = 0;

    if (skilledImpactRequest) totalButtons++;
    if (props.data?.volunteer_request) totalButtons++;

    if (minSize(DEVICE_SIZES.MEDIUM_DEVICE).includes(deviceSize)) {
      return totalButtons > 1 ? '50%' : '100%';
    } else {
      return '100%';
    }
  }, [deviceSize, skilledImpactRequest, props.data?.volunteer_request]);

  const DueDate = useMemo(() => {
    return (
      <View style={styles.dueDateContainer}>
        <Feather
          name="clock"
          size={15}
          color="white"
          style={{
            marginRight: 4,
          }}
        />
        <Text style={styles.buttonDueDate}>
          {dayjs(props.data?.support_needed_by_date as any).format('MMM DD')}
        </Text>
      </View>
    );
  }, [props.data?.support_needed_by_date]);

  const currency =
    props.data?.donation_request?.total_donated?.currency ?? 'USD';
  const decimalPlaces =
    getDecimalPlacesForCurrency(currency.toUpperCase() as Currency) ?? 2;

  const totalDonated = Math.round(
    (props.data?.donation_request?.total_donated?.amount ?? 0) /
      10 ** decimalPlaces,
  );

  return (
    <>
      {skilledImpactRequest && (
        <View style={[styles.takeActionContainer, { width: itemWidth }]}>
          <TouchableOpacity
            onPress={onPressSkilledImpact}
            style={styles.takeActionButton}
          >
            <Text style={styles.title}>SKILLS NEEDED</Text>
            <View style={styles.takeActionButtonInner}>
              <SkillBadge
                color={
                  skilledImpactRequest.my_application?.id
                    ? '#E8F9A2'
                    : '#D7FE43'
                }
                textStyle={{
                  fontSize: 15,
                }}
                style={{
                  marginVertical: 0,
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 12,
                  padding: 6,
                }}
                skill={skilledImpactRequest.skill as string}
              />
              {DueDate}
              <ChevronRight style={{ alignSelf: 'center' }} />
            </View>
          </TouchableOpacity>
        </View>
      )}
      {!!props.data?.volunteer_request && (
        <View style={[styles.takeActionContainer, { width: itemWidth }]}>
          <TouchableOpacity
            onPress={onPressVolunteer}
            style={styles.takeActionButton}
          >
            <Text style={styles.title}>IN-PERSON</Text>
            <View style={styles.takeActionButtonInner}>
              <View
                style={[
                  styles.volunteerDistanceContainer,
                  {
                    backgroundColor: isNearby ? KEY_YELLOW : KEY_GRAY,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.volunteerDistanceText,
                    {
                      color: isNearby ? 'black' : 'white',
                    },
                  ]}
                  numberOfLines={3}
                >
                  {volunteerRequestDistanceText}
                </Text>
                <AntDesign
                  onPress={informVolunteerLocation}
                  name="infocirlce"
                  size={14}
                  color="white"
                  style={{
                    paddingHorizontal: 6,
                  }}
                />
              </View>
              {DueDate}
              <ChevronRight style={{ alignSelf: 'center' }} />
            </View>
          </TouchableOpacity>
        </View>
      )}

      {!!props.data?.donation_request && (
        <View style={[styles.takeActionContainer, { width: itemWidth }]}>
          <TouchableOpacity
            onPress={onPressDonate}
            style={styles.takeActionButton}
          >
            <Text style={styles.title}>FUNDING</Text>
            <View style={styles.takeActionButtonInner}>
              {/* Progress bar */}
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: KEY_GRAY,
                    fontFamily: 'Lato',
                    marginBottom: 4,
                    textAlign: 'center',
                  }}
                >
                  {`${Math.round(
                    ((totalDonated ?? 0) /
                      (props.data.donation_request.total_goal?.amount ?? 1)) *
                      100,
                  )}% of total goal reached`}
                </Text>
                <View
                  style={{
                    flex: 1,
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <ProgressBar
                    style={{
                      flex: 1,
                      marginHorizontal: 12,
                    }}
                    decimalCount={decimalPlaces}
                    goal={props.data.donation_request.total_goal?.amount ?? 0}
                    currencySymbol={getSymbolFromCurrency(
                      props.data.donation_request.total_goal?.currency ?? 'USD',
                    )}
                    progress={totalDonated}
                  />

                  <ChevronRight style={{ alignSelf: 'center' }} />
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      )}
    </>
  );
}
