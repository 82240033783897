import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import CreativeConnectProjectCard, {
  ICreativeConnectProject,
} from '../CreativeConnectProjectCard/CreativeConnectProjectCard';
import GenericListFooter from '/components/GenericListFooter';
import GridList from '/components/GridList';
import ListLoading from '/components/ListLoading';
import GenericListEmptyComponent from '/components/common/Generic/GenericListEmptyComponent';
import GenericError from '/components/common/Generic/GenericError';
import { KEY_GRAY } from '/constants';
import {
  CreativeConnectProjectFilter,
  useListCreativeConnectProjectsQuery,
} from '/generated/graphql';

type Props = {
  style?: StyleProp<ViewStyle>;
  filter?: CreativeConnectProjectFilter;
  emptyPlaceholderText?: string;
  limit?: number;
  tileWidth?: number;
  theme?: 'dark' | 'light';
  endReached?: boolean;
  onFetchingChanged?: (value: boolean) => void;
  onSelectProject?: (project: ICreativeConnectProject) => void;
};

const DEFAULT_TILE_WIDTH = 240;

export default function CreativeConnectProjectGridList({
  onFetchingChanged,
  ...props
}: Props) {
  const [nextToken, setNextToken] = useState<string>();

  const [{ data, fetching, stale, error }, refetch] =
    useListCreativeConnectProjectsQuery({
      variables: {
        filter: props.filter ?? {},
        limit: props.limit,
        nextToken,
      },
      requestPolicy: 'cache-and-network',
    });

  useEffect(() => {
    onFetchingChanged?.(fetching);
  }, [onFetchingChanged, fetching]);

  const endReachedRef = useRef(props.endReached);

  const onEndReached = useCallback(
    function () {
      // Set timeout for 60ms to give parent component
      // a chance to set endReached to false
      setTimeout(() => {
        if (
          !endReachedRef.current ||
          !data?.listCreativeConnectProjects.nextToken
        )
          return;
        setNextToken(data.listCreativeConnectProjects.nextToken);
      }, 60);
    },
    [data?.listCreativeConnectProjects.nextToken],
  );

  useEffect(() => {
    const didChange = endReachedRef.current !== props.endReached;
    endReachedRef.current = props.endReached;
    if (didChange && props.endReached) {
      onEndReached();
    }
  }, [onEndReached, props.endReached]);
  useEffect(() => {}, [data?.listCreativeConnectProjects.nextToken]);

  return (
    <View style={props.style}>
      {!data && fetching ? (
        <ListLoading color={props.theme === 'dark' ? 'white' : KEY_GRAY} />
      ) : !data && error ? (
        <GenericError onRetry={refetch} />
      ) : !data?.listCreativeConnectProjects.items.length ? (
        <GenericListEmptyComponent
          style={{
            backgroundColor: props.theme === 'dark' ? 'transparent' : 'white',
          }}
          tintColor={props.theme === 'dark' ? 'gray' : KEY_GRAY}
          placeholderText={props.emptyPlaceholderText ?? 'No results'}
        />
      ) : (
        <>
          <GridList
            data={data?.listCreativeConnectProjects.items}
            largeContainerTileWidthMultiplier={1.5}
            maxTileWidth={DEFAULT_TILE_WIDTH ?? props.tileWidth}
            keyExtractor={({ item }) => item.id}
            tileContainerStyle={{
              paddingRight: 8,
            }}
            style={{
              marginHorizontal: 4,
            }}
            renderItem={({ item, tileWidth }) => {
              return (
                <CreativeConnectProjectCard
                  data={item}
                  theme={props.theme}
                  displayCreativeTypeAndStatus
                  onPress={
                    typeof props.onSelectProject === 'function'
                      ? (e) => {
                          e.shouldOverrideDefault = true;
                          props.onSelectProject?.(item);
                        }
                      : undefined
                  }
                  style={{
                    width: tileWidth - 8,
                    marginBottom: 10,
                  }}
                />
              );
            }}
          />
          <GenericListFooter
            emptyHeight={0}
            activityIndicatorColor={props.theme === 'dark' ? 'white' : KEY_GRAY}
            hasMore={!!data?.listCreativeConnectProjects.nextToken}
            isFirstPage={!nextToken}
            loading={fetching || stale}
            onFetchMore={() =>
              data?.listCreativeConnectProjects.nextToken &&
              setNextToken(data?.listCreativeConnectProjects.nextToken)
            }
          />
        </>
      )}
    </View>
  );
}
