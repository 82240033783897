import { UpdateResolver } from '@urql/exchange-graphcache';

const deleteCreativeConnectScheduledPremiereNotification: UpdateResolver = (
  result,
  args,
  cache,
) => {
  const projectId = args?.projectId as any;

  cache.link(
    {
      __typename: 'CreativeConnectProject',
      id: projectId,
    },
    'scheduled_premiere_notification',
    null,
  );
};

export default deleteCreativeConnectScheduledPremiereNotification;
