import { StackNavigationProp } from '@react-navigation/stack';
import {
  GroupMembershipStatus,
  Notification,
  NotificationType,
  PartnershipStatus,
} from '/generated/graphql';
import { DeepPartial } from '/types';

// Handles pressing on a notification, both in NotificationsMain as well as
// when pressing a push notification from outside the app.
export const handleNotificationPress = (
  notification: DeepPartial<Notification>,
  userId: string,
  navigate: StackNavigationProp<any>['navigate'],
) => {
  if (!notification) return;

  const data: { [key: string]: any } | undefined =
    notification.data && JSON.parse(notification.data);

  switch (notification.type) {
    case NotificationType.EventDateChange:
    case NotificationType.EventAttendanceConfirmed:
    case NotificationType.EventPartnershipInviteAccepted:
    case NotificationType.EventAttendanceInvite:
    case NotificationType.EventPartnershipInvite: {
      if (data?.eventId) {
        navigate('ViewEvent', { id: data.eventId, vUId: userId });
      }
      break;
    }
    case NotificationType.CreativeConnectCollaboratorRoleChanged:
    case NotificationType.CreativeConnectProjectPremiered:
    case NotificationType.CreativeConnectProjectFeature:
    case NotificationType.CreativeConnectProjectInviteDeclined:
    case NotificationType.CreativeConnectProjectInvite:
    case NotificationType.CreativeConnectProjectInviteAccepted:
    case NotificationType.CreativeConnectProjectJoinRequestAccepted:
    case NotificationType.CampaignConnectInviteCampaignAccepted: {
      if (data?.creativeConnectProjectId || data?.ccProjectId) {
        navigate('ViewCreativeConnectLanding', {
          id: data.creativeConnectProjectId || data.ccProjectId,
          vUId: userId,
        });
      }
      break;
    }

    case NotificationType.CreativeConnectProjectJoinRequest:
      if (data?.ccProjectId)
        navigate('ListCreativeConnectCollaborators', {
          id: data.ccProjectId,
          confirmed: false,
          collaborator_initiated: true,
        });
      break;
    case NotificationType.CommentReply:
      if (data?.interactionsId) {
        navigate('Comments', {
          interactionsId: data.interactionsId,
          commentId: data.commentId,
          replyId: data.replyId,
        });
      }
      break;
    case NotificationType.UserMentionCampaignPost:
      if (data?.postId) {
        navigate('Campaign', {
          postId: data.postId,
        });
      }
      break;
    case NotificationType.UserMentionComment:
      if (data?.interactionsId) {
        const params: any = {
          interactionsId: data.interactionsId,
          commentId: data.commentId,
        };

        if (data.replyId) {
          params.replyId = data.replyId;
        }

        navigate('Comments', params);
      }
      break;
    case NotificationType.SharedDocumentSubmitted:
      if (data?.groupDiscussionPostId) {
        navigate('GroupDiscussionPost', {
          id: data.groupDiscussionPostId,
        });
      }
      break;
    case NotificationType.GroupMembershipRequestReceived:
      if (!data?.groupId) return;
      navigate('GroupMembers', {
        id: data.groupId,
        status: GroupMembershipStatus.RequestedByUser,
      });
      break;
    case NotificationType.GroupMembershipInviteAccepted:
    case NotificationType.GroupMembershipRequestAccepted:
      if (!data?.groupId) return;
      navigate('ViewGroup', {
        id: data.groupId,
      });
      break;
    case NotificationType.NewDonation:
      if (!data?.campaignId) return;
      navigate('ListCampaignDonations', {
        campaignId: data?.campaignId,
      });
      break;
    case NotificationType.DepositMethodAccepted:
    case NotificationType.DepositMethodRejected:
    case NotificationType.DepositMethodActionRequired: {
      navigate('DonationSettings');
      break;
    }
    case NotificationType.CampaignHidden:
    case NotificationType.CampaignUnhidden:
    case NotificationType.ResearchTopicCampaignEngagement:
    case NotificationType.SkillRequestEngagement: {
      if (!data) return;

      navigate('Campaign', {
        campaignId: data.campaignId,
      });
      break;
    }
    case NotificationType.NewOrganizationApplication: {
      navigate('Organizations');
      break;
    }
    case NotificationType.FirstVolunteerRegistered:
    case NotificationType.VolunteerRequestFilled: {
      if (!data?.volunteerRequestId) return;
      navigate('ManageVolunteerRequest', {
        requestId: data.volunteerRequestId,
      });
      break;
    }
    case NotificationType.NewVolunteerOpportunity:
    case NotificationType.VolunteerRequestDateApproaching: {
      if (!data) return;
      navigate('VolunteerScreen', {
        requestId: data.volunteerRequestId,
      });
      break;
    }
    case NotificationType.PublicSpeciesTopFactsDeleted:
    case NotificationType.PublicSpeciesMediaDeleted: {
      // Take user to their profile
      navigate('ProfileStack');
      break;
    }
    case NotificationType.UserMentionDiscussionBoardMessage:
    case NotificationType.NewMessage: {
      if (!data) return;
      if (data.viewPage === 'creative-connect-collaborator-discussion')
        navigate('CreativeConnectDashboard', {
          id: data.creativeConnectProjectId || data.ccProjectId,
          vUId: userId,
          tab: 'chat',
        });
      else
        navigate('ViewDiscussionBoard', {
          id: data.discussionBoardId,
          disableTeamMessaging: userId === notification.userId,
        });
      break;
    }
    case NotificationType.NewBroadcastMessage:
    case NotificationType.NewDirectMessage: {
      if (!notification.avatar?.id || notification.userId !== userId) return;
      navigate('DirectMessageScreen', {
        userId: notification.avatar?.id,
        isBroadcast: notification.type === NotificationType.NewBroadcastMessage,
      });
      break;
    }
    case NotificationType.TeamMembershipInviteReceived:
    case NotificationType.TeamMembershipRequestReceived: {
      navigate('ViewPendingTeamMemberships', {});
      break;
    }
    case NotificationType.TeamMembershipInviteConfirmed: {
      if (!notification) return;
      navigate('ManageTeam');
      break;
    }
    case NotificationType.NewSkillRequestApplicant: {
      if (!data) return;

      navigate('ViewApplicantDetails', {
        applicationId: data.finishedApplication,
      });
      break;
    }
    case NotificationType.NewComment: {
      if (!data || !data.interactionsId) return;

      const params: any = {
        interactionsId: data.interactionsId,
      };

      if (data.commentId) params.commentId = data.commentId;

      navigate('Comments', params);
      break;
    }
    case NotificationType.SkillRequestApplicationSelectionConfirmed: {
      if (!data) return;
      navigate('ViewApplicantDetails', {
        applicationId: data.applicationId,
      });
      break;
    }
    case NotificationType.SkillRequestApplicationSelectionDeclined: {
      if (!data) return;
      navigate('ViewApplicantDetails', {
        applicationId: data.applicationId,
      });
      break;
    }
    case NotificationType.CampaignUpdate: {
      if (!data) return;
      navigate('Campaign', {
        campaignId: data.campaignId,
      });
      break;
    }
    case NotificationType.NewPartnerRequest: {
      if (!data) return;
      navigate('ViewPartnerships', {
        userId: userId,
        status: PartnershipStatus.Pending,
      });
      break;
    }
    // Supporters:
    case NotificationType.SkillRequestApplicationSelected: {
      if (!data) return;
      navigate('ViewApplicationDetails', {
        applicationId: data.applicationId,
      });
      break;
    }
    case NotificationType.SkillRequestApplicationDeclined: {
      if (!data) return;
      navigate('ViewApplicationDetails', {
        applicationId: data.applicationId,
      });
      break;
    }
    case NotificationType.ConnectionAccepted: {
      navigate('Profile', {
        id: notification.avatar?.id,
      });
      break;
    }
    case NotificationType.ConnectionRequest: {
      navigate('Connections', {
        userId: userId,
      });
      break;
    }
    case NotificationType.SkillRequestSelectionExpired: {
      if (!data) return;
      navigate('ViewApplicationDetails', {
        applicationId: data.applicationId,
      });
      break;
    }
    case NotificationType.SkillRequestApplicantSelectionExpired: {
      if (!data) return;
      navigate('ViewApplicantDetails', {
        applicationId: data.applicationId,
      });
      break;
    }
  }
  return;
};
