import { StatusBarStyle } from 'expo-status-bar';
import { CursorValue, Platform, TextStyle } from 'react-native';

export const STRIPE_FIXED_FEE = 0.3; // In USD
export const STRIPE_PERCENTAGE_FEE = 0.029;

// COLORS
export const KEY_DARK_GREEN = '#088661';
export const KEY_GREEN = '#0EE6A6';
export const KEY_LIGHT_GREEN = '#bfffe7';
export const KEY_YELLOW = '#D7FF43';
export const OFFWHITE = '#f2f2fb';
export const KEY_GRAY = '#323338';
export const KEY_LIGHT_GRAY = '#F5F5F5';
export const ALERT_YELLOW = '#fcf19a';
export const ALERT_RED = '#eb5f50';
export const INFO_GREEN = '#DDF8EE';
export const TEXT_INPUT_PLACEHOLDER_COLOR = '#bebebe';
export const CREATIVE_CONNECT_BACKGROUND_GRAY = '#343239';
export const CREATIVE_CONNECT_BACKGROUND = '#222126';
export const PRIMARY_BUTTON_BACKGROUND = '#eee';

export const ACTIVITY_INDICATOR_DEFAULT = '#999999';

export const DEFAULT_STATUS_BAR_STYLE: StatusBarStyle = 'dark';

// FONT SIZES
export const TITLE_FONT_SIZE = 22;
export const CARD_TITLE_FONT_SIZE = 19;
export const CONTENT_PARAGRAPH_FONT_SIZE = 18;

// SIZES & SPACING
export const SCROLL_VIEW_PADDING_BOTTOM = 10;

// STYLES
export const SECTION_TEXT_STYLE = {
  fontFamily: 'Lato',
  fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
} as const;

export const DESKTOP_MAX_WIDTH = {
  flex: 1,
  width: '100%',
  maxWidth: 640,
  alignSelf: 'center',
} as const;

export const BUTTON_LABEL_STYLE = {
  fontFamily: 'Lato-Bold',
  fontSize: 17,
  textAlign: 'center',
} as const;

export const TEXT_INPUT = {
  height: 36,
  textAlignVertical: 'top' as const,
  backgroundColor: KEY_LIGHT_GRAY,
  marginTop: 2,
  padding: 8,
  borderRadius: 5,
  fontSize: 15,
  fontFamily: 'Lato',
};

export const HYPERLINK_STYLE: TextStyle = {
  fontFamily: 'Lato-Bold',
  color: 'black',
  textDecorationLine: 'underline',
};

export const TEXT_INPUT_LARGE = {
  minHeight: 180,
  textAlignVertical: 'top' as const,
  backgroundColor: '#F5F5F5',
  marginTop: 2,
  padding: 12,
  paddingTop: 8,
  borderRadius: 5,
  fontSize: 16,
  fontFamily: 'Lato',
};

export const BUTTON_SHADOW = {
  shadowColor: KEY_GRAY,
  shadowRadius: 2,
  shadowOpacity: 0.2,
  shadowOffset: {
    height: 2,
    width: 0,
  },
  elevation: 2,
  overflow: 'visible' as 'visible',
};

export const REQUIRED_INPUT_INVALID = {
  borderWidth: 1,
  borderColor: 'crimson',
};

export const SECTION_CONTAINER = {
  backgroundColor: '#fff',
  borderRadius: 6,
  padding: 16,
  marginTop: 10,
  marginHorizontal: 8,
};

export const DEFAULT_TAB = {
  alignItems: 'center',
  justifyContent: 'center',
  borderBottomWidth: 3,
  borderBottomColor: 'white',
  padding: 2,
} as const;

export const DROPDOWN_STYLE = {
  borderWidth: 1,
  borderColor: KEY_LIGHT_GRAY,
  borderRadius: 6,
  marginBottom: 6,
  padding: 8,
  backgroundColor: 'white',
  alignSelf: 'flex-start',
  minWidth: 140,
  ...(Platform.OS === 'web'
    ? {
        // @ts-ignore
        cursor: 'pointer' as CursorValue,
      }
    : {}),
  ...BUTTON_SHADOW,
} as const;

// OTHER CONSTANTS

export const TOTAL_SPECIES = 2787259;

export const UserRoles = {
  conservationist: 'conservationist',
  supporter: 'supporter',
};

export const URGENCY_COLORS = {
  URGENT: '#FFCB2E',
  CRITICAL: '#E41152',
  LONG_TERM: 'rgba(0,255,157,0.6)',
};
