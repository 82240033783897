import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import { ListMyCreativeConnectInvitesDocument } from '/generated/graphql';
import removeItemFromPaginatedField from '/util/cache/removeItemFromPaginatedField';

const ProjectFragment = gql`
  fragment RemoveProjectCollaboratorFragment on CreativeConnectProject {
    id
    collaborators(
      limit: $limit
      nextToken: $nextToken
      confirmed: $confirmed
      initiated_by_collaborator: $initiated_by_collaborator
    ) {
      # total
      items {
        userId
        projectId
      }
    }
  }
`;

const ProjectCollaboratorFragment = gql`
  fragment ProjectCollaboratorFragmentRemoveProjectCollaborator on CreativeConnectProject {
    id
    collaborator(userId: $userId) {
      userId
      projectId
    }
  }
`;

const deleteCreativeConnectCollaborator: UpdateResolver = (
  result,
  args,
  cache,
) => {
  removeItemFromPaginatedField({
    cache,
    entity: {
      __typename: 'CreativeConnectProject',
      id: args.projectId as string,
    },
    paginatedField: {
      name: 'collaborators',
      fragment: ProjectFragment,
    },
    removeItem: {
      userId: args.userId as string,
      projectId: args.projectId as string,
    },
  });

  cache.updateQuery(
    {
      query: ListMyCreativeConnectInvitesDocument,
      variables: { userId: args.userId as string },
    },
    (data: any) => ({
      __typename: 'Query',
      ...data,
      listMyCreativeConnectInvites: {
        __typename: 'PaginatedCreativeConnectProjects',
        ...(data?.listMyCreativeConnectInvites
          ? data.listMyCreativeConnectInvites
          : {}),
        total: Math.max(
          0,
          (data?.listMyCreativeConnectInvites?.total ?? 0) - 1,
        ),
        nextToken: data?.listMyCreativeConnectInvites?.nextToken ?? null,
        items:
          data?.listMyCreativeConnectInvites?.items.filter(
            (item: any) => item.id !== args.projectId,
          ) ?? [],
      },
    }),
  );

  const ARGUMENT_SETS_TUPLE = [undefined, { userId: args.userId }] as const;

  ARGUMENT_SETS_TUPLE.forEach((_args) => {
    const project = cache.readFragment(
      ProjectCollaboratorFragment,
      {
        __typename: 'CreativeConnectProject',
        id: args.projectId as string,
      },
      _args,
    );

    if (project?.collaborator?.userId === args.userId) {
      cache.link(
        {
          __typename: 'CreativeConnectProject',
          id: args.projectId as string,
        },
        'collaborator',
        _args ?? null,
        _args ? null : undefined,
      );
    }
  });

  cache.invalidate({
    __typename: 'CreativeConnectCollaborator',
    userId: args.userId as string,
    projectId: args.projectId as string,
  });
};

export default deleteCreativeConnectCollaborator;
