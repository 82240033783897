import { AntDesign } from '@expo/vector-icons';
import { RouteProp, useLinkTo, useRoute } from '@react-navigation/native';
import React from 'react';
import {
  ActivityIndicator,
  StyleProp,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import Alert from '/Alert';
import Button from '/components/Button';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import { BUTTON_LABEL_STYLE, KEY_GREEN } from '/constants';
import {
  AttendEventMutationVariables,
  EventAttendee,
  EventAttendeeStatus,
  useAttendEventMutation,
  useDeleteEventAttendeeMutation,
} from '/generated/graphql';
import { useAuthContext } from '/context';

interface IEventAttendee extends Pick<EventAttendee, 'status' | 'id'> {}

type Props = {
  eventId: string;
  userId: string | null | undefined;
  attendee: IEventAttendee | null | undefined;
  containerStyle?: StyleProp<ViewStyle>;
  onAttendanceCreated?: () => void;
};

export default function AttendEventButton(props: Props) {
  const { userData } = useAuthContext();

  const [{ fetching: attendLoading }, attendEvent] = useAttendEventMutation();
  const [{ fetching: deleteLoading }, deleteAttendee] =
    useDeleteEventAttendeeMutation();

  const linkTo = useLinkTo();
  const route = useRoute<RouteProp<any>>();

  const loading = attendLoading || deleteLoading;

  async function onPress() {
    if (!props.userId) {
      Alert.alert(
        'Log In',
        'Please log in or create an account to add yourself as an attendee to this event',
        [
          {
            style: 'cancel',
          },
          {
            style: 'default',
            text: 'Sign In',
            onPress: () => {
              let params = '';

              const returnTo = route?.path;

              if (returnTo) {
                params = `?returnto=${encodeURIComponent(returnTo)}`;
              }

              linkTo(`/login${params}`);
            },
          },
        ],
      );
      return;
    }

    async function updateAttendance(action: 'attend' | 'delete') {
      if (!props.eventId || !props.userId) return;
      try {
        let promise;

        if (action === 'delete') {
          if (!props.attendee) {
            throw new Error('Attendee not found');
          }

          promise = deleteAttendee({
            id: props.attendee.id,
          });
        } else {
          const params: AttendEventMutationVariables = {
            eventId: props.eventId,
          };

          if (props.userId !== userData?.id) {
            params.userId = props.userId;
          }

          promise = attendEvent(params);
        }

        const { error } = await promise;

        if (error) {
          throw error;
        }

        Alert.notify({
          message: 'Attendance updated!',
          color: KEY_GREEN,
        });

        if (action === 'attend') {
          props.onAttendanceCreated?.();
        }
      } catch (error) {
        Alert.alert(
          'Oh no!',
          'There was a problem updating your attendance. Please try again.',
        );
        console.error(error);
      }
    }

    if (props.attendee?.status === EventAttendeeStatus.Confirmed) {
      Alert.alert(
        'Are you sure?',
        'Are you sure you want to remove yourself from this event?',
        [
          {
            style: 'cancel',
          },
          {
            style: 'destructive',
            text: 'Remove',
            onPress: () => {
              updateAttendance('delete');
            },
          },
        ],
      );
    } else {
      updateAttendance('attend');
    }
  }

  return (
    <Button
      onPress={onPress}
      disabled={loading}
      label={
        <HorizontalContainer>
          {loading ? (
            <ActivityIndicator
              size={17}
              color="black"
              style={{
                marginRight: 8,
                marginTop: 1,
              }}
            />
          ) : props.attendee?.status === EventAttendeeStatus.Confirmed ? (
            <View
              style={{
                width: 17,
                height: 17,
                marginRight: 8,
                marginTop: 1,
              }}
            >
              {/* Add black background to make check mark black... */}
              <View
                style={{
                  position: 'absolute',
                  top: 2,
                  bottom: 2,
                  right: 2,
                  left: 2,
                  borderRadius: 50,
                  overflow: 'hidden',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: -1,
                  elevation: -1,
                }}
              >
                <View
                  style={{
                    backgroundColor: 'black',
                    width: 17,
                    height: 17,
                    borderRadius: 17,
                    zIndex: -99,
                  }}
                />
              </View>
              <AntDesign name="checkcircle" color={KEY_GREEN} size={17} />
            </View>
          ) : (
            <AntDesign
              name="pluscircleo"
              size={16}
              color="black"
              style={{
                marginRight: 8,
                marginTop: 2,
              }}
            />
          )}
          <Text style={BUTTON_LABEL_STYLE}>
            {props.attendee?.status === EventAttendeeStatus.Confirmed
              ? 'Attending'
              : 'Attend'}
          </Text>
        </HorizontalContainer>
      }
      containerStyle={props.containerStyle}
    />
  );
}
