import { useLinkTo } from '@react-navigation/native';
import React from 'react';
import { Text, useWindowDimensions, View } from 'react-native';
import Button from '/components/Button';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import ScrollView from '/components/common/ScrollView/ScrollView';
import { useAuthContext } from '/context';

export default function TesterOnlyModeLandingScreen() {
  const linkTo = useLinkTo();

  const { isAuthenticating, fetching, user } = useAuthContext();
  const windowDimensions = useWindowDimensions();

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          minHeight: windowDimensions.height,
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: windowDimensions.height,
          }}
        >
          <Text>Welcome to Key Conservation Tester Only Mode</Text>

          <HorizontalContainer>
            <Button
              loading={isAuthenticating || fetching}
              label="Sign in as tester"
              onPress={() => {
                if (user) {
                  linkTo('/tester-signup');
                } else {
                  linkTo('/login');
                }
              }}
            />
          </HorizontalContainer>
        </View>
      </ScrollView>
    </View>
  );
}
