import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import ContentCardResultsBase, {
  ContentCardResultsProps,
} from './ContentCardResultsBase';
import Avatar from '/components/Avatar';
import { useGetOrganizationsInGeohashQuery } from '/generated/graphql';

export default function ContentCardResultsOrganizations(
  props: ContentCardResultsProps,
) {
  const navigation = useNavigation<StackNavigationProp<any>>();

  const [nextToken, setNextToken] = useState<string>();

  const [{ data, fetching, stale, error }, refetch] =
    useGetOrganizationsInGeohashQuery({
      variables: {
        geohash: props.geohash,
        query: props.query,
        nextToken,
      },
    });

  function goToOrganization(id: string) {
    navigation.navigate('Profile', { id });
  }

  return (
    <ContentCardResultsBase
      hasError={!!error}
      total={data?.getOrganizationsInGeohash.total ?? 0}
      loading={fetching}
      stale={stale}
      hasMore={!!data?.getOrganizationsInGeohash.nextToken}
      onRetry={refetch}
      onClose={props.onClose}
      data={data?.getOrganizationsInGeohash.items ?? []}
      renderItem={({ item }) => {
        return (
          <Pressable
            onPress={() => goToOrganization(item.id)}
            style={({ pressed }) => [
              styles.listItem,
              {
                backgroundColor: pressed ? '#eee' : 'white',
              },
            ]}
          >
            <Avatar
              source={{ uri: item.profile_image ?? '' }}
              rounded
              size={48}
              containerStyle={{
                marginRight: 8,
              }}
            />
            <Text style={styles.listItemText}>{item.name}</Text>
          </Pressable>
        );
      }}
      onPaginate={() => {
        if (data?.getOrganizationsInGeohash.nextToken && !fetching)
          setNextToken(data?.getOrganizationsInGeohash.nextToken);
      }}
    />
  );
}

const styles = StyleSheet.create({
  listItem: {
    // flex: 1,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  listItemText: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    fontSize: 18,
  },
});
