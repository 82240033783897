import { createContext, useCallback, useEffect, useState } from 'react';

const ORIGINAL_PUSH_STATE = window.history.pushState;
const ORIGINAL_REPLACE_STATE = window.history.replaceState;

export const ReferrerContext = createContext<string | undefined>(undefined);

/**
 * Reliably track the referrer of the current page.
 * Since this is an SPA, referrer does not get updated every route change, so it
 * must be managed here.
 */
export default function ReferrerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [previousPath, setPreviousPath] = useState<string>();

  const updatePreviousPath = useCallback(() => {
    setPreviousPath(window.location.href);
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', updatePreviousPath);

    window.history.pushState = function (...args) {
      updatePreviousPath();
      return ORIGINAL_PUSH_STATE.apply(this, args);
    };
    window.history.replaceState = function (...args) {
      updatePreviousPath();
      return ORIGINAL_REPLACE_STATE.apply(this, args);
    };

    return () => {
      window.removeEventListener('popstate', updatePreviousPath);
    };
  }, [updatePreviousPath]);

  return (
    <ReferrerContext.Provider value={previousPath || document.referrer}>
      {children}
    </ReferrerContext.Provider>
  );
}
