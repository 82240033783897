import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { useSearchGroupsQuery } from '/generated/graphql';
import useDebouncedState from '/hooks/useDebouncedState';
import GridList from '/components/GridList';
import Button from '/components/Button';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import Avatar from '/components/Avatar';
import { StyleSheet } from 'react-native';
import ListLoading from '/components/ListLoading';
import GenericError from '../../../../components/common/Generic/GenericError';
import SearchBar from '/components/SearchBar/SearchBar';
import { KEY_GRAY, TEXT_INPUT } from '/constants';
import GenericListEmptyComponent from '/components/common/Generic/GenericListEmptyComponent';

export default function Groups() {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [query, queryDebounced, setQuery] = useDebouncedState('');

  const [{ data, error, fetching }, refetch] = useSearchGroupsQuery({
    variables: {
      limit: 9,
      filter: {
        query: queryDebounced,
      },
    },
  });

  function onSeeMorePressed() {
    navigate('SearchGroups', {
      q: query,
    });
  }

  function onViewGroup(groupId: string) {
    navigate('ViewGroup', { id: groupId });
  }

  return (
    <View>
      <SearchBar
        loading={fetching}
        value={query}
        onChangeText={(text) => {
          setQuery(text);
        }}
        containerStyle={{
          ...TEXT_INPUT,
          height: 'auto',
          minHeight: 36,
          marginBottom: 8,
          marginHorizontal: 0,
        }}
      />

      {!data && fetching ? (
        <ListLoading />
      ) : !data && error ? (
        <GenericError onRetry={refetch} />
      ) : data?.searchGroups.total === 0 ? (
        <GenericListEmptyComponent placeholderText="No results" />
      ) : (
        <>
          <GridList
            data={data?.searchGroups.items}
            maxTileWidth={110}
            renderItem={({ item, tileWidth }) => {
              return (
                <Pressable
                  onPress={() => onViewGroup(item.id)}
                  style={{
                    flex: 1,
                    justifyContent: 'flex-start',
                  }}
                >
                  <Avatar
                    rounded
                    source={{ uri: item.group_logo ?? '' }}
                    size={tileWidth - 8}
                  />
                  <Text style={styles.groupName}>
                    {item.name.toUpperCase()}
                  </Text>
                </Pressable>
              );
            }}
          />

          {data?.searchGroups.nextToken ? (
            <Button
              style={{
                backgroundColor: KEY_GRAY,
              }}
              labelStyle={{
                color: 'white',
              }}
              label="SEE MORE"
              onPress={onSeeMorePressed}
            />
          ) : null}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  groupName: {
    marginTop: 8,
    textTransform: 'uppercase',
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
    textAlign: 'center',
  },
});
