import { AntDesign, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import Avatar from './Avatar';
import CampaignConnectInviteCTA from './Notifications/CallsToAction/CampaignConnectInviteCTA/CampaignConnectInviteCTA';
import OrgSurveyCheckCTA from './Notifications/CallsToAction/OrgSurveyCheckCTA';
import NotificationBody from './Notifications/NotificationBody';
import { KEY_GRAY } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import {
  NotificationIcon,
  NotificationType,
  Notification as TNotification,
  User,
} from '/generated/graphql';
import getCDNImageUri from '/util/getCDNImageUri';
import { handleNotificationPress } from '/util/notifications';
import CampaignConnectInviteCampaignSubmittedCTA from './Notifications/CallsToAction/CampaignConnectInviteCampaignSubmittedCTA/CampaignConnectInviteCampaignSubmittedCTA';
import Button from './Button';
import CreativeConnectProjectJoinRequestCTA from './Notifications/CallsToAction/CreativeConnectProjectJoinRequestCTA/CreativeConnectProjectJoinRequestCTA';
import EventAttendanceInviteCTA from './Notifications/CallsToAction/EventAttendanceInviteCTA/EventAttendanceInviteCTA';
import EventPartnershipInviteCTA from './Notifications/CallsToAction/EventPartnershipInviteCTA/EventPartnershipInviteCTA';

interface INotification
  extends Pick<
    TNotification,
    'id' | 'data' | 'body' | 'icon' | 'created_at' | 'thumbnail' | 'type'
  > {
  avatar?: Pick<User, 'id' | 'name' | 'profile_image'> | null;
}

interface INotificationProps {
  notification: INotification;
  viewingAsUserId: string;
}

const AVATAR_SIZE = 55;

export default function Notification(props: INotificationProps) {
  const data: any =
    props.notification?.data && JSON.parse(props.notification.data);

  const navigation = useNavigation<StackNavigationProp<any>>();

  const { userData } = useAuthContext();
  const { teams } = useTeamContext();

  const renderIcon = (icon: NotificationIcon) => {
    switch (icon) {
      case NotificationIcon.Warning: {
        return (
          <View
            style={[styles.iconContainer, { borderWidth: 0, borderRadius: 0 }]}
          >
            <AntDesign name="warning" size={AVATAR_SIZE} color={KEY_GRAY} />
          </View>
        );
      }
      case NotificationIcon.Alert: {
        return (
          <View style={styles.iconContainer}>
            <Ionicons name="alert" size={32} color={KEY_GRAY} />
          </View>
        );
      }
      case NotificationIcon.Info: {
        return (
          <View style={styles.iconContainer}>
            <Ionicons name="information" size={32} color={KEY_GRAY} />
          </View>
        );
      }
      default:
        return null;
    }
  };

  /** Some notifications require showing a call to action */
  // TODO: Move contents to CTAComponent
  const renderLegacyCTAComponent = () => {
    switch (props.notification?.type) {
      case NotificationType.OrganizationApplicationAccepted: {
        return <OrgSurveyCheckCTA />;
      }
    }
  };

  const CTAComponent = useMemo(() => {
    switch (props.notification?.type) {
      case NotificationType.EventAttendanceInvite:
        if (!data?.eventId) return null;
        return (
          <EventAttendanceInviteCTA
            eventId={data.eventId}
            userId={props.viewingAsUserId ?? userData?.id}
            attendeeId={data.eventAttendeeId}
          />
        );
      case NotificationType.EventPartnershipInvite:
        if (!data?.eventId) return null;
        return (
          <EventPartnershipInviteCTA
            eventId={data.eventId}
            userId={props.viewingAsUserId ?? userData?.id}
          />
        );
      case NotificationType.CampaignConnectInviteCampaignSubmitted: {
        if (!data?.campaignConnectInvite?.id) return null;

        const invitingUser =
          props.viewingAsUserId && props.viewingAsUserId !== userData?.id
            ? teams.find((team) => team.user.id === props.viewingAsUserId)?.user
            : userData;

        return (
          <CampaignConnectInviteCampaignSubmittedCTA
            invite={data?.campaignConnectInvite}
            invitedUser={props.notification.avatar}
            invitingUser={invitingUser}
          />
        );
      }
      case NotificationType.CampaignConnectInvite: {
        if (!data?.campaignConnectInvite?.id) return null;
        return (
          <CampaignConnectInviteCTA
            invite={data?.campaignConnectInvite}
            invitingUser={props.notification.avatar}
          />
        );
      }
      case NotificationType.CreativeConnectProjectInvite: {
        if (!data?.ccProjectId) return null;
        return (
          <Button
            label="View"
            onPress={() => {
              navigation.push('ViewCreativeConnectLanding', {
                id: data.ccProjectId,
                vUId: props.viewingAsUserId,
              });
            }}
          />
        );
      }
      case NotificationType.CreativeConnectProjectJoinRequest: {
        if (!data?.ccProjectId) return null;
        return (
          <CreativeConnectProjectJoinRequestCTA projectId={data.ccProjectId} />
        );
      }
      default:
        return null;
    }
  }, [
    data,
    navigation,
    props.notification.avatar,
    props.notification?.type,
    props.viewingAsUserId,
    teams,
    userData,
  ]);

  const goToProfile = () => {
    if (NotificationType.NewPartnerRequest === props.notification.type) {
      if (!data) return null;

      navigation.push('Profile', {
        id: data?.partner.id || data?.organizationId,
      });

      return;
    }

    if (!props.notification.avatar) return;

    if (props.notification.avatar.id === userData?.id) {
      navigation.navigate('ProfileStack');
    } else {
      navigation.push('Profile', {
        id: props.notification.avatar.id || data?.organizationId,
      });
    }
  };

  // // Link users to the thumbnailLinkId included in notification data
  const goToThumbnail = () => {
    if (!data) return;

    switch (props.notification.type) {
      case NotificationType.EventAttendanceConfirmed:
      case NotificationType.EventPartnershipInviteAccepted:
      case NotificationType.EventAttendanceInvite:
      case NotificationType.EventPartnershipInvite: {
        if (data?.eventId) {
          navigation.push('ViewEvent', { id: data.eventId });
        }
        break;
      }
      case NotificationType.CreativeConnectProjectInviteDeclined:
      case NotificationType.CreativeConnectProjectFeature: {
        if (!data?.ccProjectId) return;
        navigation.push('ViewCreativeConnectLanding', {
          id: data.ccProjectId,
          vUId: props.viewingAsUserId,
        });
        break;
      }
      case NotificationType.NewComment: {
        if (!data.interactionsId) return;
        navigation.push('Comments', {
          interactionsId: data.interactionsId,
        });
        break;
      }
      case NotificationType.GroupMembershipRequestAccepted:
      case NotificationType.GroupMembershipInviteReceived:
        if (!data.groupId) return;
        navigation.push('ViewGroup', {
          id: data.groupId,
        });
        break;
      default:
        if (!data.thumbnailLinkId && !data?.campaignId) return;
        navigation.push('Campaign', {
          campaignId: data?.thumbnailLinkId || data?.campaignId,
        });
    }
    return;
  };

  return (
    <Pressable
      onPress={() =>
        handleNotificationPress(
          props.notification,
          props.viewingAsUserId || userData!.id, // * If we have notifications, we have userData
          navigation.navigate,
        )
      }
      style={styles.container}
    >
      <View style={styles.bodyContainer}>
        {/* ICON/AVATAR */}
        {props.notification?.icon ? (
          renderIcon(props.notification?.icon)
        ) : props.notification?.avatar?.profile_image ? (
          <Avatar
            source={{ uri: props.notification?.avatar.profile_image }}
            size={AVATAR_SIZE}
            rounded
            onPress={goToProfile}
          />
        ) : null}

        {/* BODY */}
        <View style={styles.innerBodyContainer}>
          <NotificationBody notification={props.notification} />
          {/* NOTIFICATION DATE */}
          <Text style={styles.createdAt}>
            {dayjs(Number(props.notification?.created_at)).fromNow()}
          </Text>
        </View>
        {/* THUMBNAIL */}
        {CTAComponent ||
          (props.notification?.thumbnail ? (
            <Pressable onPress={goToThumbnail}>
              <Image
                source={{
                  uri: getCDNImageUri({
                    uri: props.notification.thumbnail,
                    isThumbnail: true,
                    dimensions: {
                      width: 64,
                      height: 64,
                    },
                  }),
                }}
                style={styles.thumbnail}
              />
            </Pressable>
          ) : null)}
      </View>
      {/* ACTION ITEM */}
      {renderLegacyCTAComponent()}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 8,
    paddingVertical: 4,
    alignItems: 'center',
    borderRadius: 10,
  },
  campainUpdate: {
    fontFamily: 'Lato-BoldItalic',
    fontSize: 16,
    color: '#A8E063',
  },
  warningTitle: {
    color: '#FF0076',
    fontFamily: 'Lato-BoldItalic',
    fontSize: 16,
  },
  bodyContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 7,
  },
  innerBodyContainer: {
    flex: 1,
    paddingHorizontal: 8,
  },
  thumbnail: {
    width: 64,
    height: 64,
    backgroundColor: 'gray',
    borderRadius: 4,
  },
  bodyText: {
    fontFamily: 'Lato',
    fontSize: 16,
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  createdAt: {
    fontFamily: 'Lato',
    color: 'gray',
  },
  iconContainer: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE,
    overflow: 'hidden',
    borderWidth: 3,
    borderColor: KEY_GRAY,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
