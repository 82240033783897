import React from 'react';
import { Platform } from 'react-native';
import { StackNavigationOptions } from '@react-navigation/stack';
import BackButton from '/components/BackButton';
import {
  CampaignFlatListFilter,
  CampaignFlatListLayoutMode,
} from '/components/CampaignFlatList/CampaignFlatList';
import { CREATIVE_CONNECT_BACKGROUND_GRAY, KEY_GRAY } from '/constants';
import { ResponsiveTabNavigationOptions } from '/navigation/ResponsiveTabs/navigators/createResponsiveTabNavigator';
import { WithSafeAreaInsetsProps } from 'react-native-safe-area-context';

import Screens from './_imports';

const {
  CreateGroup,
  ViewGroup,
  GroupMembers,
  ManageGroup,
  EditGroupDiscussionCategories,
  ViewGroupDiscussionPost,
  EditGroup,
  JobBoardScreen,
  ViewSharedContentBucket,
  ListUserGroups,
  SearchGroups,
  ViewCampaignScreen,
  ListCampaignsScreen,
  ViewCommentsScreen,
  ViewMomentInNature,
  ViewJobPost,
  CreateReportScreen,
  ManageDonationRequest,
  ManageCampaignScreen,
  EditCampaignScreen,
  CreateCampaignUpdateScreen,
  CreateMomentInNature,
  EditMomentInNature,
  CreateNewsPost,
  EditNewsPost,
  ViewNewsPost,
  CreateJobPost,
  EditJobPost,
  CloseCampaignScreen,
  CampaignStatsScreen,
  GiveSkillsScreen,
  CreateSkillRequestApplicationScreen,
  VolunteerScreen,
  DonateScreen,
  ListCampaignDonations,
  ListMyDonations,
  DonateSuccess,
  ListJobPosts,
  VolunteerSignUpScreen,
  SpeciesSummaryScreen,
  SpeciesVernacularNames,
  SpeciesScientificNames,
  SkillSummaryScreen,
  ResearchTopicSummaryScreen,
  ProfileScreen,
  EditProfileLocations,
  ConnectionsScreen,
  ViewPartnershipsScreen,
  ListUsersScreen,
  BookmarksScreen,
  ViewSpeciesUserContent,
  AccountSettingsScreen,
  NotificationPreferences,
  DonationSettings,
  StripePayouts,
  ChangePassword,
  ChangeEmail,
  ManageTeamScreen,
  EditTeamMemberScreen,
  InviteTeamMemberScreen,
  ViewPendingTeamMemberships,
  ViewMyTeamsScreen,
  ListBlockedUsers,
  ViewApplicationDetails,
  ViewDiscussionBoard,
  ManageSkillRequestScreen,
  ManageVolunteerRequest,
  ListVolunteers,
  ViewApplicantDetailsScreen,
  MyImpactCampaignsScreen,
  OrganizationStatsScreen,
  ListCampaignsWithStats,
  CreateKeyBulletinPost,
  EditKeyBulletinPost,
  ViewKeyBulletinPost,
  ListKeyBulletinPosts,
  ListDirectConversations,
  DirectMessageScreen,
  DeleteAccount,
  CreateCreativeConnectProject,
  EditCreativeConnectProject,
  ApplyToCreativeConnectProject,
  ListCreativeConnectCollaborators,
  ListCreativeConnectProjects,
  CreativeConnectLandingPage,
  CreativeConnectDashboard,
  ListSurveySubmissionsScreen,
  CreateEventScreen,
  EditEventScreen,
  ListEventsScreen,
  ViewEventScreen,
  ListNewsPosts,
  ListEventAttendeesScreen,
  ViewEventInvitation,
  ListMyEventsScreen,
  CreativeConnectAnalyticsDashboard,
} = Screens;

/**
 * Configure the tab bar here
 */
export const TabBarOptions: ResponsiveTabNavigationOptions = {
  headerShown: false,
};

/**
 * Configure the header here
 */
export const getStackNavigatorOptions = (
  safeAreaInsets: WithSafeAreaInsetsProps['insets'],
): StackNavigationOptions => ({
  headerStyle: {
    backgroundColor: '#fff',
    // shadowColor: 'transparent',
    height: Platform.select({
      default: 64 + safeAreaInsets.top,
      web: undefined,
    }),
  },
  headerTintColor: KEY_GRAY,
  headerTitleStyle: {
    fontSize: 20,
    color: KEY_GRAY,
    fontFamily: 'LeagueSpartan-Bold',
    fontWeight: undefined,
  },
  headerBackTitleVisible: false,
});

/**
 * Navigation Clusters
 *
 * Groups of screens that overlap between main stack navigators.
 *
 */

/** Includes all the common screen clusters at once */
export const createCommonCluster = (Navigator: any) => {
  return (
    <>
      {createCampaignCluster(Navigator)}
      {createImpactCluster(Navigator)}
      {createProfileCluster(Navigator)}
      {createAccountSettingsCluster(Navigator)}
      <Navigator.Screen
        component={CreateGroup}
        name="CreateGroup"
        options={CreateGroupScreenOptions}
      />
      <Navigator.Screen
        component={ViewGroup}
        name="ViewGroup"
        options={ViewGroupScreenOptions}
      />
      <Navigator.Screen
        component={GroupMembers}
        name="GroupMembers"
        options={GroupMembersScreenOptions}
      />
      <Navigator.Screen
        component={ManageGroup}
        name="ManageGroup"
        options={ManageGroupScreenOptions}
      />
      <Navigator.Screen
        component={EditGroupDiscussionCategories}
        name="EditGroupDiscussionCategories"
        options={EditGroupDiscussionCategoriesScreenOptions}
      />
      <Navigator.Screen
        component={ViewGroupDiscussionPost}
        name="GroupDiscussionPost"
        options={GroupDiscussionPostScreenOptions}
      />
      <Navigator.Screen
        component={EditGroup}
        name="EditGroup"
        options={EditGroupScreenOptions}
      />
      <Navigator.Screen
        component={JobBoardScreen}
        name="JobBoard"
        options={JobBoardScreenOptions}
      />
      <Navigator.Screen
        component={ViewSharedContentBucket}
        name="ViewSharedContentBucket"
        options={ViewSharedContentBucketOptions}
      />
      <Navigator.Screen
        component={ListUserGroups}
        name="ListUserGroups"
        options={ListUserGroupsOptions}
      />
      <Navigator.Screen
        component={SearchGroups}
        name="SearchGroups"
        options={SearchGroupsOptions}
      />
      <Navigator.Screen
        component={CreateCreativeConnectProject}
        name="CreateCreativeConnectProject"
        options={CreateCreativeConnectProjectScreenOptions}
      />
      <Navigator.Screen
        component={EditCreativeConnectProject}
        name="EditCreativeConnectProject"
        options={EditCreativeConnectProjectScreenOptions}
      />
      <Navigator.Screen
        component={CreativeConnectDashboard}
        name="CreativeConnectDashboard"
        options={CreativeConnectDashboardScreenOptions}
      />
      <Navigator.Screen
        component={CreativeConnectLandingPage}
        name="ViewCreativeConnectLanding"
        options={CreativeConnectLandingPageScreenOptions}
      />
      <Navigator.Screen
        component={ApplyToCreativeConnectProject}
        name="ApplyToCreativeConnectProject"
        options={ApplyToCreativeConnectProjectScreenOptions}
      />
      <Navigator.Screen
        component={ListCreativeConnectCollaborators}
        name="ListCreativeConnectCollaborators"
        options={ListCreativeConnectCollaboratorsScreenOptions}
      />
      <Navigator.Screen
        component={ListCreativeConnectProjects}
        name="ListCreativeConnectProjects"
        options={ListCreativeConnectProjectsScreenOptions}
      />
      <Navigator.Screen
        component={ListNewsPosts}
        name="ListNewsPosts"
        options={ListNewsPostsScreenOptions}
      />
      <Navigator.Screen
        component={ListSurveySubmissionsScreen}
        name="ListSurveySubmissions"
        options={ListSurveySubmissionsScreenOptions}
      />
      <Navigator.Screen
        component={CreateEventScreen}
        name="CreateEvent"
        options={CreateEventScreenOptions}
      />
      <Navigator.Screen
        component={EditEventScreen}
        name="EditEvent"
        options={EditEventScreenOptions}
      />
      <Navigator.Screen
        component={ListEventsScreen}
        name="ListEvents"
        options={ListEventsScreenOptions}
      />
      <Navigator.Screen
        component={ViewEventScreen}
        name="ViewEvent"
        options={ViewEventScreenOptions}
      />
      <Navigator.Screen
        component={ListEventAttendeesScreen}
        name="ListEventAttendees"
        options={ListEventAttendeesScreenOptions}
      />
      <Navigator.Screen
        component={ViewEventInvitation}
        name="ViewEventInvitation"
        options={ViewEventInvitationScreenOptions}
      />
      <Navigator.Screen
        component={ListMyEventsScreen}
        name="ListMyEvents"
        options={ListMyEventsScreenOptions}
      />
      <Navigator.Screen
        component={CreativeConnectAnalyticsDashboard}
        name="CreativeConnectAnalyticsDashboard"
        options={CreativeConnectAnalyticsDashboardScreenOptions}
      />
    </>
  );
};
export type CommonClusterParamList = CampaignClusterParamList &
  ImpactClusterParamList &
  ProfileClusterParamList &
  AccountSettingsClusterParamList;

const createCampaignCluster = (Navigator: any) => {
  return (
    <>
      <Navigator.Screen
        component={ViewCampaignScreen}
        name="Campaign"
        options={ViewCampaignScreenOptions}
      />
      <Navigator.Screen
        component={ListCampaignsScreen}
        name="ListCampaigns"
        options={ListCampaignsScreenOptions}
      />
      <Navigator.Screen
        component={ViewCommentsScreen}
        name="Comments"
        options={ViewCommentsScreenOptions}
      />
      <Navigator.Screen
        component={ViewMomentInNature}
        name="MomentInNature"
        options={ViewMomentInNatureScreenOptions}
      />
      <Navigator.Screen
        component={ViewJobPost}
        name="ViewJobPost"
        options={ViewJobPostScreenOptions}
      />
      <Navigator.Screen
        component={CreateReportScreen}
        name="CreateReport"
        options={CreateReportScreenOptions}
      />
      <Navigator.Screen
        component={ManageDonationRequest}
        name="ManageDonationRequest"
        options={ManageDonationRequestScreenOptions}
      />
      <Navigator.Screen
        component={ManageCampaignScreen}
        name="ManageCampaign"
        options={ManageCampaignScreenOptions}
      />
      <Navigator.Screen
        component={EditCampaignScreen}
        name="EditCampaign"
        options={EditCampaignScreenOptions}
      />
      <Navigator.Screen
        component={CreateCampaignUpdateScreen}
        name="CreateCampaignUpdate"
        options={CreateCampaignUpdateScreenOptions}
      />
      <Navigator.Screen
        component={CreateMomentInNature}
        name="CreateMomentInNature"
        options={CreateMomentInNatureScreenOptions}
      />
      <Navigator.Screen
        component={EditMomentInNature}
        name="EditMomentInNature"
        options={EditMomentInNatureScreenOptions}
      />
      <Navigator.Screen
        component={CreateNewsPost}
        name="CreateNewsPost"
        options={CreateNewsPostScreenOptions}
      />
      <Navigator.Screen
        component={EditNewsPost}
        name="EditNewsPost"
        options={EditNewsPostScreenOptions}
      />
      <Navigator.Screen
        component={ViewNewsPost}
        name="ViewNewsPost"
        options={ViewNewsPostScreenOptions}
      />
      <Navigator.Screen
        component={CreateJobPost}
        name="CreateJobPost"
        options={CreateJobPostScreenOptions}
      />
      <Navigator.Screen
        component={EditJobPost}
        name="EditJobPost"
        options={EditJobPostScreenOptions}
      />
      <Navigator.Screen
        component={CloseCampaignScreen}
        name="CloseCampaign"
        options={CloseCampaignScreenOptions}
      />
      <Navigator.Screen
        component={CampaignStatsScreen}
        name="CampaignStats"
        options={CampaignStatsScreenOptions}
      />
      <Navigator.Screen
        component={GiveSkillsScreen}
        name="GiveSkills"
        options={GiveSkillsScreenOptions}
      />
      <Navigator.Screen
        component={CreateSkillRequestApplicationScreen}
        name="CreateSkillRequestApplication"
        options={CreateSkillRequestApplicationScreenOptions}
      />
      <Navigator.Screen
        component={VolunteerScreen}
        name="VolunteerScreen"
        options={VolunteerScreenOptions}
      />
      <Navigator.Screen
        component={DonateScreen}
        name="DonateScreen"
        options={DonateScreenOptions}
      />
      <Navigator.Screen
        component={ListCampaignDonations}
        name="ListCampaignDonations"
        options={ListCampaignDonationsOptions}
      />
      <Navigator.Screen
        component={ListMyDonations}
        name="ListMyDonations"
        options={ListMyDonationsOptions}
      />
      <Navigator.Screen
        component={DonateSuccess}
        name="DonateSuccess"
        options={DonateSuccessOptions}
      />
      <Navigator.Screen
        component={ListJobPosts}
        name="ListJobPosts"
        options={ListJobPostsOptions}
      />

      <Navigator.Screen
        component={VolunteerSignUpScreen}
        name="VolunteerSignUp"
        options={VolunteerSignUpScreenOptions}
      />
      <Navigator.Screen
        component={SpeciesSummaryScreen}
        name="SpeciesSummary"
        options={SpeciesSummaryScreenOptions}
      />
      <Navigator.Screen
        component={SpeciesVernacularNames}
        name="SpeciesVernacularNames"
        options={SpeciesVernacularNamesOptions}
      />
      <Navigator.Screen
        component={SpeciesScientificNames}
        name="SpeciesScientificNames"
        options={SpeciesScientificNamesOptions}
      />
      <Navigator.Screen
        component={SkillSummaryScreen}
        name="SkillSummary"
        options={SkillSummaryScreenOptions}
      />
      <Navigator.Screen
        component={ResearchTopicSummaryScreen}
        name="ResearchTopicSummary"
        options={ResearchTopicSummaryScreenOptions}
      />
    </>
  );
};
export type CampaignClusterParamList = {
  Campaign: {
    campaignId?: string;
    postId?: string;
  };
  ListCampaigns: {
    /** Required for reporting analytics */
    page: string | null;
    filter?: CampaignFlatListFilter;
    limit?: number;
    title?: string;
    layoutMode?: CampaignFlatListLayoutMode;
    /** Define behavior when campaign is pressed. Only works when `compact` is true */
    compactOnPress?: () => void;
  };
  ManageCampaign: {
    campaignId: string;
  };
  CreateCampaignUpdate: {
    campaignId?: string;
  };
  CloseCampaign: {
    campaignId: string;
  };
  CreateCampaign: {
    draftId?: string;
  };
  CampaignStats: {
    campaignId: string;
  };
};

const createImpactCluster = (Navigator: any) => {
  return (
    <>
      <Navigator.Screen
        component={ViewApplicationDetails}
        name="ViewApplicationDetails"
        options={ViewApplicationDetailsOptions}
      />
      <Navigator.Screen
        component={ViewDiscussionBoard}
        name="ViewDiscussionBoard"
        options={ViewDiscussionBoardOptions}
      />
      <Navigator.Screen
        component={ManageSkillRequestScreen}
        name="ManageSkillRequest"
        options={ManageSkillRequestScreenOptions}
      />
      <Navigator.Screen
        component={ManageVolunteerRequest}
        name="ManageVolunteerRequest"
        options={ManageVolunteerRequestOptions}
      />
      <Navigator.Screen
        component={ListVolunteers}
        name="ListVolunteers"
        options={ListVolunteersScreen}
      />
      <Navigator.Screen
        component={ViewApplicantDetailsScreen}
        name="ViewApplicantDetails"
        options={ViewApplicantDetailsScreenOptions}
      />
      <Navigator.Screen
        name="MyImpactCampaigns"
        component={MyImpactCampaignsScreen}
        options={MyImpactCampaignsScreenOptions}
      />
      <Navigator.Screen
        name="OrganizationStats"
        component={OrganizationStatsScreen}
        options={OrganizationStatsScreenOptions}
      />
      <Navigator.Screen
        name="ListCampaignsWithStats"
        component={ListCampaignsWithStats}
        options={ListCampaignsWithStatsOptions}
      />
      <Navigator.Screen
        name="CreateKeyBulletinPost"
        component={CreateKeyBulletinPost}
        options={CreateKeyBulletinPostOptions}
      />
      <Navigator.Screen
        name="EditKeyBulletinPost"
        component={EditKeyBulletinPost}
        options={EditKeyBulletinPostOptions}
      />
      <Navigator.Screen
        name="ViewKeyBulletinPost"
        component={ViewKeyBulletinPost}
        options={ViewKeyBulletinPostOptions}
      />
      <Navigator.Screen
        name="ListKeyBulletinPosts"
        component={ListKeyBulletinPosts}
        options={ListKeyBulletinPostsOptions}
      />
      <Navigator.Screen
        component={ListDirectConversations}
        name="ListDirectConversations"
        options={ListDirectConversationsOptions}
      />
      <Navigator.Screen
        component={DirectMessageScreen}
        name="DirectMessageScreen"
        options={DirectMessageScreenOptions}
      />
    </>
  );
};
export type ImpactClusterParamList = {
  OrganizationStats: {
    userId: string;
  };
};

const createProfileCluster = (Navigator: any) => {
  return (
    <>
      <Navigator.Screen
        component={ProfileScreen}
        name="Profile"
        options={ProfileScreenOptions}
      />
      <Navigator.Screen
        component={EditProfileLocations}
        name="EditProfileLocations"
        options={EditProfileLocationsOptions}
      />
      <Navigator.Screen
        component={ConnectionsScreen}
        name="Connections"
        options={ConnectionsScreenOptions}
      />
      <Navigator.Screen
        component={ViewPartnershipsScreen}
        name="ViewPartnerships"
        options={ViewPartnershipsScreenOptions}
      />
      <Navigator.Screen
        component={ListUsersScreen}
        name="ListUsers"
        options={ListUsersScreenOptions}
      />
      <Navigator.Screen
        name="Bookmarks"
        component={BookmarksScreen}
        options={BookmarksScreenOptions}
      />
      <Navigator.Screen
        name="ViewSpeciesUserContent"
        component={ViewSpeciesUserContent}
        options={ViewSpeciesUserContentOptions}
      />
    </>
  );
};
export type ProfileClusterParamList = {};

const createAccountSettingsCluster = (Navigator: any) => {
  return (
    <>
      <Navigator.Screen
        component={AccountSettingsScreen}
        name="AccountSettings"
        options={AccountSettingsScreenOptions}
      />
      <Navigator.Screen
        component={NotificationPreferences}
        name="NotificationPreferences"
        options={NotificationPreferencesScreenOptions}
      />
      <Navigator.Screen
        component={DonationSettings}
        name="DonationSettings"
        options={DonationSettingsScreenOptions}
      />
      <Navigator.Screen
        component={StripePayouts}
        name="StripePayouts"
        options={StripePayoutsScreenOptions}
      />
      <Navigator.Screen
        component={ChangePassword}
        name="ChangePassword"
        options={ChangePasswordScreenOptions}
      />
      <Navigator.Screen
        component={ChangeEmail}
        name="ChangeEmail"
        options={ChangeEmailScreenOptions}
      />
      <Navigator.Screen
        component={ProfileScreen}
        name="ProfileDetails"
        options={ProfileScreenOptions}
      />
      <Navigator.Screen
        component={ManageTeamScreen}
        name="ManageTeam"
        options={ManageTeamScreenOptions}
      />
      <Navigator.Screen
        component={EditTeamMemberScreen}
        name="EditTeamMember"
        options={EditTeamMemberScreenOptions}
      />
      <Navigator.Screen
        component={InviteTeamMemberScreen}
        name="InviteTeamMember"
        options={InviteTeamMemberScreenOptions}
      />
      <Navigator.Screen
        component={ViewPendingTeamMemberships}
        name="ViewPendingTeamMemberships"
        options={ViewPendingTeamMembershipsScreenOptions}
      />
      <Navigator.Screen
        component={ViewMyTeamsScreen}
        name="ViewMyTeams"
        options={ViewMyTeamsScreenOptions}
      />
      <Navigator.Screen
        component={ListBlockedUsers}
        name="ListBlockedUsers"
        options={ListBlockedUsersOptions}
      />
      <Navigator.Screen
        component={DeleteAccount}
        name="DeleteAccount"
        options={DeleteAccountScreenOptions}
      />
    </>
  );
};
export type AccountSettingsClusterParamList = {};

/**
 * Screen options
 *
 * Exported for usage by other stacks that may share the common screens described the clusters above
 */
export const ViewCampaignScreenOptions = (): StackNavigationOptions => ({
  title: 'Campaign',
  headerTitle: 'CAMPAIGN',
  headerShown: true,
});

const ListCampaignsScreenOptions = (): StackNavigationOptions => ({
  title: 'Campaigns',
  headerTitle: 'CAMPAIGNS',
  headerShown: true,
});

const CreateGroupScreenOptions = (): StackNavigationOptions => ({
  title: 'Create Group',
  headerTitle: 'CREATE GROUP',
  headerShown: false,
});

const ViewGroupScreenOptions = (): StackNavigationOptions => ({
  title: 'Group',
  headerTitle: 'GROUP',
  headerShown: false,
});

const GroupMembersScreenOptions = (): StackNavigationOptions => ({
  title: 'Group Members',
  headerTitle: 'GROUP MEMBERS',
});

const ManageGroupScreenOptions = (): StackNavigationOptions => ({
  title: 'Manage Group',
  headerTitle: 'MANAGE GROUP',
  headerShown: true,
});

const EditGroupDiscussionCategoriesScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Manage Group',
    headerTitle: 'MANAGE GROUP',
    headerShown: true,
  });

const GroupDiscussionPostScreenOptions = (): StackNavigationOptions => ({
  title: 'Discussion Post',
  headerTitle: 'DISCUSSION POST',
  headerShown: true,
});

const EditGroupScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit Group',
  headerTitle: 'EDIT GROUP',
  headerShown: true,
});

const JobBoardScreenOptions = (): StackNavigationOptions => ({
  title: 'Job Board',
  headerTitle: 'JOB BOARD',
  headerShown: true,
});

const ViewSharedContentBucketOptions = (): StackNavigationOptions => ({
  title: 'Shared Resources',
  headerTitle: 'SHARED RESOURCES',
});

const ListUserGroupsOptions = (): StackNavigationOptions => ({
  title: 'Groups',
  headerTitle: 'GROUPS',
});
const SearchGroupsOptions = (): StackNavigationOptions => ({
  title: 'Search Groups',
  headerTitle: 'SEARCH GROUPS',
});
const CreateCreativeConnectProjectScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Create Project',
    headerTitle: 'CREATE PROJECT',
  });
const ListNewsPostsScreenOptions = (): StackNavigationOptions => ({
  title: 'News Posts',
  headerTitle: 'NEWS POSTS',
});
const ListSurveySubmissionsScreenOptions = (): StackNavigationOptions => ({
  title: 'Survey Submissions',
  headerTitle: 'SURVEY SUBMISSIONS',
});
const CreateEventScreenOptions = (): StackNavigationOptions => ({
  title: 'Create Event',
  headerTitle: 'CREATE EVENT',
});
const EditEventScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit Event',
  headerTitle: 'EDIT EVENT',
});
const ListEventsScreenOptions = (): StackNavigationOptions => ({
  title: 'Events',
  headerTitle: 'EVENTS',
});
const ViewEventScreenOptions = (): StackNavigationOptions => ({
  title: 'Event',
  headerTitle: 'EVENT',
  headerShown: false,
});
const ListEventAttendeesScreenOptions = (): StackNavigationOptions => ({
  title: 'Event Attendees',
  headerTitle: 'EVENT ATTENDEES',
});
const ViewEventInvitationScreenOptions = (): StackNavigationOptions => ({
  title: 'Event Invitation',
  headerTitle: 'EVENT INVITATION',
});
const ListMyEventsScreenOptions = (): StackNavigationOptions => ({
  title: 'My Events',
  headerTitle: 'MY EVENTS',
});
const CreativeConnectAnalyticsDashboardScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Analytics Dashboard',
    headerTitle: 'ANALYTICS DASHBOARD',
  });
const EditCreativeConnectProjectScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit Project',
  headerTitle: 'EDIT PROJECT',
});
const CreativeConnectDashboardScreenOptions = (): StackNavigationOptions => ({
  title: 'Dashboard',
  headerTitle: 'DASHBOARD',
});
const CreativeConnectLandingPageScreenOptions = (): StackNavigationOptions => ({
  title: 'Creative Connect',
  headerShown: false,
});
const ApplyToCreativeConnectProjectScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Apply',
    headerTitle: 'APPLY',
  });
const ListCreativeConnectCollaboratorsScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Collaborators',
    headerTitle: 'COLLABORATORS',
  });
const ListCreativeConnectProjectsScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Creative Connect',
    headerTitle: 'CREATIVE CONNECT',
    headerStyle: {
      backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
      borderBottomColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
    },
    headerTintColor: 'white',
    headerTitleStyle: {
      fontSize: 20,
      color: 'white',
      fontFamily: 'LeagueSpartan-Bold',
    },
  });

const ListUsersScreenOptions = (): StackNavigationOptions => ({
  title: 'Users',
  headerTitle: 'USERS',
  headerShown: true,
});

export const ViewCommentsScreenOptions = (): StackNavigationOptions => ({
  title: 'Comments',
  headerTitle: 'COMMENTS',
  headerTitleAlign: 'center',
  headerLeft: () => <BackButton />,
  headerShown: true,
});

const ViewMomentInNatureScreenOptions = (): StackNavigationOptions => ({
  title: 'Moment In Nature',
  headerTitle: 'MOMENT IN NATURE',
  headerShown: false,
});

const ViewJobPostScreenOptions = (): StackNavigationOptions => ({
  title: 'Job Post',
  headerTitle: 'JOB POST',
});

export const CreateReportScreenOptions = (): StackNavigationOptions => ({
  title: 'Report',
  headerTitle: 'REPORT',
  headerLeft: () => <BackButton />,
  headerShown: true,
});

export const ManageDonationRequestScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Manage Funding',
    headerTitle: 'MANAGE FUNDING',
    headerShown: true,
  });

export const ManageCampaignScreenOptions = (): StackNavigationOptions => ({
  title: 'Manage Campaign',
  headerTitle: 'MANAGE CAMPAIGN',
  headerShown: true,
});

export const EditCampaignScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit Post',
  headerTitle: 'EDIT CAMPAIGN',
  headerShown: true,
  // headerLeft: () => <BackButton />,
});

export const CreateCampaignUpdateScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Create Post',
    headerTitle: 'UPDATE POST',
    headerShown: false,
  });

export const CreateMomentInNatureScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Create Moment In Nature',
    headerShown: false,
  });

export const EditMomentInNatureScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit Post',
  headerTitle: 'EDIT POST',
});

export const CreateNewsPostScreenOptions = (): StackNavigationOptions => ({
  title: 'Create News Post',
  headerTitle: 'CREATE NEWS POST',
  headerShown: false,
});
export const EditNewsPostScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit News Post',
  headerTitle: 'EDIT NEWS POST',
});
export const ViewNewsPostScreenOptions = (): StackNavigationOptions => ({
  title: 'News Post',
  headerTitle: 'NEWS POST',
});

export const CreateJobPostScreenOptions = (): StackNavigationOptions => ({
  title: 'Create Job',
  headerTitle: 'CREATE JOB',
  headerShown: false,
});

export const EditJobPostScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit Job',
  headerTitle: 'EDIT JOB',
});

export const CloseCampaignScreenOptions = (): StackNavigationOptions => ({
  title: 'Close Campaign',
  headerTitle: 'CLOSE CAMPAIGN',
  headerShown: true,
});

export const CampaignStatsScreenOptions = (): StackNavigationOptions => ({
  title: 'Campaign Stats',
  headerTitle: 'CAMPAIGN STATS',
  headerShown: true,
});

export const VolunteerScreenOptions = (): StackNavigationOptions => ({
  title: 'Volunteer',
  headerShown: false,
  headerTitle: 'VOLUNTEER',
});

export const DonateScreenOptions = (): StackNavigationOptions => ({
  title: 'Donate',
  headerShown: false,
  headerTitle: 'DONATE',
});

export const ListCampaignDonationsOptions = (): StackNavigationOptions => ({
  title: 'Donations',
  headerShown: true,
  headerTitle: 'DONATIONS',
});

export const ListMyDonationsOptions = (): StackNavigationOptions => ({
  title: 'My Donations',
  headerShown: true,
  headerTitle: 'MY DONATIONS',
});

export const DonateSuccessOptions = (): StackNavigationOptions => ({
  title: 'Thank you for donating',
  headerShown: true,
  headerTitle: 'THANK YOU',
});

export const ListJobPostsOptions = (): StackNavigationOptions => ({
  title: 'Job Posts',
  headerShown: true,
  headerTitle: 'JOB POSTS',
});

const VolunteerSignUpScreenOptions = () => ({
  title: 'Volunteer',
  headerTitle: 'VOLUNTEER',
});

export const SpeciesSummaryScreenOptions = (): StackNavigationOptions => ({
  title: 'Species Summary',
  headerTitle: 'SPECIES SUMMARY',
});
export const SpeciesVernacularNamesOptions = (): StackNavigationOptions => ({
  title: 'Species Vernacular',
  headerTitle: 'SPECIES VERNACULAR',
});
export const SpeciesScientificNamesOptions = (): StackNavigationOptions => ({
  title: 'Species Names',
  headerTitle: 'SPECIES NAMES',
});

export const SkillSummaryScreenOptions = (): StackNavigationOptions => ({
  title: 'Skill Summary',
  headerTitle: 'SKILL SUMMARY',
});

export const ResearchTopicSummaryScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Research Topic Summary',
    headerTitle: 'TOPIC SUMMARY',
  });

export const GiveSkillsScreenOptions = (): StackNavigationOptions => ({
  title: 'Skill Request',
  headerTitle: 'SKILL REQUEST',
  headerShown: false,
});
export const CreateSkillRequestApplicationScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Apply',
    headerTitle: 'APPLY',
    headerShown: true,
  });

export const ProfileScreenOptions = (): StackNavigationOptions => ({
  headerTransparent: true,
  headerStyle: {
    backgroundColor: 'rgba(50,51,57,0.7)',
    shadowColor: 'transparent',
  },
  title: 'Profile',
  headerTitle: ' ',
  headerTintColor: '#f5f5f5',
  headerLeft: () => null,
  headerRight: () => null,
});

export const EditProfileLocationsOptions = (): StackNavigationOptions => ({
  title: 'Edit Locations',
  headerTitle: 'EDIT LOCATIONS',
  headerShown: true,
});

export const ManageTeamScreenOptions = (): StackNavigationOptions => ({
  title: 'Manage Team',
  headerTitle: 'MANAGE TEAM',
  headerShown: true,
});

export const EditTeamMemberScreenOptions = (): StackNavigationOptions => ({
  title: 'Edit Member',
  headerTitle: 'EDIT TEAM MEMBER',
  headerShown: true,
});

export const InviteTeamMemberScreenOptions = (): StackNavigationOptions => ({
  title: 'Invite Member',
  headerTitle: 'INVITE TEAM MEMBER',
  headerShown: true,
});

export const ViewPendingTeamMembershipsScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Team Requests',
    headerTitle: 'PENDING REQUESTS',
    headerShown: true,
  });

export const ViewMyTeamsScreenOptions = (): StackNavigationOptions => ({
  title: 'My Teams',
  headerTitle: 'MY TEAMS',
  headerShown: true,
});

export const ViewApplicationDetailsOptions = () => ({
  title: 'Application Details',
  headerTitle: 'APPLICATION',
  headerBackTitleVisible: false,
  headerShown: true,
});
export const ListBlockedUsersOptions = () => ({
  title: 'Blocked Users',
  headerTitle: 'BLOCKED USERS',
  headerShown: true,
});
export const DeleteAccountScreenOptions = () => ({
  title: 'Delete Account',
  headerTitle: 'DELETE ACCOUNT',
  headerShown: true,
});

export const ViewDiscussionBoardOptions = () => ({
  title: 'Discussion Board',
  headerTitle: 'DISCUSSION BOARD',
  headerBackTitle: 'Back',
  headerShown: true,
});

export const ConnectionsScreenOptions = (): StackNavigationOptions => ({
  title: 'Connections',
  headerTitle: 'CONNECTIONS',
  headerShown: true,
});

export const ViewPartnershipsScreenOptions = (): StackNavigationOptions => ({
  title: 'Partnerships',
  headerTitle: 'PARTNERSHIPS',
  headerShown: true,
});

export const AccountSettingsScreenOptions = (): StackNavigationOptions => ({
  title: 'Settings',
  headerTitle: 'ACCOUNT SETTINGS',
  headerTitleAlign: 'center',
  headerShown: true,
});

export const NotificationPreferencesScreenOptions =
  (): StackNavigationOptions => ({
    title: 'Notification Preferences',
    headerTitle: 'NOTIFICATION PREFERENCES',
    headerShown: true,
  });

export const DonationSettingsScreenOptions = (): StackNavigationOptions => ({
  title: 'Donations',
  headerTitle: 'DONATIONS',
  headerTitleAlign: 'center',
  headerShown: true,
});

export const StripePayoutsScreenOptions = (): StackNavigationOptions => ({
  title: 'Payouts - Stripe',
  headerTitle: 'PAYOUTS - STRIPE',
  headerTitleAlign: 'center',
  headerShown: true,
});

export const ChangePasswordScreenOptions = (): StackNavigationOptions => ({
  title: 'Change Password',
  headerTitle: 'CHANGE PASSWORD',
  headerTitleAlign: 'center',
  headerShown: true,
});

const ChangeEmailScreenOptions = (): StackNavigationOptions => ({
  title: 'Change Email',
  headerTitle: 'CHANGE EMAIL',
  headerTitleAlign: 'center',
  headerShown: true,
});

const ManageSkillRequestScreenOptions = () => ({
  title: 'Manage Skill Request',
  headerTitle: 'MANAGE SKILL REQUEST',
  headerBackTitle: '',
  headerShown: true,
});

const ManageVolunteerRequestOptions = () => ({
  title: 'Manage Volunteer Request',
  headerTitle: 'MANAGE VOLUNTEER REQUEST',
  headerBackTitle: '',
  headerShown: true,
});

const ListVolunteersScreen = () => ({
  title: 'Volunteers',
  headerTitle: 'VOLUNTEERS',
  headerBackTitle: '',
  headerShown: true,
});

const ViewApplicantDetailsScreenOptions = () => ({
  title: 'Applicant Details',
  headerTitle: 'APPLICANT DETAILS',
  headerShown: true,
});

const MyImpactCampaignsScreenOptions = () => ({
  title: 'My Campaigns',
  headerTitle: 'MY CAMPAIGNS',
  headerShown: true,
});

const OrganizationStatsScreenOptions = () => ({
  title: 'Organization Stats',
  headerTitle: 'ORGANIZATION STATS',
  headerShown: true,
});

const ListCampaignsWithStatsOptions = () => ({
  title: 'Campaigns',
  headerTitle: 'CAMPAIGNS',
  headerShown: true,
});

const CreateKeyBulletinPostOptions = () => ({
  title: 'Create Bulletin Post',
  headerTitle: 'CREATE BULLETIN POST',
  headerShown: true,
});

const EditKeyBulletinPostOptions = () => ({
  title: 'Edit Bulletin Post',
  headerTitle: 'EDIT BULLETIN POST',
  headerShown: true,
});

const ViewKeyBulletinPostOptions = () => ({
  title: 'Key Bulletin',
  headerTitle: 'KEY BULLETIN',
  headerShown: true,
});

const ListKeyBulletinPostsOptions = () => ({
  title: 'Key Bulletin',
  headerTitle: 'KEY BULLETIN',
  headerShown: true,
});

const ListDirectConversationsOptions = () => ({
  title: 'Direct Messages',
  headerTitle: 'DIRECT MESSAGES',
  headerShown: true,
});

const DirectMessageScreenOptions = () => ({
  title: 'Conversation',
  headerTitle: 'CONVERSATION',
  headerShown: true,
});

const BookmarksScreenOptions = () => ({
  title: 'Bookmarks',
  headerTitle: 'BOOKMARKS',
  headerShown: true,
});

const ViewSpeciesUserContentOptions = () => ({
  title: 'Our Species',
  headerTitle: 'OUR SPECIES',
  headerShown: true,
});
