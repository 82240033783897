import { useEffect, useState } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import Button from '../Button';
import Carousel from '../Carousel/Carousel';
import GenericListEmptyComponent from '../common/Generic/GenericListEmptyComponent';
import styles from './BigIssuesPicker.style';
import {
  CREATIVE_CONNECT_BACKGROUND_GRAY,
  KEY_GRAY,
  KEY_GREEN,
} from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import {
  OrganizationBigIssue,
  useGetUserBigIssuesQuery,
  UserRole,
} from '/generated/graphql';

export interface IBigIssuesPickerBigIssue
  extends Pick<OrganizationBigIssue, 'id' | 'userId'> {}

interface BigIsuesPickerProps {
  /** ID of user whose Big Issues to browse */
  userId?: string;
  darkTheme?: boolean;
  onChangeSelection?: (bigIssues: IBigIssuesPickerBigIssue[]) => void;
  selection?: IBigIssuesPickerBigIssue[];
  emptyPlaceholderText?: string;
}

export default function BigIssuesPicker(props: BigIsuesPickerProps) {
  const { activeTeam } = useTeamContext();

  return (
    <BigIssuesPickerCore
      key={props.userId ?? activeTeam?.user?.id}
      {...props}
    />
  );
}

function BigIssuesPickerCore(props: BigIsuesPickerProps) {
  const [_selection, _setSelection] = useState(props.selection || []);

  const { activeTeam } = useTeamContext();
  const { userData } = useAuthContext();

  const userId =
    props.userId ??
    (userData?.role === UserRole.Supporter
      ? activeTeam?.user?.id
      : userData?.id);

  const [{ data, fetching, stale, error }, refetch] = useGetUserBigIssuesQuery({
    variables: {
      userId: userId!,
      draft: false,
    },
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (Array.isArray(props.selection)) {
      _setSelection(props.selection);
    } else {
      _setSelection([]);
    }
  }, [props.selection]);

  function toggleSelectBigIssue(bigIssue: IBigIssuesPickerBigIssue) {
    let newSelection = Array.from(_selection) ?? [];

    if (newSelection.some((issue) => issue.id === bigIssue.id)) {
      newSelection = newSelection.filter((issue) => issue.id !== bigIssue.id);
    } else {
      newSelection.push(bigIssue);
    }

    _setSelection(newSelection);
    props.onChangeSelection?.(newSelection);
  }

  return (fetching || stale) && !data ? (
    <View style={styles.placeholderContainer}>
      <ActivityIndicator color={props.darkTheme ? 'white' : KEY_GRAY} />
      <Text
        style={[
          styles.loadingText,
          props.darkTheme ? { color: 'lightgray' } : {},
        ]}
      >
        Fetching...
      </Text>
    </View>
  ) : error ? (
    <View style={styles.placeholderContainer}>
      <Text style={styles.errorText}>Error fetching big issues</Text>
      <Button
        label="Retry"
        onPress={() => {
          refetch();
        }}
      />
    </View>
  ) : !data?.getBigIssues.length ? (
    <View>
      <GenericListEmptyComponent
        style={
          props.darkTheme
            ? {
                backgroundColor: CREATIVE_CONNECT_BACKGROUND_GRAY,
              }
            : {}
        }
        tintColor={props.darkTheme ? 'white' : undefined}
        placeholderText={
          props.emptyPlaceholderText ??
          'You have not created any Big Issues on your profile'
        }
      />
    </View>
  ) : (
    <Carousel
      darkTheme={props.darkTheme}
      data={
        data?.getBigIssues.map((issue) => {
          const isSelected = _selection?.some(
            (bigIssue) => bigIssue.id === issue.id,
          );

          return {
            media: issue.main_carousel?.[0]?.media ?? '',
            thumbnail: issue.main_carousel?.[0]?.thumbnail ?? '',
            caption: issue.main_carousel?.[0]?.caption ?? '',
            title: issue.title?.text ?? '',
            footerComponent: (
              <Button
                testID={`big-issues-picker-toggle-${issue.id}`}
                onPress={() => {
                  toggleSelectBigIssue(issue);
                }}
                style={{
                  backgroundColor: isSelected
                    ? KEY_GREEN
                    : props.darkTheme
                    ? 'white'
                    : KEY_GRAY,
                }}
                labelStyle={{
                  padding: 6,
                  color: isSelected || props.darkTheme ? 'black' : 'white',
                }}
                label={isSelected ? 'SELECTED' : 'SELECT'}
              />
            ),
          };
        }) ?? []
      }
    />
  );
}
